var DisponibilidadeAtendimento = function() {
    
    var validation = new Validation();

    var templateHoraSource = $(".template-hour").html();

    var templateEnderecoSource = $(".enderecos-template").html();
    var templateEnderecoDest = $(".enderecosList");


    function init() {
		insertMask();
        addListeners();
        OpenBodyContent();
        RemoveDisponibilidade();
        compileAddresTemplate();
        compileHourTemplate();
	}

    function compileAddresTemplate() {
        templateCompile = dust.compile(templateEnderecoSource, 'address');
        dust.loadSource(templateCompile);
        
    };

    function compileHourTemplate() {
        templateCompile = dust.compile(templateHoraSource, 'hourfree');
        dust.loadSource(templateCompile);
        
    };    

    function OpenBodyContent(){

        $('[data-component="disponibilidade-atendimento"]').on('click','.container-hour', function(){                        
            $(this).toggleClass('selected');
        });     
    };
    function limparTudo(){            
        $('.cancel').parents('.StoredAddress').find('input[name="horarioFim"]').val('');
        $('.cancel').parents('.StoredAddress').find('input[name="horarioInicio"]').val('');
        $('.selected').removeClass('selected');
        $('input').removeClass('error');
        $('.specialty-choose').removeClass('error');
    }

  /*  function salvarDados(inicio,fim){
        $('.selected').each(function(key){

            var diaSemana =  $(this).find('input').val();
            var inicio = inicio.val();
            var fim = fim.val();
            $('.dias-selecionados').append(
                '<div class="col-md-5 body-week-right">'+
                    '<span class="icon icon-clock col-md-3"></span>'+                             
                    '<h4 class="pull-left">'+diaSemana+'</h4>'+                    
                    '<div class="hour-free">'+
                    '<input type="hidden" value="'+inicio+'" class="horario-period" />'+
                    '<div class="horarios">'+
                        '<span class="begin">'+inicio+' </span>'+
                        '<span class="end">às '+fim+'</span>'+
                    '</div>'+
                    '<i class="fa fa-times" aria-hidden="true"></i>'+
                '</div>');
        });
    }*/
    function adicionarHorarioAtendimento(form) {
        var diasSemana = form.find('.selected');
        var EnderecoID = form.parents('.serviceAddress').find('.enderecoID').val();
        var fim = form.find('input[name="horarioFim"]').val();
        var inicio = form.find('input[name="horarioInicio"]').val();

        diasSemana.each(function (key,value) {
            //var dia = $(this).find('input[name="Dia"]').val();
            // var nomeDia = $(this).find('input[name="DiaSemana"]').val();
            var dia = $(this).find('.keyDiaSemana').val();
            var nomeDia = $(this).find('.nomeDiaSemana').val();
            var JSON = {
                "NomeDia": nomeDia,
                //"DiaSemana": DiaSemana,
                "DiaSemana": dia,
                "EnderecoID": EnderecoID,
                "horaInicio": inicio,
				"horaFim": fim,
            };
            dust.render('hourfree', JSON, function (err, out) {
                form.find('.available-hours').append(out);
            });

            $(this).removeClass('selected');
        });
    }

    $('[data-component="disponibilidade-atendimento"]').on('click','.btn-salvar-dados', function(){                        
           
        
        var error = false;

        if ($('.selected').length < 1) {
            error = true;
            $('.specialty-choose').addClass('error');
        }
        else {
            $('.specialty-choose').removeClass('error');

            var inputHoraFim = $(this).parents('form').find('input[name="horarioFim"]');
            var inputHoraInicio = $(this).parents('form').find('input[name="horarioInicio"]');
            if (validateHhMm(inputHoraInicio, inputHoraFim)) {
                error = true;
            }
            if (!error) {
                adicionarHorarioAtendimento($(this).parents('form'));
                inputHoraInicio.val('');
                inputHoraFim.val('');
            }
        }
    });

	function insertMask() {
       
		$('input[name="horarioInicio"], input[name="horarioFim"]').mask('00:00');
        $('.cep-register').mask('00000-000');


	}


    function RemoveDisponibilidade(){
        $('[data-component="disponibilidade-atendimento"]').on('click', '.body-week-right', function(){
            var horario = $(this).find('.horario-period');
            var item = $(this).parents('.body-week').find('.horarios');
            item.val(item.val().replace(horario.val(),""));
            $(this).remove();
        });
    };

    function validateHhMm(inputField1, inputField2) 
    {
        var error = false;

        var isValid1 = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField1.val());
        var isValid2 = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField2.val());

        if (isValid1 && isValid2 && inputField2.val() > inputField1.val()) 
        {
            $(inputField1).removeClass('error');
            $(inputField2).removeClass('error');
        } else {
            $(inputField1).addClass('error');
            $(inputField2).addClass('error');
            error = true;
        }

        return error;
    }

    function addListeners() 
    {
        var error = false;

        $('#cepProfissional').on('click', function(){
            $(this).parents('form').find('.cep-content-juristic-register').addClass('active');
        });

        $('[data-component="disponibilidade-atendimento"]').on('click','.button-turn',BlueBoxChange);

        $('[data-component="disponibilidade-atendimento"]').on('click','.InserirDisponibilidade',function(e){
            e.preventDefault();
            var box = $(this).parents('.turn-specific');
            var inicio = box.find('[name="horarioInicio"]');
            var fim = box.find('[name="horarioFim"]');
            error = validateHhMm(inicio, fim);
            if(!error)
            {
                var inputHorarios = $(this).parents(".body-week").find(".horarios");
                inputHorarios.val(inputHorarios.val() + inicio.val() + "-" + fim.val() + "|");   

                var html = templateHoraSource.replace('{Horario}',inicio.val() + '-' + fim.val()).replace('{HoraInicio}',"Das " + inicio.val()).replace('{HoraFim}'," às " + fim.val());
                inicio.val("");
                fim.val("");
                
                var item = $(this).parents('.body-week').find('.body-week-right');
                item.append(html);
            }
        });

        $('[data-component="disponibilidade-atendimento"]').on("click",".form-submit", function(e)
        {
            e.preventDefault();
            validationFormAvailabilityOfCare($(this).parents('form'));
        });

        $('[data-component="disponibilidade-atendimento"]').on("click", ".close-hour", function (e) {
            e.preventDefault();
            $(this).closest(".div-hour").addClass('hidden');
        });


        $('[data-component="disponibilidade-atendimento"]').on("click",".btn-salvar-dados", function(e)
        {
            e.preventDefault();

            //$(this).parents('form').submit();
                   
        });

        $('[data-component="disponibilidade-atendimento"]').on("click", ".form-submit-horario", function (e) {
			  
            e.preventDefault();
            var box = $(this).parents('.StoredAddress').find('.turn-dawn');
            var divbox = $(this).parents('.StoredAddress').find('.div-hour');
            var validarHora = false;
            $(this).parents('.StoredAddress').find('.div-hour').each(function (key, value) {
                if (!$(value).hasClass('hidden')) {
                    validarHora = true;
                }
            });
            if (box.length > 0 && validarHora) {
                $(this).parents('.serviceAddress').find('.form-horario-atendimento').submit();
            } else {
                Alert('Atenção!', 'É preciso ter um horário cadastrado!');
                ModalShow();
            }
        });
		 
		$('[data-component="disponibilidade-atendimento"]').on("submit",".form-horario-atendimento", function(e)
        {
            e.preventDefault();

            var ListaDisponibilidade = $(this).find('.available-hours');
            $(ListaDisponibilidade).find('.div-hour').remove('.hidden');
            $(ListaDisponibilidade).find('.div-hour').each(function (count) {
                $(this).find(':input').each(function () {
                    var nomeInput = $(this).prop('name');
                    $(this).attr('name', nomeInput.replace('|i|', '[' + count + ']'));
                });
            });

            var loading = new Loading();
            var form = $(this);
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: form.serialize(),
                success: function (result) {
                    if(result.Sucesso)
                    {
                        var item = form.parents(".StoredAddress");
                        item.find('.tempHour').removeClass('tempHour');
                        item.find('.boxAddress').click();
                        item.find('.cancel').click();
                    }
                    else
                    {
                        ModalShow();
                        Alert('Atenção!',result.Mensagem);
                    }

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro");
                    loading.hide();
                }
            });     
        });

        $('[data-component="disponibilidade-atendimento"]').on("submit",".NewAddress,.EditAddress", function(e)
        {
            e.preventDefault();
            var loading = new Loading();
            var form = $(this);
            var endereco = form.find('[name="Logradouro"]').val() + ',';
            endereco += form.find('[name="Numero"]').val() + ',';
            endereco += form.find('[name="Cidade"] option:selected').data("nome") + ',';
						endereco += form.find('[name="Estado"] option:selected').data("uf") + ',';
						endereco += form.find('[name="CEP"]').val() + ',';
						endereco += form.find('[name="Bairro"]').val() + ',';
            geolocalizacao(endereco, form.find('[name="Latitude"]'), form.find('[name="Longitude"]'),function(){
                $.ajax({
                    url: form.attr("action"),
                    type: form.attr("method"),
                    data: form.serialize(),
                    success: function (result) {
                        if(result.Sucesso)
                        {
                            if(form.find(".endereco-id").val() == 0)
                            {
                                form.trigger('reset');
                                dust.render('address', result.Retorno, function (err, out) {
                                    templateEnderecoDest.append(out);
                                });

                                //$(".qualification:visible").last().find("[name='Categoria']").val(result.Retorno.Categoria);
                            }
                            else
                            {
                                var item = form.parents(".StoredAddress");
                                item.find(".period.logradouro").text(result.Retorno.Logradouro + ", " + result.Retorno.Numero);
                                item.find(".period.complemento").text(result.Retorno.Complemento);
                                item.find(".period.bairro").text("Bairro: " + result.Retorno.Bairro);
                                item.find(".period.cidade").text(result.Retorno.CidadeNome + " - " + result.Retorno.EstadoUF);
                                item.find('.cancel').click();
                            }
                            $(".enderecosList").children().last().find('.faixa').click();
                        }
                        else
                        {
                            ModalShow();
                            Alert('Erro!',result.Mensagem);
                        }

                        loading.hide();
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log("Erro");
                        loading.hide();
                    }
                });     
            });
        });

        
        $('[data-component="disponibilidade-atendimento"]').on('click','.faixa',function(e){
        	e.preventDefault();
           $(this).parent('.action-icons').next('.boxAddress').addClass('hidden');
           $(this).addClass('hidden');
           $(this).parent('.action-icons').nextAll('.serviceAddress').slideDown();
           $(this).parent('.action-icons').find('.edit').addClass('hidden');
           $(this).parent('.action-icons').find('.exclude').addClass('hidden');
           var box = $(this).parents('.StoredAddress').find('.turn-dawn');
           if (box.length > 0) {
               $(this).parent('.action-icons').find('.cancel').removeClass('hidden');
           } else {
               $(this).parent('.action-icons').find('.cancel').addClass('hidden');
           }
           if ($(".boxAddress").hasClass("hidden")) {
               $("#botaosalvarprosseguir").hide();
           }
        }); 
        

        $('[data-component="disponibilidade-atendimento"]').on("change",".ddlEstado", function () {
            var inputEstado = $(this); 
            var parent = $(inputEstado.parents(".estadoCidade"));
            var city = parent.find(".ddlCidade");
            if ($(this).val() !== '') {
                EstadoSelecionado(city, inputEstado.val());
            }
            else {
                $(city).html('<option value="" class="hidden"></option>');
            }
        });

        $('[data-component="disponibilidade-atendimento"]').on("blur",".txtCep", function () {
            var input = $(this);
            cepChange(input);
        });

        $('[data-component="disponibilidade-atendimento"]').on('click','.edit', function(e){
            e.preventDefault();
            $(this).parent('.action-icons').children('.EditAddress').slideDown('slow');
            $(this).parent('.action-icons').next('.boxAddress').slideUp('slow');
            $(this).addClass('hidden');
            $(this).next('.exclude').addClass('hidden');
            $(this).parent('.action-icons').find('.faixa').addClass('hidden');
            $(this).parent('.action-icons').find('.cancel').removeClass('hidden');

        });

        $('[data-component="disponibilidade-atendimento"]').on('click','.cancel', function(e){
            e.preventDefault();
            $(this).parents('.StoredAddress').find('.tempHour').remove();
            $(this).parents('.StoredAddress').find('.div-hour').removeClass('hidden');
            $(this).parent('.action-icons').children('.EditAddress').slideUp('slow');
            $(this).parent('.action-icons').next('.boxAddress').slideDown('slow');
            $(this).addClass('hidden');
            $(this).parent('.action-icons').find('.exclude').removeClass('hidden');
            $(this).parent('.action-icons').find('.edit').removeClass('hidden');
            $(this).parent('.action-icons').find('.faixa').removeClass('hidden');
            $(this).parent('.action-icons').nextAll('.serviceAddress').slideUp();
            $(this).parent('.action-icons').next('.boxAddress').removeClass('hidden');
            if (!$(".boxAddress").hasClass("hidden")) {
                $("#botaosalvarprosseguir").show();
            }
            limparTudo();
        });

        $('[data-component="disponibilidade-atendimento"]').on('click',".exclude", function(e){
            e.preventDefault();
            var loading = new Loading();
            var item = $(this).parents('.StoredAddress');
            var url = $("#remover-endereco").val() + "/" + item.find('.endereco-id').val();
            $.ajax({
                url: url,
                type: "GET",
                success: function (result) {
                    if(result.Sucesso)
                    {
                        item.remove();
                    }
                    else
                        {
                            Alert("Atenção",result.Mensagem);
                            ModalShow();
                        }
                        loading.hide();
                    },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro")
                }
            });
        });
        
    }

    function BlueBoxChange(){
        var icon = $(this).find('.turn-specific-text span');

        if(icon.hasClass('icon-unmark')){
            icon.addClass('icon-mark');
            icon.removeClass('icon-unmark'); 
            $(this).find('.turn-specific-text').css('color','#fff'); 
            $(this).css('background-color','#03d3ef');
            $(this).find('.-blue').css({
                'background-color' : '#fff',
                'color' : '#03d3ef'
            });        
        }
    }

    function BoxSpecificRemoveClick(box)
    {
        
        box.find('.turn-specific-text').removeAttr('style'); 
        box.removeAttr('style');
        box.find('.-blue').removeAttr('style');
        var icon = box.find('.turn-specific-text span');
        icon.removeClass('icon-mark');
        icon.addClass('icon-unmark'); 
    }

    function validationFormAvailabilityOfCare(form) {

        var error = false;
        var cep = form.find('[name="CEP"]');
        var LogradouroCadastro = form.find('[name="Logradouro"]');
        var NumeroCadastro = form.find('[name="Numero"]');
        var estadoCadastro = form.find('[name="Estado"]');
        var cidadeCadastro = form.find('[name="Cidade"]');
        var BairroCadastro = form.find('[name="Bairro"]');

        if (validation.isNull(cep.val())) {
            error = true;
            cep.addClass('error');
        } else {
            cep.removeClass('error');
        }

        if (validation.isNull(LogradouroCadastro.val())) {
            error = true;
            LogradouroCadastro.addClass('error');
        } else {
            LogradouroCadastro.removeClass('error');
        }
        if (validation.isNull(NumeroCadastro.val())) {
            error = true;
            NumeroCadastro.addClass('error');
        } else {
            NumeroCadastro.removeClass('error');
        }
        if (validation.isNull(estadoCadastro.val())) {
            error = true;
            estadoCadastro.addClass('error');
        } else {
            estadoCadastro.removeClass('error');
        }
    
        if (validation.isNull(cidadeCadastro.val())) {
            error = true;
            cidadeCadastro.addClass('error');
        } else {
            cidadeCadastro.removeClass('error');
        }
        if (validation.isNull(BairroCadastro.val())) {
            error = true;
            BairroCadastro.addClass('error');
        } else {
            BairroCadastro.removeClass('error');
        }
        
        if (!error) {
            form.submit();
        } 
    }


    function EstadoSelecionado(inputCidades, estadoID, callBackFunction, loading) {
        if(!loading)
            loading = new Loading();
        $.ajax({
            type: "GET",
            url: $("#urlListarCidades").val(),
            data: { id: estadoID },
            success: function (data) {
                inputCidades.html('<option value="" class="hidden"></option>');
                $.each(data, function (index, value) {
                    inputCidades.append('<option value="' + value.ID + '" data-nome="' + value.Nome + '">' + value.Nome + '</option>');
                });
            
                if (callBackFunction)
                    callBackFunction(inputCidades);
                else
                    loading.hide();
            }
        });
    }


    function cepChange(input)
    {
        if (input.get(0).value.length == 9) {
            var loading = new Loading();
            var inputCep = input.parents('form');
            
            $.ajax({
                type: "GET",
                url: "https://webmaniabr.com/api/1/cep/" + input.val().replace("-", "") + "/?app_key=" + 'PYlhy37jk1bhwY3WnKq0GZray0xA5MGb' + "&app_secret=" + 'VWjiPK0jzOYROBNZLmfqtmT3PKPcZ7cGrLGRMQG6vA5wMJX1',
                data: { cep: input.val().replace("-", ""), formato: "json" },
                success: function (data) {
                    if (data.uf && data.cidade) {
					  $(inputCep).find(".txtBairro").val(data.bairro);
					  $(inputCep).find(".txtLogradouro").val(data.endereco);
					  var inputEstado = $(inputCep).find(".ddlEstado");
					  inputEstado.val(inputEstado.find("[data-uf='" + data.uf + "']").val());
					  var inputCidade = $(inputCep).find(".ddlCidade");
					  EstadoSelecionado(inputCidade, inputEstado.val(), function (inputCidade) { inputCidade.val(inputCidade.find("[data-nome='" + data.cidade + "']").val()); loading.hide(); }, loading);
                    } else {
                        console.log("Erro");
                        loading.hide();
                    }
                }
            });
        }
    }

    function geolocalizacao(endereco, inputLatitude, inputLongitude, callBackFunction, c = 0) {
        $.ajax({
		  url: "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDrUUCk1Yz5alT7PyYHzaur3-9fp_oRPlg&address=" + endereco + "&sensor=false",
            type: "POST",
            success: function (res) {
                var lat = ""; var long="";
                if (res.results.length != 0) {
                    lat=res.results[0].geometry.location.lat;
                    long=res.results[0].geometry.location.lng;         
                }
								if ((!lat || !long) && c < 6) {
										setTimeout(() => { geolocalizacao(endereco, inputLatitude, inputLongitude, c++) }, 10000);
								} else if (c == 6) {
								  notifica('Nao foi possível validar o endereço no momento.', 'erro');
								} else {
                    inputLatitude.val(lat);
                    inputLongitude.val(long);
                    if (callBackFunction)
                        callBackFunction();
                }
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro");
                    if(callBackFunction)
                        callBackFunction();     
                }
        });
    }

    $('[data-component="disponibilidade-atendimento"]').on("click", "#botaosalvarprosseguir", function (e) {
        var valido = true;
        var msg = "";

        if ($('.enderecosList').find('.StoredAddress').length == 0) {
            valido = false;
            msg = 'É preciso ter endereço cadastrado!';
        } else {
            $('.enderecosList').find('.StoredAddress').each(function (key, value) {
                if($(value).find('.turn-dawn').length == 0)
                {
                    valido = false;
                    msg = 'O endereço ' + $(value).find('.logradouro').text() + ' não possui um horário cadastrado!';
                }
            });
        }

        if (!valido) {
            e.preventDefault();
            Alert('Atenção!', msg);
            ModalShow();
        }
    });
    
	return init();

};
