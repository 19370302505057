var Speciality = function () {

    var validation = new Validation();


    function init() {

        $('#specialty').on('click', '.button-specialty', function (e) {
            e.preventDefault();

            var button = $(this);
            var dataId = $(this).data('id');
            var input = button.parent().find('.number-register');
            var registerNumber = input.length === 0 || input.val().length === 0;
            var specialtyButton = button.parents('.specialty-div').find('.specialty-choose.active').length === 0;

            if (button.hasClass('active')) {
                if (registerNumber && specialtyButton) {
                    button.removeClass('active');
                    button.find('.icon-specialty-active').addClass('icon-specialty').removeClass('icon-location-active');
                    button.find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
                    button.find('[type="checkbox"]').removeProp('checked');
                    $("#" + dataId).find("button").removeClass("active");
                }
            }
            else {
                button.addClass('active');
                button.find('.icon-specialty').addClass('icon-specialty-active').removeClass('icon-specialty');
                button.find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');
                button.find('[type="checkbox"]').prop('checked', 'checked');

            }

            if (dataId) {
                $('[specialty-id="' + dataId + '"]').slideToggle('slow');
            }

        });

        $('.specialty-choose').on('click', function (e) {
            e.preventDefault();

            var button = $(this);
            if (button.hasClass('active'))
                $("." + button.attr("data-check")).removeProp('checked');
            else
                $("." + button.attr("data-check")).prop('checked', 'checked');

            button.toggleClass('active');

        });

        $(".form-especialidade .form-submit").on("click", function (e) {
            e.preventDefault();
            console.log($('.button-specialty.active'));
            if ($('.button-specialty.active').length) {
                validationFormEspecialidade($(this).parents('form'));
            } else {
                Alert("Atenção", "Você precisa cadastrar uma especialidade!");
                ModalShow();
            }
        });

        $('.form-especialidade').on("submit", function (e) {
            e.preventDefault();
            var form = $(this);
            var loading = new Loading();
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: form.serialize(),
                success: function (result) {
                    if (result != null) {
                        if (result.Sucesso) {
                            location.href = result.Mensagem;
                        }
                        else {
                            Alert("Atenção", result.Mensagem);
                            ModalShow();
                            loading.hide();
                        }
                    }
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro")
                }
            });
        });

    }

    function validationFormEspecialidade(form) {

        var error = false;
        var ativos = 0;
        var chooseChecked = 0;
        var divsBlock = $('.button-specialty.active');
        var divSpecialty = '';
        divsBlock.each(function () {
            divSpecialty = $(this).parents('.specialty-div');
            if (divSpecialty.find('.specialty-choose.area-button.active').length == 0) {
                divSpecialty.find('.specialty-choose.area-button').addClass("error");
                error = true;
            }
            else {
                divSpecialty.find('.specialty-choose').removeClass("error");
            }

            var numeroRegistro = $(this).parent().find('.number-register');

            if (validation.isNull(numeroRegistro.val()) && numeroRegistro.length > 0) {
                error = true;
                numeroRegistro.addClass('error');
            } else {
                numeroRegistro.removeClass('error');
            }

            if (divSpecialty.find('.specialty-choose.termos-button.active').length == 0) {
                divSpecialty.find('.specialty-choose.termos-button').addClass("error");
                error = true;
            }
            else {
                divSpecialty.find('.specialty-choose').removeClass("error");
            }

        });

        if (!error) {
            form.submit();
        }
        else {

            var erro = $('.form-especialidade').find('.error');

            if (erro.parents('.conteudo-especialidade').css('display') == 'none') {
                erro.parents('.specialty-div').find('.button-specialty').click();
            }

            var elemTop = erro.offset().top;
            TweenLite.to('html,body', 1, { scrollTop: (elemTop - 50), ease: Power1.easeOut });
        }


    }

    return init();

};
