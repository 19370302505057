var AcademicData = function() {

    var validation = new Validation();

    var templateAcademicSource = $(".academic-template").html();
    var templateAcademicDest = $(".academic-list");
    
    function init() {
		insertMask();
        compileAddresTemplate();
        addListeners();
	}

 	function compileAddresTemplate() {
        templateCompile = dust.compile(templateAcademicSource, 'academic');
        dust.loadSource(templateCompile);
        
    };

	function insertMask() {
		$('[name="AnoInicio"], [name="AnoFim"]').mask('0000');
	}

    function ValidateFormAcademicData(form){
        var error = false;
        var empresa = form.find('[name="Instituicao"]');
        var intitutionClass = form.find('[name="Curso"]');
        var category = form.find('[name="Categoria"]');
        var anoInicio = form.find('[name="AnoInicio"]');
        var anoFim = form.find('[name="AnoFim"]');
        var anoIntIn = parseInt(anoInicio.val());
        var anoIntFim = parseInt(anoFim.val());


	    if (validation.isNull(anoFim.val()) || (anoIntIn > anoIntFim)) {
	        error = true;
	        anoFim.addClass('error');
	    } else {
	        anoFim.removeClass('error');
	    }


	    if (validation.isNull(anoInicio.val()) || (anoIntIn > anoIntFim)) {
	        error = true;
	        anoInicio.addClass('error');
	    } else {
	        anoInicio.removeClass('error');
	    }        


		if (validation.isNull(empresa.val())) {
		    error = true;
		    empresa.addClass('error');
		} else {
		    empresa.removeClass('error');
		} 
	    
	    if (validation.isNull(intitutionClass.val())) {
	        error = true;
	        intitutionClass.addClass('error');
	    } else {
        	intitutionClass.removeClass('error');
    	}	 
	    if (validation.isNull(category.val())){
	        error = true;
	        category.addClass('error');
	    } else {
	            category.removeClass('error');
	    } 
	    if (!error) {
	        form.submit();
	    }                 
    }

    function addListeners() {     

        $("[data-page='cadastro-profissional-dados-academicos'],[data-page='profissional-meus-dados-academicos']").on('click','.attachment',function(e)
        {
            e.preventDefault();
            $(this).next('.includeCertificate').click();
        });

        $("[data-page='cadastro-profissional-dados-academicos'],[data-page='profissional-meus-dados-academicos']").on('change','.includeCertificate',function(){
            var form = $(this).parents('form');
            form.find('.text-download').val(this.files[0].name);

        });       

        $("[data-page='cadastro-profissional-dados-academicos'],[data-page='profissional-meus-dados-academicos']").on("click",".formAcademicData .btn-dados-academicos", function(e)
        {
            e.preventDefault();
            ValidateFormAcademicData($(this).parents('form'));
        });

        $("[data-page='cadastro-profissional-dados-academicos'],[data-page='profissional-meus-dados-academicos']").on("submit",".formAcademicData", function(e)
        {
            e.preventDefault();
            var form = $(this);
            var loading = new Loading();
            var formData = new FormData(form[0]);
            formData.append("CertificadoFile", form.find(".includeCertificate")[0].files[0]);
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: formData,
                dataType: 'json',
                contentType: false,
                processData: false,
                success: function (result) {
                    if(result.Sucesso)
                    {
                        if(form.find(".academic-id").val() == 0)
                            {
                                form.trigger('reset');
                                dust.render('academic', result.Retorno, function(err, out) {
                                	templateAcademicDest.append(out);
                            	});

								$(".qualification:visible").last().find("[name='Categoria']").val(result.Retorno.Categoria);
							}
                            else
                            {
                                var item = form.parents(".qualification");
                                item.find(".storedCategory").text(result.Retorno.CategoriaDescricao);
                                item.find(".storedIntitution").text(result.Retorno.Instituicao);
                                item.find(".storedFormation").text(result.Retorno.Curso);
                                item.find(".storedDate").text("Ano: " + result.Retorno.AnoInicio + " - " + result.Retorno.AnoFim);
                                if(result.Retorno.Certificado)
                                {
                                	item.find(".storedCertificate a").attr('href',result.Retorno.Certificado);
                                	item.find(".storedCertificate a").html('<i class="fa fa-file-pdf-o" aria-hidden="true"></i>' + result.Retorno.CertificadoNome);
                                    item.find(".fileDownload").attr('href',result.Retorno.Certificado);
                                }
                                item.find('.cancel').click();
                            }
                        }
                        else
                        {
                            Alert("Atenção",result.Mensagem);
                            ModalShow();
                            
                        }

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro")
                }
            });
        });

        $("[data-page='cadastro-profissional-dados-academicos'],[data-page='profissional-meus-dados-academicos']").on('click',".edit", function(e){
            e.preventDefault();

            var formShow = $(this).parents('.action-icons').next('.EditAcademicContent');
            var formGroup = $(this).parent().parent('.qualification');
            $(formShow).slideDown('slow');
            $(formGroup).find('.box').slideUp('slow');
			
			$(this).addClass('hidden');
            $(this).next('.exclude').addClass('hidden');
            $(this).parent('.action-icons').find('.cancel').removeClass('hidden');
        });  

        $("[data-page='cadastro-profissional-dados-academicos'],[data-page='profissional-meus-dados-academicos']").on('click','.cancel', function(e){
            e.preventDefault();

            e.preventDefault();
            var formShow = $(this).parents('.action-icons').next('.EditAcademicContent');
            $(formShow).slideUp('slow');
            var formGroup = $(this).parent().parent('.qualification');
            $(formGroup).find('.box').slideDown('slow');
			
			$(this).addClass('hidden');
            $(this).parent('.action-icons').find('.exclude').removeClass('hidden');
            $(this).parent('.action-icons').find('.edit').removeClass('hidden');
        });               
        
        $("[data-page='cadastro-profissional-dados-academicos'],[data-page='profissional-meus-dados-academicos']").on('click',".exclude", function(e){
            e.preventDefault();
            var loading = new Loading();
            var item = $(this).parents('.qualification');
            var url = $("#remover-dado-academico").val() + "/" + $(this).data("id");
            $.ajax({
                url: url,
                type: "GET",
                success: function (result) {
                    if(result.Sucesso)
                    {
                        item.remove();
                    }
                    else
                        {
                            Alert("Atenção",result.Mensagem);
                            ModalShow();
                        }
                        loading.hide();
                    },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro")
                }
            });
        });

    }
    
	return init();

};