var MyData = function () {

  var validation = new Validation();

  function init() {
	addListeners();
	insertMask();
	submits();

  }

  function submits() {

	$('.pass-1-form-1 .form-submit').on('click', function (e) {
	  e.preventDefault();
	  if ($(this).attr('disabled') == undefined)
		validationForm1_1();
	});

	$(".pass-1-form-2 .form-submit").on("click", function (e) {
	  e.preventDefault();
	  if ($(this).attr('disabled') == undefined)
		validationForm1_2();
	});

	$(".pass-2-form-1 .form-submit").on("click", function (e) {
	  e.preventDefault();
	  if ($(this).attr('disabled') == undefined)
		validationForm2_1($(this).parents('form'));
	});

	$(".pass-4-form-1 .form-submit").on("click", function (e) {
	  e.preventDefault();
	  validationCard($(this).parents('form'));
	});

	$('.pass-2-form-1 [name="relacionamento"').on("change", function (e) {
	  var form = $(this).parents('form');
	  var formCliente = $('.pass-1-form-1');
	  if ($(this).val() == 'R') {
		form.find('[name="nome"]').val(formCliente.find('[name="nome"]').val());
		form.find('[name="cpf"]').val(formCliente.find('[name="cpf"]').val());
		form.find('[name="dataNascimento"]').val(formCliente.find('[name="dataNascimento"]').val());
		form.find('[name="sexo"]').val(formCliente.find('[name="sexo"]').val());
		form.find('[name="telefone1"]').val(formCliente.find('[name="telefone1"]').val());
		form.find('[name="telefone2"]').val(formCliente.find('[name="telefone2"]').val());
	  }
	});

	$("#submit-nova-senha").on("click", function (e) {
	  e.preventDefault();
	  $(this).parents('form').submit();
	});
  }

  function validationForm1_1() {
	var error = false;
	var form = $('.pass-1-form-1');

	var nome = form.find('[name="nome"]');
	var cpf = form.find('[name="cpf"]');
	var sexo = form.find('[name="sexo"]');
	var dataNascimento = form.find('[name="dataNascimento"]');
	var telefone1 = form.find('[name="telefone1"]');
	var cep = form.find('[name="CEPCadastro"]');
	var logradouro = form.find('[name="LogradouroCadastro"]');
	var numero = form.find('[name="NumeroCadastro"]');
	var estado = form.find('[name="estadoCadastro"]');
	var cidade = form.find('[name="cidadeCadastro"]');
	var bairro = form.find('[name="BairroCadastro"]');

	if (validation.isNull(nome.val())) {
	  error = true;
	  nome.addClass('error');
	} else {
	  nome.removeClass('error');
	}

	if (validation.isNull(cpf.val()) || !validation.verifyCPF(cpf.val())) {
	  error = true;
	  cpf.addClass('error');
	  if (!validation.isNull(cpf.val())) {
		$('.cpf-alert').css('display', 'block');
	  } else {
		$('.cpf-alert').css('display', 'none');
	  }
	} else {
	  cpf.removeClass('error');
	  $('.cpf-alert').css('display', 'none');
	}

	if (validation.isNull(sexo.val())) {
	  error = true;
	  sexo.addClass('error');
	} else {
	  sexo.removeClass('error');
	}

	if (!validation.isNull(dataNascimento.val()) && !validation.verifyDate(dataNascimento.val())) {
	  error = true;
	  dataNascimento.addClass('error');
	} else {
	  dataNascimento.removeClass('error');
	}

	if (validation.isNull(telefone1.val()) || telefone1.val().length < 14) {
	  error = true;
	  telefone1.addClass('error');
	} else {
	  telefone1.removeClass('error');
	}

	if (validation.isNull(cep.val())) {
	  error = true;
	  cep.addClass('error');
	} else {
	  cep.removeClass('error');
	}

	if (validation.isNull(logradouro.val())) {
	  error = true;
	  logradouro.addClass('error');
	} else {
	  logradouro.removeClass('error');
	}

	if (validation.isNull(numero.val())) {
	  error = true;
	  numero.addClass('error');
	} else {
	  numero.removeClass('error');
	}

	if (validation.isNull(estado.val())) {
	  error = true;
	  estado.addClass('error');
	} else {
	  estado.removeClass('error');
	}

	if (validation.isNull(cidade.val())) {
	  error = true;
	  cidade.addClass('error');
	} else {
	  cidade.removeClass('error');
	}

	if (validation.isNull(bairro.val())) {
	  error = true;
	  bairro.addClass('error');
	} else {
	  bairro.removeClass('error');
	}

	if ($('.cep-content-physical-charges').hasClass('active')) {
	  var cepCobranca = form.find('[name="CepCobranca"]');
	  var logradouroCobranca = form.find('[name="LogradouroCobranca"]');
	  var numeroCobranca = form.find('[name="NumeroCobranca"]');
	  var estadoCobranca = form.find('[name="EstadoCobranca"]');
	  var cidadeCobranca = form.find('[name="CidadeCobranca"]');
	  var bairroCobranca = form.find('[name="BairroCobranca"]');

	  if (validation.isNull(cepCobranca.val())) {
		error = true;
		cepCobranca.addClass('error');
	  } else {
		cepCobranca.removeClass('error');
	  }

	  if (validation.isNull(logradouroCobranca.val())) {
		error = true;
		logradouroCobranca.addClass('error');
	  } else {
		logradouroCobranca.removeClass('error');
	  }

	  if (validation.isNull(numeroCobranca.val())) {
		error = true;
		numeroCobranca.addClass('error');
	  } else {
		numeroCobranca.removeClass('error');
	  }

	  if (validation.isNull(estadoCobranca.val())) {
		error = true;
		estadoCobranca.addClass('error');
	  } else {
		estadoCobranca.removeClass('error');
	  }

	  if (validation.isNull(cidadeCobranca.val())) {
		error = true;
		cidadeCobranca.addClass('error');
	  } else {
		cidadeCobranca.removeClass('error');
	  }

	  if (validation.isNull(bairroCobranca.val())) {
		error = true;
		bairroCobranca.addClass('error');
	  } else {
		bairroCobranca.removeClass('error');
	  }
	}

	if (!error) {
	  SubmitFormCliente(form);
	}
	else {
	  var elemTop = $('.pass-1-form-1').find('.error').offset().top;
	  TweenLite.to('html,body', 1, { scrollTop: (elemTop - 40), ease: Power1.easeOut });
	}
  }


  function validationForm1_2() {
	var error = false;
	var form = $('.pass-1-form-2');

	var nome = form.find('[name="nome"]');
	var cnpj = form.find('[name="cnpj"]');
	// var inscricaoEstadual = form.find('[name="inscricaoEstadual"]');
	var nomeContato = form.find('[name="nomeContato"]');
	var telefone1 = form.find('[name="telefone1"]');
	var cep = form.find('[name="CEPCadastro"]');
	var logradouro = form.find('[name="LogradouroCadastro"]');
	var numero = form.find('[name="NumeroCadastro"]');
	var estado = form.find('[name="estadoCadastro"]');
	var cidade = form.find('[name="cidadeCadastro"]');
	var bairro = form.find('[name="BairroCadastro"]');
	var inscricaoEstadualIsento = form.find('[name="InscricaoEstadualIsento"]');

	if (validation.isNull(nome.val())) {
	  error = true;
	  nome.addClass('error');
	} else {
	  nome.removeClass('error');
	}

	if (validation.isNull(cnpj.val()) || !validation.verifyCNPJ(cnpj.val())) {
	  error = true;
	  cnpj.addClass('error');
	  if (!validation.isNull(cnpj.val())) {
		$('.cnpj-alert').css('display', 'block');
	  } else {
		$('.cnpj-alert').css('display', 'none');
	  }
	} else {
	  cnpj.removeClass('error');
	  $('.cnpj-alert').css('display', 'none');
	}


	$(inscricaoEstadualIsento).prop('checked');

	// if (validation.isNull(inscricaoEstadual.val()) && $(inscricaoEstadualIsento).prop('checked')) {
	//     inscricaoEstadual.removeClass('error');

	// }else if(!validation.isNull(inscricaoEstadual.val()) ){
	//     inscricaoEstadual.removeClass('error');
	// }else {

	//     error = true;
	//     inscricaoEstadual.addClass('error');
	// }

	if (validation.isNull(nomeContato.val())) {
	  error = true;
	  nomeContato.addClass('error');
	} else {
	  nomeContato.removeClass('error');
	}

	if (validation.isNull(telefone1.val()) || telefone1.val().length < 14) {
	  error = true;
	  telefone1.addClass('error');
	} else {
	  telefone1.removeClass('error');
	}

	if (validation.isNull(cep.val())) {
	  error = true;
	  cep.addClass('error');
	} else {
	  cep.removeClass('error');
	}

	if (validation.isNull(logradouro.val())) {
	  error = true;
	  logradouro.addClass('error');
	} else {
	  logradouro.removeClass('error');
	}

	if (validation.isNull(numero.val())) {
	  error = true;
	  numero.addClass('error');
	} else {
	  numero.removeClass('error');
	}

	if (validation.isNull(estado.val())) {
	  error = true;
	  estado.addClass('error');
	} else {
	  estado.removeClass('error');
	}

	if (validation.isNull(cidade.val())) {
	  error = true;
	  cidade.addClass('error');
	} else {
	  cidade.removeClass('error');
	}

	if (validation.isNull(bairro.val())) {
	  error = true;
	  bairro.addClass('error');
	} else {
	  bairro.removeClass('error');
	}

	if ($('.cep-content-juristic-charges').hasClass('active')) {
	  var cepCobranca = form.find('[name="CepCobranca"]');
	  var logradouroCobranca = form.find('[name="logradouroCobranca"]');
	  var numeroCobranca = form.find('[name="numeroCobranca"]');
	  var estadoCobranca = form.find('[name="EstadoCobranca"]');
	  var cidadeCobranca = form.find('[name="CidadeCobranca"]');
	  var bairroCobranca = form.find('[name="BairroCobranca"]');

	  if (validation.isNull(cepCobranca.val())) {
		error = true;
		cepCobranca.addClass('error');
	  } else {
		cepCobranca.removeClass('error');
	  }

	  if (validation.isNull(logradouroCobranca.val())) {
		error = true;
		logradouroCobranca.addClass('error');
	  } else {
		logradouroCobranca.removeClass('error');
	  }

	  if (validation.isNull(numeroCobranca.val())) {
		error = true;
		numeroCobranca.addClass('error');
	  } else {
		numeroCobranca.removeClass('error');
	  }

	  if (validation.isNull(estadoCobranca.val())) {
		error = true;
		estadoCobranca.addClass('error');
	  } else {
		estadoCobranca.removeClass('error');
	  }

	  if (validation.isNull(cidadeCobranca.val())) {
		error = true;
		cidadeCobranca.addClass('error');
	  } else {
		cidadeCobranca.removeClass('error');
	  }

	  if (validation.isNull(bairroCobranca.val())) {
		error = true;
		bairroCobranca.addClass('error');
	  } else {
		bairroCobranca.removeClass('error');
	  }
	}

	if (!error) {
	  SubmitFormCliente(form);
	}
	else {
	  var elemTop = $('.pass-1-form-2').find('.error').offset().top;
	  TweenLite.to('html,body', 1, { scrollTop: (elemTop - 40), ease: Power1.easeOut });
	}
  }

  function validationForm2_1(form) {
	var error = false;

	var nome = form.find('[name="nome"]');
	var relacionamento = form.find('[name="relacionamento"]');
	var data = form.find('[name="dataNascimento"]');
	var sexo = form.find('[name="sexo"]');
	var telefone1 = form.find('[name="telefone1"]');


	if (validation.isNull(nome.val())) {
	  error = true;
	  nome.addClass('error');
	} else {
	  nome.removeClass('error');
	}

	if (validation.isNull(sexo.val())) {
	  error = true;
	  sexo.addClass('error');
	} else {
	  sexo.removeClass('error');
	}

	if (validation.isNull(data.val()) || !validation.verifyDate(data.val())) {
	  error = true;
	  data.addClass('error');
	} else {
	  data.removeClass('error');
	}

	if (validation.isNull(telefone1.val()) || telefone1.val().length < 14) {
	  error = true;
	  telefone1.addClass('error');
	} else {
	  telefone1.removeClass('error');
	}

	if (relacionamento.val() == "") {
	  error = true;
	  relacionamento.addClass('error');
	} else {
	  relacionamento.removeClass('error');
	}

	if (form.find('.cepPaciente').hasClass('active')) {
	  var cepCobranca = form.find('[name="CEP"]');
	  var logradouroCobranca = form.find('[name="Logradouro"]');
	  var numeroCobranca = form.find('[name="Numero"]');
	  var estadoCobranca = form.find('[name="Estado"]');
	  var cidadeCobranca = form.find('[name="Cidade"]');
	  var bairroCobranca = form.find('[name="Bairro"]');

	  if (validation.isNull(cepCobranca.val())) {
		error = true;
		cepCobranca.addClass('error');
	  } else {
		cepCobranca.removeClass('error');
	  }

	  if (validation.isNull(logradouroCobranca.val())) {
		error = true;
		logradouroCobranca.addClass('error');
	  } else {
		logradouroCobranca.removeClass('error');
	  }

	  if (validation.isNull(numeroCobranca.val())) {
		error = true;
		numeroCobranca.addClass('error');
	  } else {
		numeroCobranca.removeClass('error');
	  }

	  if (validation.isNull(estadoCobranca.val())) {
		error = true;
		estadoCobranca.addClass('error');
	  } else {
		estadoCobranca.removeClass('error');
	  }

	  if (validation.isNull(cidadeCobranca.val())) {
		error = true;
		cidadeCobranca.addClass('error');
	  } else {
		cidadeCobranca.removeClass('error');
	  }

	  if (validation.isNull(bairroCobranca.val())) {
		error = true;
		bairroCobranca.addClass('error');
	  } else {
		bairroCobranca.removeClass('error');
	  }
	}

	if (!error) {
	  SubmitFormPaciente(form);
	}
	else {
	  var elemTop = form.find('.error').offset().top;
	  TweenLite.to('html,body', 1, { scrollTop: (elemTop - 40), ease: Power1.easeOut });
	}
  }

  function SubmitFormCliente(form) {
	var loading = new Loading();
	var endereco = form.find('[name="LogradouroCadastro"]').val() + ',';
	endereco += form.find('[name="NumeroCadastro"]').val() + ',';
	endereco += form.find('[name="cidadeCadastro"] option:selected').data("nome") + ',';
	endereco += form.find('[name="estadoCadastro"] option:selected').data("uf") + ',';
	endereco += form.find('[name="cepCadastro"]').val() + ',';
	endereco += form.find('[name="bairroCadastro"]').val() + ',';
	$.ajax({
	  url: form.attr("action"),
	  type: form.attr("method"),
	  data: form.serialize(),
	  success: function (result) {
		if (result.Sucesso) {
		  location.reload();
		}
		else {
		  ModalShow();
		  Alert('Erro!', result.Mensagem);
		}
		loading.hide();
	  },
	  error: function (XMLHttpRequest, textStatus, errorThrown) {
		console.log("Erro");
		loading.hide();
	  }
	});

  }

  function SubmitFormPaciente(form) {
	var loading = new Loading();
	var endereco = form.find('[name="Logradouro"]').val() + ',';
	endereco += form.find('[name="Numero"]').val() + ',';
	endereco += form.find('[name="Cidade"] option:selected').data("nome") + ',';
	endereco += form.find('[name="Estado"] option:selected').data("uf") + ',';
	endereco += form.find('[name="cepCadastro"]').val() + ',';
	endereco += form.find('[name="bairroCadastro"]').val() + ',';
	geolocalizacao(endereco, form.find('[name="Latitude"]'), form.find('[name="Longitude"]'), function () {
	  $.ajax({
		url: form.attr("action"),
		type: form.attr("method"),
		data: form.serialize(),
		success: function (result) {
		  if (result.Sucesso) {
			location.href = result.Mensagem;
		  }
		  else {
			ModalShow();
			Alert('Erro!', result.Mensagem);
		  }
		  loading.hide();
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) {
		  console.log("Erro");
		  loading.hide();
		}
	  });
	});
  }


  function validationCard(form) {

	var error = false;
	var cardNumber = form.find('#card-number');
	var cardShelfLifeMonth = form.find('#card-shelf-life-month');
	var cardShelfLifeYear = form.find('#card-shelf-life-year');
	var NomeTitular = form.find('#card-name');
	var cardSecurityCode = form.find('#card-security-code');

	if ($("#forma-pagamento").val() != 'B') {
	  if (validation.isNull(cardNumber.val())) {
		error = true;
		cardNumber.addClass('error');
	  } else {
		cardNumber.removeClass('error');
	  }
	  if (validation.isNull(cardShelfLifeMonth.val())) {
		error = true;
		cardShelfLifeMonth.addClass('error');
	  } else {
		cardShelfLifeMonth.removeClass('error');
	  }
	  if (validation.isNull(cardShelfLifeYear.val())) {
		error = true;
		cardShelfLifeYear.addClass('error');
	  } else {
		cardShelfLifeYear.removeClass('error');
	  }
	  if (validation.isNull(NomeTitular.val())) {
		error = true;
		NomeTitular.addClass('error');
	  } else {
		NomeTitular.removeClass('error');
	  }
	  if (validation.isNull(cardSecurityCode.val())) {
		error = true;
		cardSecurityCode.addClass('error');
	  } else {
		cardSecurityCode.removeClass('error');
	  }
	}



	if (!error) {
	  form.submit();
	}
  };

  function addListeners() {
	$('.template-addres > div:nth-child(odd)').addClass('col-md-offset-2');
	$('.template-addres').find('.col-md-5').addClass('col-md-4').removeClass('col-md-5');

	$('.edit').on('click', function (e) {
	  e.preventDefault();
	  $('.pass-form.active [disabled]').removeAttr('disabled');
	  $('.-buttons').removeClass('hidden');
	});

	$(".register-nav-list li").on("click", function (e) {
	  e.preventDefault();
	  $(".register-nav-list li").removeClass("active");
	  $(this).addClass("active");
	  $(".pass").removeClass("active");
	  $(".pass-" + $(this).attr("data-pass")).addClass("active");
	});

	$('.box-button').on('hover, mouseenter', function () {
	  boxButton($(this).find('i'));
	});

	$('.box-button').on('blur, mouseleave', function () {
	  boxButton($(this).find('i'));
	});

	$('.box-button, .box-button a').on('click', function (e) {
	  e.preventDefault();
	  $('.pass-1-form-1-content').show('slow');
	});

	$('.partial-edit-patient').find('input, select').attr('disabled', true);

	function boxButton(i) {
	  if (i.hasClass('icon-person5')) {
		i.switchClass("icon-person5", "icon-person6", 250, "easeInOutQuad");
	  } else {
		i.switchClass("icon-person6", "icon-person5", 400, "easeInOutQuad");
	  }
	};

	$('.box-patient').on('click', function () {
	  var partial = $(this).closest('.partial-edit-patient');
	  openNewPatient(partial);
	});

	$('.edit-person').on('click', function (e) {
	  e.preventDefault();
	  e.stopPropagation();

	  var partial = $(this).closest('.partial-edit-patient');

	  if (!partial.hasClass('active')) {
		openNewPatient(partial);
	  }

	  partial.find('input, select, .form-submit').removeAttr('disabled');

	});

	$('.new-cep-charges').on('click', function () {
	  $(this).closest('.register-new-patient').find('.cepPaciente').show('slow');
	});

	$('.button-pay').on('click', function () {

	  var content = $(this).data('pay');

	  $('.pay-content').removeClass('active');
	  if (content == "card") {
		$('#pay-card').addClass('active');
		$('#pay-ticket').addClass('hidden');
		$('#pref-date').addClass('hidden');
	  }
	  else {
		$('#pay-card').removeClass('active');
		$('#pay-ticket').removeClass('hidden');
		$('#pref-date').removeClass('hidden');
	  }


	  $('.button-pay').removeClass('active');
	  $(this).addClass('active');

	  $('.button-pay').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
	  $(this).find('.icon').addClass('icon-mark').removeClass('icon-unmark');

	  $("#forma-pagamento").val($(this).attr("data-formaPagamento"));
	});

	$('.edit-pay').on('click', function (e) {
	  e.preventDefault();
	  $('.pay-content').show('slow');
	});

	$(".new-cep-charges").on("click", function (e) {
	  $(this).addClass("hidden");
	  $(".cepCobrancaFisica").removeClass("hidden");
	});

	$(".form-cancel-submit").on("click", function (e) {
	  e.preventDefault();
	  $(".new-cep-charges").removeClass("hidden");
	  $(".cepCobrancaFisica").addClass("hidden");
	  $("#cep-charges").val('');
	  $("#address-charges").val('');
	  $("#address-number-charges").val('');
	  $('#address-complement-charges').val('');
	  $("#state-physical-charges").val('');
	  $("#state-physical-charges").change();
	  $("#complement-charges").val('');
	});

	$(".new-cep-charges-juridica").on("click", function (e) {
	  $(this).addClass("hidden");
	  $(".cepCobrancaJuridica").removeClass("hidden");
	});

	$(".form-cancel-submit-juridica").on("click", function (e) {
	  e.preventDefault();
	  $(".new-cep-charges-juridica").removeClass("hidden");
	  $(".cepCobrancaJuridica").addClass("hidden");
	  $("#cep-charges2").val('');
	  $("#addresscharges").val('');
	  $("#address-number-charges-juridic").val('');
	  $('#address-complement-charges-juridic').val('');
	  $("#state-juristic-charges").val('');
	  $("#state-juristic-charges").change();
	  $("#bairro-juristic").val('');
	});

	$('#card-number').on("keydown", function (e) {
	  if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode != 8 && e.keyCode != 46 && e.keyCode != 9 && e.keyCode != 37 && e.keyCode != 39) {
		e.preventDefault();
	  }
	});

	$('#card-number').mask("9999.9999.9999.9999");
  }



  function openNewPatient(partial) {
	if (partial.hasClass('active')) {
	  partial.removeClass('active');
	  partial.find('.new-patient-content').hide('slow');
	  partial.find('.icon').switchClass("icon-arrow-up", "icon-arrow-down", 200, "easeInOutQuad");

	} else {
	  partial.addClass('active');
	  partial.find('.new-patient-content').show('slow');
	  partial.find('.icon').switchClass("icon-arrow-down", "icon-arrow-up", 200, "easeInOutQuad");
	  partial.find('input, select').attr('disabled', true);
	  $(partial).find("#relationship").val($(partial).find("#relacionamento-value").val());
	}
  }


  $("#form-nova-senha").on("submit", function (e) {
	e.preventDefault();
	var form = $(this);

	var error = false;
	var senha = form.find('[name="novaSenha"]');
	var confirmaSenha = form.find('[name="senha-confirme"]');

	if (validation.isNull(senha.val()) || senha.val().length < 4 || senha.val().length > 18) {
	  error = true;
	  senha.addClass('error');
	} else {
	  senha.removeClass('error');

	  if (validation.isNull(confirmaSenha.val()) || confirmaSenha.val().length < 4 || confirmaSenha.val().length > 18) {
		error = true;
		confirmaSenha.addClass('error');
	  }
	  else {
		confirmaSenha.removeClass('error');
		if (confirmaSenha.val() !== senha.val()) {
		  error = true;
		  confirmaSenha.addClass('error');
		  senha.addClass('error');
		}
	  }
	}

	if (!error) {
	  var loading = new Loading();
	  $.ajax({
		url: form.attr("action"),
		type: form.attr("method"),
		data: form.serialize(),
		success: function (result) {
		  if (result != null) {
			if (result.Sucesso) {
			  $("#password").val("");
			  $("#password-confirm").val("");
			  location.reload();
			}
			else {
			  ModalShow();
			  Alert('Erro!', result.Mensagem);
			}
		  }
		  loading.hide();
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) {
		  console.log("Erro");
		  loading.hide();
		}
	  });
	}
  });



  $(".ddlEstado").on("change", function () {
	var inputEstado = $(this);
	var parent = $(inputEstado.parents(".estadoCidade"));
	var city = parent.find(".ddlCidade");
	if ($(this).val() !== '') {
	  EstadoSelecionado(city, inputEstado.val());
	}
	else {
	  $(city).html('<option value="" class="hidden"></option>');
	}
  });

  $(".txtCep").on("blur", function () {
	var input = $(this);
	var classCep = "." + input.attr('data-cep');
	cepChange(input.val().replace("-", ""), classCep);
  });

  $(".pay-content form").on("submit", function (e) {
	e.preventDefault();
	var form = $(this);
	var loading = new Loading();
	$.ajax({
	  url: form.attr("action"),
	  type: form.attr("method"),
	  data: form.serialize(),
	  success: function (result) {
		if (result != null) {
		  if (result.Sucesso) {
			location.reload();
		  }
		  else {
			ModalShow();
			Alert('Erro!', result.Mensagem);
		  }
		}
		loading.hide();
	  },
	  error: function (XMLHttpRequest, textStatus, errorThrown) {
		console.log("Erro");
		loading.hide();
	  }
	});
  });

  function cepChange(cep, inputCep) {
	var loading = new Loading();
	$.ajax({
	  type: "GET",
	  url: "https://webmaniabr.com/api/1/cep/" + cep + "/?app_key=" + 'PYlhy37jk1bhwY3WnKq0GZray0xA5MGb' + "&app_secret=" + 'VWjiPK0jzOYROBNZLmfqtmT3PKPcZ7cGrLGRMQG6vA5wMJX1',
	  data: { cep: cep, formato: "json" },
	  success: function (data) {
		$(inputCep).find(".txtBairro").val(data.bairro);
		$(inputCep).find(".txtLogradouro").val(data.endereco);
		var inputEstado = $(inputCep).find(".ddlEstado");
		inputEstado.val(inputEstado.find("[data-uf='" + data.uf + "']").val());
		var inputCidade = $(inputCep).find(".ddlCidade");
		EstadoSelecionado(inputCidade, inputEstado.val(), function (inputCidade) { inputCidade.val(inputCidade.find("[data-nome='" + data.cidade + "']").val()); loading.hide(); }, loading);
	  }
	});
  }






  function EstadoSelecionado(inputCidades, estadoID, callBackFunction, loading) {
	if (!loading)
	  loading = new Loading();
	if (estadoID != 0) {
	  $.ajax({
		type: "GET",
		url: $("#urlListarCidades").val(),
		data: { id: estadoID },
		success: function (data) {
		  inputCidades.html('<option value="" class="hidden"></option>');
		  $.each(data, function (index, value) {
			inputCidades.append('<option value="' + value.ID + '" data-nome="' + value.Nome + '">' + value.Nome + '</option>');
		  });

		  if (callBackFunction)
			callBackFunction(inputCidades);
		  else
			loading.hide();
		}
	  });
	} else {
	  loading.hide();
	}
  }

  function insertMask() {
	$('[name="dataNascimento"], [name="data_nascimento"]').mask('00/00/0000', { placeholder: "__/__/____" });

	MascaraTelefone($('[name="telefone1"], [name="telefone2"]'), 11);

	$('[name="telefone1"], [name="telefone2"]').keyup(function () {
	  MascaraTelefone($(this), 15);
	});

	$('[name="cpf"]').mask('000.000.000-00', { reverse: true });
	$('[name="CEPCadastro"]').mask('00000-000');
	$('[name="CepCobranca"]').mask('00000-000');
	$('[name="CEP"]').mask('00000-000');
	$('[name="cnpj"]').mask('00.000.000/0000-00', { reverse: true });
	$('[name="mesValidade"], [name="anoValidade"]').mask('00');
	$('[name="codigoSeguranca"]').mask('000');

  }

  function geolocalizacao(endereco, inputLatitude, inputLongitude, callBackFunction, c = 0) {
	if (endereco && inputLatitude && inputLongitude) {
	  $.ajax({
		url: "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDrUUCk1Yz5alT7PyYHzaur3-9fp_oRPlg&address=" + endereco + "&sensor=false",
		type: "POST",
		success: function (res) {
		  var lat = ""; var long = "";
		  if (res.results.length != 0) {
			lat = res.results[0].geometry.location.lat;
			long = res.results[0].geometry.location.lng;
		  }
		  if ((!lat || !long) && c < 6) {
			setTimeout(() => { geolocalizacao(endereco, inputLatitude, inputLongitude, callBackFunction, c++) }, 10000);
		  } else if (c == 6) {
			notifica('Nao foi poss�vel validar o endere�o no momento.', 'erro');
		  } else {
			inputLatitude.val(lat);
			inputLongitude.val(long);
			if (callBackFunction)
			  callBackFunction();
		  }
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) {
		  console.log("Erro");
		  if (callBackFunction)
			callBackFunction();
		}
	  });
	}
	if (callBackFunction)
	  callBackFunction();

  }
  function MascaraTelefone(inputs, length) {
	inputs.each(function () {
	  var input = $(this);
	  var mask = "(00) 00000-0000";
	  if (input.val().length < length) {
		mask = "(00) 0000-0000Z";
	  }
	  if ((input.attr("mascara") == undefined) || (input.attr("mascara") != mask)) {
		input.attr("mascara", mask);
		input.unmask().mask(mask, {
		  translation: {
			'Z': {
			  pattern: /[0-9]/, optional: true
			}
		  }
		});
		setTimeout(function () { input[0].selectionStart = input[0].selectionEnd = 10000; }, 0);
	  }
	});
  };

  return init();

};
