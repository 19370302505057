var ProfessionalContatc = function(){

    var validation = new Validation();
    
	function init() {
		addListeners();
	}
    
	function addListeners() {

        $('.FormHelp .form-button').on('click', function(e) {
            e.preventDefault();
            validationFormAjuda($(this).parents('form'));
        }); 

        $(".FormHelp").on("submit", function(e)
        {
            e.preventDefault();
            var form = $(this);
            var loading = new Loading();
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: form.serialize(),
                success: function (result) {
                    if(result.Sucesso)
                    {
                        ModalShow();
                        Alert('Sucesso!','Email enviado com sucesso');
                        form[0].reset();
                    }
                    else
                    {
                        ModalShow();
                        Alert('Erro!',result.Mensagem);
                    }

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro");
                    loading.hide();
                }
            });
        });      
	}

    function validationFormAjuda(form) {

        var error = false;
        var assunto = form.find('[name="Assunto"]');
        var nome = form.find('[name="Nome"]');
        var email = form.find('[name="Email_"]');
        var mensagem = form.find('#equipments');

        if (validation.isNull(mensagem.val())) {
            error = true;
            mensagem.addClass('error');
        } else {
            mensagem.removeClass('error');
        }       

        if (validation.isNull(assunto.val())) {
            error = true;
            assunto.addClass('error');
        } else {
            assunto.removeClass('error');
        }       

        if (validation.isNull(nome.val())) {
            error = true;
            nome.addClass('error');
        } else {
            nome.removeClass('error');
        }
            
        if (!validation.IsEmail(email.val()) || validation.isNull(email.val())) {
            error = true;
            email.addClass('error');
        } else {
            email.removeClass('error');
        }
        if (!error) {
            form.submit();
        } 
    }
	return init();

};
