var NovaSenha = function() {

	var validation = new Validation();
	
	function init() {
		addListeners();
	}

	function addListeners()
	{
		$('.to-access a').on("click",function (e) {
			e.preventDefault();
			validationForm();		
			
		});

		$('form').on("submit",function (e) {
			e.preventDefault();
            var form = $(this);
            var loading = new Loading();
            $.ajax({
				url: form.attr("action"),
				type: form.attr("method"),
				data: form.serialize(),
				success: function (result) {
					$('[name="Senha"]').val('');
					$('[name="NovaSenha"]').val('');
					Alert("Atenção", result.Mensagem)
					ModalShow();
					loading.hide();
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
				    console.log("Erro")
				}
			});
		});	
	}

	
	function validationForm() {

    	var error = false;
		var form = $('form');

		var senhaMin = 4;
    	var senhaMax = 18;

    	
    	var senha = form.find('[name="Senha"]');
    	var novaSenha = form.find('[name="NovaSenha"]');

    	if (senha.val().length < senhaMin || senha.val().length > senhaMax || validation.isNull(senha.val()) || senha.val() != novaSenha.val()) {
    		error = true;
    		senha.addClass('error');
    		novaSenha.addClass('error');
    	} else {
    		senha.removeClass('error');
    		novaSenha.removeClass('error');
    	}
    	if (!error) {
    		form.submit();
    	} 
    }

    return init();

};