var Home = function(){


	var validation = new Validation();
	var mobile = false;
	var slidesToShow = 4;

	if (screen.width <= 992) {
		mobile = true;
	}

	function init() {
		// $('html,body').animate({
		//   scrollTop: $(window.location.hash).offset().top
		// },1500);		
		
		if($(window.location.hash) && $(window.location.hash).offset()){
			
			$("html, body").animate({ scrollTop: $(window.location.hash).offset().top}, 1500);

		}

		if (mobile) {
			slidesToShow = 1;
		}

		if ($('#carousel-step2').length > 0) {

			$('#carousel-step2').slick({
				infinite: true,
				slidesToShow: slidesToShow,
				slidesToScroll: slidesToShow,
				arrows: true
			});
		}

		addListeners();
	}


	function addListeners() {

		$(".search-location").on("click", function(e)
		{
			e.preventDefault();
			get_location();
		});

		$('.block-drop').on('click', function() {

			if ($(this).hasClass('active')) {

				$(this).removeClass('active');
				$(this).find('article').hide('fast');

			} else {
				
				$('.block-drop.active').find('article').hide('fast');
				$('.block-drop.active').removeClass('active');

				$(this).addClass('active');
				$(this).find('article').show('fast');

			}

		});

		$('.step-1 .form-submit').on('click', function(e) {
			e.preventDefault();
			if($(this).attr('disabled') != 'disabled')
				validationStep1();
		});

		$('.step-2 .block-highlight').on('click', function() {
			$('.step-2 .block-highlight').removeClass('active');
			$(this).addClass('active');
			$('.step-2 .form-submit').removeAttr('disabled');
			$("#servico").val($(this).find(".servicoID").val());
			
		});
		$('.step-2 .-next').on('click', function(e) {
			e.preventDefault();
			validationStep2();	
		});

		$('.step-3 .-next').on('click', function(e) {
			e.preventDefault();
			validationStep3();	
		});
		$('.step-3 .-back').on('click', function(e) {
			e.preventDefault();
			$('.circle-description-2 span').text("");
			$('.circle-3').addClass('-off').removeClass('-on');
			$('.steps-content .circle-2').replaceWith('<i class="circle -on circle-2">2</i>');
			$(".step-3 .block-highlight").removeClass("active")
			$("#patologia").val(0);
			$("#attendance-type-hidden").val(0);
			$("#attendance-type").val("");

			$('.step-3 .form-submit').attr('disabled','disabled');
			$('.home-care .step-3').fadeOut('fast');
			$('.home-care .step-2').fadeIn('fast');
			$('#carousel-step2').slick('unslick');
			$('#carousel-step2').slick({
				infinite: true,
				slidesToShow: slidesToShow,
				slidesToScroll: slidesToShow,
				arrows: true
			});
		});

		$('.pass-4-form-1 .-back').on('click', function (e) {
			e.preventDefault();
			$('.circle-description-3 span').text("");
			$('.circle-4').addClass('-off').removeClass('-on');
			$('.steps-content .circle-3').replaceWith('<i class="circle -on circle-3">3</i>');
			$(".step-4 .block-highlight").removeClass("active");
			$('.home-care .step-4').fadeOut('fast');
    		$('.home-care .step-3').fadeIn('fast');
		});

		$('.pass-4-form-1 .form-submit').on('click', function (e) {
			e.preventDefault();
			validationStep4();
		});


		/*AutoComplete*/
		$('.home-care').on('click','.list-result li', function() {
			var id = $(this).data('id');
			$('#place-care').val($(this).attr('data-nome'));
			$('#CidadeID').val(id);
			$('.step-1 .form-submit').removeAttr('disabled');
		});

		$('#place-care').keyup(function(e)	{
			$('.step-1 .form-submit').attr('disabled','disabled');
			
			if($(this).val().length >= 3) {

				$.ajax({
	                url: $("#pesquisa-cidade").val() + "?cidade=" + $(this).val(),
	                type: "GET",
	                success: function (result) {
	                    if (result != null) { 
	                        if(result.Sucesso)
	                        {

	                        	$('.list-result').empty();
	                        	$.each(result.Retorno, function(i,e){
	                        		$('.list-result').append('<li data-id="' + e.ID +'" data-nome="' + e.Nome + '">' + e.Nome + ' - ' + e.EstadoUF + '</li>');
	                        	});

	                        	$('.list-result').slideDown('fast');
	                        	
	                        }
	                        else
	                        {	
	                        	ModalShow();
	                            Alert('Erro!',result.Mensagem);
	                        }
	                    }
	                },
	                error: function (XMLHttpRequest, textStatus, errorThrown) {
	                    console.log("Erro")
	                }
	            });	
			} else {
				$('.list-result').slideUp('fast');
				$('.list-result').empty();
			}
		});
        $('body').on("click", function (e) {
            
            $('.list-result').slideUp('fast');
            $('.list-result-attendance').slideUp('fast');
        });

	    /*AutoComplete*/

		$('.step-1 form').on("submit",function(e)
		{
			e.preventDefault();
			if($('.step-1 .form-submit').attr('disabled') != 'disabled')
			{
				var form = $(this);
			var loading = new Loading();
			
			$.ajax({
                url: form.attr('action'),
                type: form.attr('method'),
                data: form.serialize(),
                success: function (result) {
                    if (result.Sucesso) { 
                        location.href = result.Retorno;
                    }
                    else
                    {
                    	ModalShow();
                        Alert('Erro!',result.Mensagem);                    	
                    }

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro")
                    loading.hide();
                }
            });		
			}
			
			
			
		});

		$(".step-4 form").on("submit", function(e)
		{
			e.preventDefault();
			var form = $(this);
			var loading = new Loading();
			$.ajax({
                url: form.attr('action'),
                type: form.attr('method'),
                data: form.serialize(),
                success: function (result) {
                	if(result.Sucesso)
                    {
                	    $('.home-care .step-5').fadeIn('fast');
                	    $('#telOrcamento').hide();
                	    $('#cidadeRetorno').text(result.Retorno.Cidade);
                	    $('#servicoRetorno').text(result.Retorno.Servico);
                	    $('#patologiaRetorno').text(result.Retorno.Patologia);
                        $('#valorRetorno').text("R$" + result.Retorno.Valor);
                        var botaoCadastro = $("#attendance-button");
                        var enderecoCadastro = botaoCadastro.prop("href");
                        botaoCadastro.prop("href", enderecoCadastro + result.Retorno.Token);

                			$('.steps-content').fadeOut('fast');
                		}
                		else
                        {
                        	ModalShow();
                            Alert('Erro!',result.Mensagem);
                        }
                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro")
                    loading.hide();
                }
            });	
		});

		$("#sem-patologia").on("click",function(e)
		{
			e.preventDefault();
			$("#patologia").val(0);
			$("#patologia-nao-marcada").val(1);
			$('#attendance-type-hidden').val("");
			$('#attendance-type').val("");
			$('.step-3 .form-submit').attr('disabled','disabled');
			validationStep3();
		});


		/*AutoComplete*/
		$('.home-care').on('click','.list-result-attendance li', function() {
			var id = $(this).data('id');

			$('#attendance-type').val($(this).attr('data-attendance'));
			$('#attendance-type-hidden').val($(this).attr('data-id'));
			$('#attendance-type-hidden').attr('data-nome', id);

			$("#patologia").val($(this).attr('data-id'));
			$("#patologia-nao-marcada").val(0);

			$('.list-result-attendance').slideUp('fwast');

			if (!validation.isNull($('#attendance-type-hidden').val())) {
				$('.step-3 .form-submit').removeAttr('disabled');
			}
		});

		$('#attendance-type').keyup(function(e) {
			$("#patologia").val("");
			$('#attendance-type-hidden').val("");
			$('.step-3 .form-submit').attr('disabled','disabled');

			if ($(this).val().length >= 3) {

				$.ajax({
	                url: $(this).attr("data-href") + "?servicoID=" + $("#servico").val() + "&patologia=" + $(this).val(),
	                type: "GET",
	                success: function (result) {
	                    if (result != null) { 
	                        if(result.Sucesso)
	                        {
	                        	$('.list-result-attendance').empty();
	                        	$.each(result.Retorno, function(i,e){
	                        		$('.list-result-attendance').append('<li data-id="' + e.ID +'" data-attendance="' + e.Nome + '">' + e.Nome + '</li>');
	                        	});

	                        	$('.list-result-attendance').slideDown('fast');
	                        	
	                        }
	                        else 
	                        {	
	                        	ModalShow();
	                            Alert('Erro!',result.Mensagem);
	                        }
	                    }
	                },
	                error: function (XMLHttpRequest, textStatus, errorThrown) {
	                    console.log("Erro")
	                }
	            });	

			} else {
				$('.list-result-attendance').slideUp('fast');
				$('.list-result-attendance').empty();
			}
			
		});

	    /*$('#attendance-type').on('blur', function() {
	        $('.list-result-attendance').slideUp('fast');
	    });*/
	    /*AutoComplete*/

	}

	function validationStep1() {
		var error = false;
    	var form = $('.form1');

    	var localAtendimento = form.find('[name="localAtendimento"]');

    	if (validation.isNull(localAtendimento.val())) {
    		error = true;
    		localAtendimento.addClass('error');
    	} else {
			localAtendimento.removeClass('error');
    	}

    	if (!error) {
    		form.submit();
    	}
	}

	function validationStep2() {
		var error = false;

		if ($('.step-2 .block-highlight.active').length == 0) {
			error = true;
		}
		if (!error) {

			var loading = new Loading();
			var value = $('.step-2 .block-highlight.active').find('figcaption').text();
			$('.circle-description-2 span').text(value);
			$('.circle-3').addClass('-on').removeClass('-off');
			$('.steps-content .circle-2').replaceWith('<i class="icon-blue-circle-verify circle-2"></i>');
			$('.home-care .step-2').fadeOut('fast');
			$('.home-care .step-3').fadeIn('fast');
			loading.hide();
		}
	}

	function validationStep3() {
		var error = false;

		if (validation.isNull($('#attendance-type-hidden').val()) && $("#patologia-nao-marcada").val() != "1" ) {
			error = true;
		}

		if (!error) {
			var value = $('#attendance-type').val();
			$('.circle-description-3 span').text(value);
			$('.circle-4').addClass('-on').removeClass('-off');

			$('.steps-content .circle-3').replaceWith('<i class="icon-blue-circle-verify circle-3"></i>');
    		
    		$('.home-care .step-3').fadeOut('fast');
    		$('.home-care .step-4').fadeIn('fast');
		}
	}

	function validationStep4() {
    	var error = false;
    	var form = $('.pass-4-form-1');

    	var nome = form.find('[name="nome"]');
    	var email = form.find('[name="email_"]');
    	var servico = form.find('[name="servicoID"]');
    	var patologia = form.find('[name="patologiaID"]');

		if (validation.isNull(nome.val())) {
    		error = true;
    		nome.addClass('error');
    	} else {
    		nome.removeClass('error');
    	}

    	if (!validation.IsEmail(email.val()) || validation.isNull(email.val())) {
    		error = true;
    		email.addClass('error');
    	} else {
			email.removeClass('error');
    	}

    	if (!error) {

    	    $(".step-4 form").submit();
    	} 
    }



    function get_location() {
    	var loading = new Loading();
    	var input = $("#pesquisa-cep");
  		navigator.geolocation.getCurrentPosition(
  			function (posicao)
  			{

  				$.ajax({
					  url: "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDrUUCk1Yz5alT7PyYHzaur3-9fp_oRPlg&address=" + posicao.coords.latitude + " " + posicao.coords.longitude + "&sensor=false",
            		type: "POST",
            		success: function (res) {

	                	if (res.results.length != 0) {
	                	    $.each(res.results[0].address_components, function (i, item) {
	                			if(res.results[0].address_components[i].types[0] == "postal_code")
	                			{
	                			    var cepLocal = res.results[0].address_components[i].long_name;
	                			    if (cepLocal.length <= 5) {
	                			        cepLocal += "001";
	                			    }
	                				//console.log(res.results[0].address_components[i]);
	                				//console.log("" + res.results[0].address_components[i].long_name);
	                				//console.log(res.results[0].address_components[i].long_name.replace('-','').replace('.',''));
	                				
                                    $.ajax({
                                        url: input.val() + "?cep=" + cepLocal.replace('-', '').replace('.', ''),
										type: "GET",
										success: function (result) {

											if(result.Sucesso)
											{
												$('#CidadeID').val(result.Retorno);
												$('.step-1 .form-submit').removeAttr('disabled');
												$('.form1').submit();		
											}
											else
				        					{
                        						ModalShow();
                            					Alert('Erro!',result.Mensagem);
				        					}

				        					loading.hide();
				        				},
										error: function (XMLHttpRequest, textStatus, errorThrown) {
				    						console.log("Erro");
				    						loading.hide();
										}
									});
    							}
    						})
	                	}
                	},
            		error: function (XMLHttpRequest, textStatus, errorThrown) {
				    	console.log("Erro");
				    	loading.hide();
					}
        		});
  			},
  			function(erro){
  				loading.hide();
  				var erro = "Devido a algum problema, sua localização não foi encontrada";
  				switch(erro.code)
  				{
  					
  					case erro.POSITION_UNAVAILABLE:
          				erro = 'Localização Indisponível.';
        			break;
        			case erro.PERMISSION_DENIED:
          				erro = 'Geolocalização não autorizada';
        			break;

  				}
  				ModalShow();
  				Alert('Erro!',result.Mensagem);
  			}
  		);
  	}
	return init();

};
