var CircleProgressBar = function() {


	function init() {

		$('.RegisterData').each(function(){

			var dataId = $(this).data('id');
			var dataIdValue = $('.'+dataId+'').children('input').val();

			console.log('dataId: '+dataId);

			$('.'+dataId+'').circleProgress({
				startAngle: 4.7,
				reverse: false,
				thickness: '10',
				fill: '#a2ba54',
				emptyFill: '#f14c00'
			});

			// if(dataId == 'contrato'){
				if(dataIdValue === 'True'){
					console.log(dataId);
					$('.'+dataId+'').circleProgress({ value: '1' });
					$('#'+dataId).addClass('fa fa-check');
					$('.'+dataId).next('p').text('Todos os campos foram preenchidos. Obrigado.');
				}else{
					$('#'+dataId).addClass('fa fa-close');
					$('.'+dataId).next('p').text('Há campos obrigatórios que ainda precisam ser informados.');
				}
			// }else if(dataIdValue < 1){
			// 	var newValue = dataIdValue.split('.').join(' ');			
			// 	$('.'+dataId+'').circleProgress({ value: dataIdValue });
			// 	$('.'+dataId+'').children().children('strong').text(newValue);
			// 	$('.'+dataId+'').next('p').text('Há campos obrigatórios que ainda precisam ser informados.');
			// }else{
			// 	$('.'+dataId+'').circleProgress({ value: dataIdValue });
			// 	$('.'+dataId+'').children().children('strong').text('100%');
			// 	$('.'+dataId+'').next('p').text('Todos os campos foram preenchidos. Obrigado.');
			// }

		});

	};

	return init();
}