var Notices = function(){

    var templateCompile;
    var templateSource = $(".template-avisos").html();
    var templateDestiny = $("#lista-quadros");
    
	function init() {
		new BlockNotices();

		addListeners();
        compileAddresTemplate();
	}
    
    function compileAddresTemplate() {
		templateCompile = dust.compile(templateSource, 'noticesList');
        dust.loadSource(templateCompile);
	};
    
	function addListeners() {
		$("a.more-noticy").on("click",function(e) {
             e.preventDefault();
            var link = $(this);
            var loading = new Loading();
            $.ajax({
				url: link.data("href"),
				type: "GET",
				success: function (result) {
				    if (result != null) { 
				        if(result.Sucesso)
				        {
                            $grid.masonry('destroy');
                            dust.render('noticesList', result.Retorno, function(err, out) {
                                templateDestiny.append(out);
                            });
                            $grid = $('.grid').masonry({
                                itemSelector: '.grid-item'
                            });
                            
                            if(result.Retorno.ProximaPagina == "")
                            {
                                $(".more-noticy").remove();
                            }
                            else
                            {
                                link.attr("href",result.Retorno.ProximaPagina);
                            }
                        }
				        else
				        {
                            ModalShow();
                            Alert('Erro!',result.Mensagem);
				        }
				    }
                    
                    loading.hide();
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
				    console.log("Erro");
                    loading.hide();
                }
			});

        });
	}

	return init();

};
