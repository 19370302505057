var Attendance = function() {

    var templateCompile;
    var templateSource = $(".template-attendance-row").html();
    var templateDestiny = $("#atendimentos");

    //mobile
    var templateCompileMobile;
    var templateSourceMobile = $(".table-row-mobile-template").html();
    var templateDestinyMobile = $("#atendimentosMobile");
    //


    var templateModalCompile;
    var templateModalSource = $(".template-attendance-detail").html();
    var templateModalDestiny = $("#modal-attendance");
    var limparFiltro = false;
    
    function init() {
		insertMask();
        compileAddresTemplate();
        addListeners();
	}

    function compileAddresTemplate() {
		templateCompile = dust.compile(templateSource, 'attendanceList');
        templateModalCompile = dust.compile(templateModalSource, 'attendanceModal');
		dust.loadSource(templateCompile);
		dust.loadSource(templateModalCompile);

        //mobile
		templateCompileMobile = dust.compile(templateSourceMobile, 'attendanceListMobile');
		dust.loadSource(templateCompileMobile);
        //
	};

	function insertMask() {
		$('[name="DataInicio"], [name="DataFim"]').mask('00/00/0000', {placeholder: "__/__/____"});
	}

    function addListeners() {



        $('.carousel-rows').slick({
            dots: true,
            arrows: false
        });

        $('.button-status').on('click', function () {
            $('.button-status').removeClass('active');
            $(this).addClass('active');
            $("#status-filtro").val($(this).attr("data-value"));
            $('.-filter').click();
        });


        $('.form-filter').on("submit",function(e) {
            e.preventDefault();
            var form = $(this);

            if(limparFiltro)
            {
                form[0].reset();
                $(".button-status.-all").click();

            }
            var loading = new Loading();
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: form.serialize(),
                success: function (result) {
                    if (result != null) { 
                        if(result.Sucesso)
                        {
                            var button = $(".button-report")[0]; 
                            var href = button.getAttribute('data-href') + "?" + form.serialize();
                            button.href = href;
                            //$("#cont-agendados").html(result.Retorno.Agendados);
                            //$("#cont-realizados").html(result.Retorno.Realizados);
                            //$("#cont-cancelados").html(result.Retorno.Cancelados);
                            $("#cont-itens").html(result.Retorno.NumeroItens);
                            if(limparFiltro)
                                $("#limpar-filtro").addClass("hidden");
                            else
                                $("#limpar-filtro").removeClass("hidden");
                                
                            templateDestiny.html('');
                            templateModalDestiny.html('');
                            dust.render('attendanceList', result.Retorno, function(err, out) {
                                templateDestiny.html(out);
                            });
                            
                            dust.render('attendanceModal', result.Retorno, function(err, out) {
                                templateModalDestiny.html(out);
                            });



                            templateDestinyMobile.html('');
                            dust.render('attendanceListMobile', result.Retorno, function (err, out) {
                                templateDestinyMobile.html(out);
                            });
                            $('.link-style-2').addClass('hidden');
                            $('.carousel-rows').addClass('hidden');
                            $('.table-row-mobile').addClass('hidden');
                            $('#atendimentosMobile').removeClass('hidden');



                        }
                        else
                        {
                            ModalShow();
                            Alert('Atenção!',result.Mensagem);
                            
                        }
                    }

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro");
                    loading.hide();
                }
            });

        });
        
        $(".attendance").on("click",".modal-evolucao button", function(e)
        {
            e.preventDefault();
            $(this).parents("form").submit();
        });


        $(".attendance").on("submit",".modal-evolucao", function(e)
        {
            e.preventDefault();
            var form = $(this);

            var loading = new Loading();
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: form.serialize(),
                success: function (result) {
                    if (result != null) { 
                        $(".close-reveal-modal").click();
                        if(result.Sucesso)
                        {
                            ModalShow();
                            Alert('Sucesso!','Evolução cadastrada com sucesso.');   

                        }
                        else
                        {
                            ModalShow();
                            Alert('Erro!',result.Mensagem);
                        }
                    }

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro");
                    loading.hide();
                }
            });
        });

        $(".form-button.-filter").on("click",function(e) {
            e.preventDefault();
            limparFiltro = false;
            $('.carousel').addClass('hidden');
            $('.form-filter').submit(); 
        });

        $("#limpar-filtro").on("click",function(e) {
            e.preventDefault();
            if (!$('#atendimentosMobile').hasClass('hidden')) {
                location.reload();
            } else {
                limparFiltro = true;
                $('.form-filter').submit();
            }
        });

       	$("#atendimentos").on('click','.btn-cancel',function(e)
       	{
       	    e.preventDefault();
       	    $("#motivo").removeClass('error');
       	    $("#motivo").val('');
       		$("#cancel-url").val($(this).attr("href"));
       		$("#open-modal-cancel").click();
       	});

       	$(".table-row-mobile").on('click', '.btn-cancel', function (e) {
       	    e.preventDefault();
       	    $(".reveal-modal.-cancel").width(340);
       	    $(".reveal-modal.-cancel").css({ "margin-left": "-170px" });
       	    $("#motivo").removeClass('error');
       	    $("#motivo").val('');
       	    $("#cancel-url").val($(this).attr("href"));
       	    $("#open-modal-cancel").click();
       	});

       	$("#atendimentosMobile").on('click', '.btn-cancel', function (e) {
       	    e.preventDefault();
       	    $(".reveal-modal.-cancel").width(340);
       	    $(".reveal-modal.-cancel").css({ "margin-left": "-170px" });
       	    $("#motivo").removeClass('error');
       	    $("#motivo").val('');
       	    $("#cancel-url").val($(this).attr("href"));
       	    $("#open-modal-cancel").click();
       	});

       	$(".confirm-cancelamento").on("click",function(e)
       	{
       	    e.preventDefault();

       	    if ($("#motivo").val())
       	    {
       	        var loading = new Loading();
       	        $.ajax({
       	            url: $("#cancel-url").val() + "/" + $("#motivo").val(),
       	            type: "GET",
       	            success: function (result) {
       	                $(".close-reveal-modal").click();
       	                if (result.Sucesso) {
       	                    ModalShow();
       	                    Alert('Sucesso!', 'Atendimento Cancelado com Sucesso');

       	                    $('.form-filter').submit();
       	                }
       	                else {
       	                    ModalShow();
       	                    Alert('Erro!', result.Mensagem);

       	                }

       	                loading.hide();
       	            },
       	            error: function (XMLHttpRequest, textStatus, errorThrown) {
       	                $(".close-reveal-modal").click();
       	                console.log("Erro");
       	                loading.hide();
       	            }
       	        });
       	    }
       	    else
       	    {
       	        $("#motivo").addClass('error');
       	    }
       		
       	});

       	$(".cancel-cancelamento").on("click", function(e)
       	{
       		e.preventDefault();
       		$(".close-reveal-modal").click();
       	});
        
        $('.table-result').on('click', '.link-style-2',function(e){
            e.preventDefault();
            $(this).next().removeClass('hidden');
            $('.link-style-2').addClass('hidden');
            $('.carousel-rows').addClass('hidden');
            $('.btn-back-view').removeClass('hidden');
        });

        $('.table-result').on('click', '.btn-back-view',function(e){
            e.preventDefault();
            $('.link-style-2').removeClass('hidden');
            $('.carousel-rows').removeClass('hidden');
            $('.table-item-mobile').addClass('hidden');            
            $(this).addClass('hidden');
        });        
        
    }
    
	return init();

};
