var Index = function(){

    var validation;
    var templateCompile;
    var templateSource = $(".template-addres").html();
    var templateDestiny = $("#locais-paciente");
    
	function init() {
		addListeners();
        validation = new Validation()
        new RegisterAttendance(CallBackSubmit,validation);
        compileAddresTemplate();
	}
    
    function CallBackSubmit(){
    	//TweenLite.to('html,body', 1, {scrollTop: 0, ease:Power1.easeOut}); 
    	$("#select-register-patients").val("");
        $("#select-register-patients").change();
        $("#open-modal-submit").click();
        
    }
    
    function compileAddresTemplate() {
        templateCompile = dust.compile(templateSource, 'addresList');
        dust.loadSource(templateCompile);
	};

	function addListeners() {
        $('#select-register-patients').on('change', function(e) {
			e.preventDefault();
            var input = $(this);
            if(input.val())
            {
                var loading = new Loading();
                $.ajax({
                    url: input.attr("data-href") + "?pacienteID=" + input.val(),
                    type: "GET",
                    success: function (result) {
                        if(result.Sucesso)
                        {
                            templateDestiny.html('');
                            dust.render('addresList', result.Retorno, function(err, out) {
                                templateDestiny.html(out);
                            });
                            
                            $('[data-page="index"]').find('.register').addClass('active');
                        }
                        else
                        {
                            ModalShow();
                            Alert('Erro!',result.Mensagem);
                        }
                        loading.hide();
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log("Erro");
                        loading.hide();
                    }
                });
            }
            else
            {
                $('[data-page="index"]').find('.register').removeClass('active');
            }
        });
        
        $('#locais-paciente').on('click', '.button-location', function(e) {
			e.preventDefault();

			$('.button-location').removeClass('active');
			$(this).addClass('active');

			$('.button-location').find('.icon-location-active').addClass('icon-location').removeClass('icon-location-active');
			$(this).find('.icon-location').addClass('icon-location-active').removeClass('icon-location');

			$('.button-location').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');
            $("#endereco-paciente-check").val($(this).find(".endereco-paciente-id").val());
            
            $("#local-atendimento").val($(this).find(".endereco-paciente-id").val());
            $(".ddlPatologia").change();

		});
	}

	return init();

};
