$(document).ready(function(){
	
	new RevealModal();
	new JqueryUI();
	new JqueryMask();
	new SliderSlick();
	new DustFull();
	new DustHelpers();
	new Header();
	new _TweenLite();
	

	if ($('[data-page="register"]').length > 0) {
		
		new Register();
	} 
	if ($('[data-component="profissional-contato"]').length > 0) {
		new ProfessionalContatc();
	} 	
	if ($('[data-page="cadastro-profissional-disponibilidade-atendimento"]').length > 0 || $('[data-page="profissional-meus-dados-disponibilidade-atendimento"]').length > 0) {
		new DisponibilidadeAtendimento();
		new Availability();
		//new CadastroProfissional();		
	} 	
	if ($('[data-page="cadastro-profissional-dados-academicos"]').length > 0 || $('[data-page="profissional-meus-dados-academicos"]').length > 0) {
		new AcademicData();
	}
	if ($('[data-page="agenda"]').length > 0) {
		new Agenda();
	}	
	if ($('[data-partial="header-profissional"]').length > 0) {
		new HeaderProfissional();
	} 
	if ($('[data-page="profissional-financeiro"]').length > 0) {
		new ProfissionalFinanceiro();
	}

	if ($('[data-page="index"]').length > 0) {
		new Index();
	} 

	if ($('[data-page="notices"]').length > 0) {
		
		new Notices();
	}

	if ($('[data-page="attendance"]').length > 0 || $('[data-page="profissional-atendimentos"]').length > 0) {
		new Attendance();
	} 
	if ($('[data-page="cadastro-profissional-resumo"]').length > 0) {
		new CircleProgressBar();
	} 	

	if ($('[data-page="financial"]').length > 0) {
		new Financial();
	} 

	if ($('[data-page="cadastro-profissional-central-de-ajuda"]').length > 0) {
		new ProfessionalHelp();
	} 
	if ($('[data-page="cadastro-profissional-status-envio"]').length > 0) {
		new ProfessionalHelp();
	} 
	if ($('[data-page="indicate-patient"]').length > 0) {
		new IndicatePatient();
	} 
	if ($('[data-page="indicate-professional"]').length > 0) {
		new IndicateProfessional();
	} 
	if ($('[data-page="my-data"]').length > 0) {
		new MyData();
	} 

	if ($('[data-page="home"]').length > 0 || $('[data-page="home-flow"]').length > 0) {
		new Home();
	} 

	if ($('[data-page="login"]').length > 0 ) {
		new Login();
	} 

	if($('[data-page="login-profissional"]').length > 0)
	{
		new LoginProfissional();
	}


	if ($('[data-page="pre-cadastro-profissional"]').length > 0) {
		new PreRegister();
	} 	
	if ($('[data-page="cadastro-profissional-especialidade"]').length > 0 || $('[data-page="profissional-meus-dados-especialidades"]').length > 0) {
		new Speciality();
	} 
	
	if ($('[data-partial="menu-cadastro-profissional"]').length > 0) {
		new ProfissionalMobile();
	}
	 	
	if($('[data-page="cadastro-profissional-resumo"]').length > 0 ||  
	   $('[data-page="cadastro-profissional-contrato"]').length > 0 ||
	   $('[data-page="cadastro-profissional-central-de-ajuda"]').length > 0 ||
	   $('[data-page="cadastro-profissional-experiencia-profissional"]').length > 0 || 
	   $('[data-page="profissional-meus-dados-experiencia-profissional"]').length > 0 ||
	   $('[data-page="profissional-meus-dados-pessoais"]').length > 0 ||
	   $('[data-page="cadastro-profissional"]').length > 0){
		new CadastroProfissional();
	}

	if($('[data-page="nova-senha-profissional"]').length > 0)
	{
		new NovaSenha();
	}

});
