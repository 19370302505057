var ProfessionalHelp = function(){
    
	function init() {

    $('.block-drop').on('click', function() {

        if ($(this).hasClass('active')) {

            $(this).removeClass('active');
            $(this).find('article').hide('fast');

        } else {
            
            $('.block-drop.active').find('article').hide('fast');
            $('.block-drop.active').removeClass('active');

            $(this).addClass('active');
            $(this).find('article').show('fast');

        }

    });        

    }

	return init();

};
