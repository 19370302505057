
var CadastroProfissional = function () {

	var validation = new Validation();
	var form = $('form');
	var devolucao = form.find('[name="Devolucao"]').val();
	if (devolucao !== null && devolucao !== undefined) {
	    if (devolucao !== "") {
	        Alert("Atenção", devolucao);
	        ModalShow();
	    }
	}
    var templateExperienciaSource = $(".experience-template").html();
    var templateExperienciaDest = $(".experiences-list");

	function init() {

		addListeners();
		ProfissionalMasks();
        if($('[data-page="cadastro-profissional-especialidade"]').length > 0){
            SpecialityActivate();
        }
        compileAddresTemplate();

	}

    function compileAddresTemplate() {
        templateCompile = dust.compile(templateExperienciaSource, 'experiencia');
        dust.loadSource(templateCompile);
        
    };

	function addListeners()	
	{
		$('.btn-crop').on('click',function(e){
			e.preventDefault();
			    var fileinput = document.getElementById('imageFile');
    			fileinput.click();
		});

		$(".onlyNumber").on("keydown", function (e) {
	        if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode != 8 && e.keyCode != 46 && e.keyCode != 9 && e.keyCode != 37 && e.keyCode != 39) {
	            e.preventDefault();
	        }
    	});

		$('#imageFile').on('change',function(){
			
			$('.user-image').css('background-image', 'url('+window.URL.createObjectURL(this.files[0])+')');
			$('.user-image').css('background-size','cover');
			$('[name="image"]').val('url('+window.URL.createObjectURL(this.files[0])+')');

		});


		$("body").on('.check-contrato', 'click',function(e){
			alert("teste");
			var inputChek = $(this).find('[type="checkbox"]');
            var iconCheck = $(this).find('.fa-check');
			if(inputChek.is(":checked"))
			{
				inputChek.removeProp("checked");	
                iconCheck.css('display','none');
			}
			else
			{
				inputChek.prop("checked","checked");
                iconCheck.css('display','block');
			}
		});


		$('.form-contrato .form-submit').on('click',function(e)
		{
			e.preventDefault();
			var form = $('.form-contrato');
			var loading = new Loading();
			$.ajax({
				url: form.attr("action"),
				type: form.attr("method"),
				data: form.serialize(),
				success: function (result) {
					if(result.Sucesso)
                        {
                        	location.href = result.Mensagem;
                        }
                        else
                        {
                        	Alert("Atenção",result.Mensagem);
                            ModalShow();
                            loading.hide();
                        }
                    },
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					console.log("Erro");
				}
			});
		});



		$('.to-access a').on("click",function (e) {
			e.preventDefault();
			validationForm();		
		});

        $('[data-page="cadastro-profissional-experiencia-profissional"],[data-page="profissional-meus-dados-experiencia-profissional"]').on('click','.edit',function(e){
		  e.preventDefault();

            var formShow = $(this).parents('.action-icons').next('.form-component');
            var formGroup = $(this).parent().parent('.experience-item');
            $(formShow).slideDown('slow');
            $(formGroup).find('.box').slideUp('slow');
            
            $(this).addClass('hidden');
            $(this).next('.exclude').addClass('hidden');
            $(this).parent('.action-icons').find('.cancel').removeClass('hidden');
        });
        $('[data-page="cadastro-profissional-experiencia-profissional"],[data-page="profissional-meus-dados-experiencia-profissional"]').on('click','.cancel',function(e){
            e.preventDefault();
            var formShow = $(this).parents('.action-icons').next('.form-component');
            $(formShow).slideUp('slow');
            var formGroup = $(this).parent().parent('.experience-item');
            $(formGroup).find('.box').slideDown('slow');
            
            
            $(this).addClass('hidden');
            $(this).parent('.action-icons').find('.exclude').removeClass('hidden');
            $(this).parent('.action-icons').find('.edit').removeClass('hidden');
        });        

        $('[data-page="cadastro-profissional-experiencia-profissional"],[data-page="profissional-meus-dados-experiencia-profissional"]').on('click',".exclude", function(e){
            e.preventDefault();
            var loading = new Loading();
            var item = $(this).parents('.experience-item');
            var url = $("#remover-experiencia").val() + "/" + $(this).data("id");
            $.ajax({
                url: url,
                type: "GET",
                success: function (result) {
                    if(result.Sucesso)
                    {
                        item.remove();
                    }
                    else
                        {
                            Alert("Atenção",result.Mensagem);
                            ModalShow();
                        }
                        loading.hide();
                    },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro")
                }
            });
        });

		$('[data-page="cadastro-profissional-experiencia-profissional"],[data-page="profissional-meus-dados-experiencia-profissional"]').on("click",".form-experiencias .btn-submit", function(e)
		{
			e.preventDefault();
            validationFormExperiencias($(this).parents('form'));
		});

        $('[data-page="cadastro-profissional-experiencia-profissional"],[data-page="profissional-meus-dados-experiencia-profissional"]').on("submit",".form-experiencias",function(e)
        {
            
            e.preventDefault();
            var form = $(this);
            var loading = new Loading();
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: form.serialize(),
                success: function (result) {
                    if (result != null) { 
                        if(result.Sucesso)
                        {
                            if(form.find(".experience-id").val() == 0)
                            {
                                form.trigger('reset');
                                dust.render('experiencia', result.Retorno, function(err, out) {
                                templateExperienciaDest.append(out);
                            });
                            }
                            else
                            {
                                var item = form.parents(".experience-item");
                                item.find(".empresa").text("Empresa: " + form.find("[name='Empresa']").val());
                                item.find(".cargo").text("Cargo: " + form.find("[name='Cargo']").val());
                                item.find(".periodo").text("Período: " + form.find("[name='AnoInicio']").val() + " - " + (form.find("[name='AnoFim']").val() ?  form.find("[name='AnoFim']").val() : 'Atual'));
                                item.find('.cancel').click();
                            }
                        }
                        else
                        {
                            Alert("Atenção",result.Mensagem);
                            ModalShow();
                        }
                    }

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro")
                }
            });
		});

        $(".formPersonalData").on("submit", function(e)
        {
            e.preventDefault();
            var form = $(this);
            var loading = new Loading();
            var formData = new FormData(form[0]);
            formData.append("ImagemFile", $("#imageFile")[0].files[0]);
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: formData,
                dataType: 'json',
                contentType: false,
                processData: false,
                success: function (result) {
                    if (result != null) { 
                        if(result.Sucesso)
                        {
                        	location.href = result.Mensagem;
                        }
                        else
                        {
                        	Alert("Atenção",result.Mensagem);
                            ModalShow();
                            loading.hide();
                        }
                    }
                    
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
					Alert("Atenção","Ocorreu um erro ao tentar processar a sua solicitação.");
					ModalShow();
					loading.hide();
		}
            });
        });
		
		$(".formPersonalDataSenha").on("submit", function(e)
        {
            e.preventDefault();
            var form = $(this);
            var loading = new Loading();
			var formData = new FormData(form[0]);
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: formData,
                dataType: 'json',
                contentType: false,
                processData: false,
                success: function (result) {
                    if (result != null) { 
                        if(result.Sucesso)
                        {
                        	Alert("Sucesso","Senha alterada com sucesso");
							$("#password").val("");
							$("#password-confirm").val("");
							ModalShow();
                            loading.hide();
                        }
                        else
                        {
                        	Alert("Atenção",result.Mensagem);
							$("#password").val("");
							$("#password-confirm").val("");
                            ModalShow();
                            loading.hide();
                        }
                    }
                    
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro")
                }
            });
        });

		
		$('.formPersonalDataSenha .form-button').on('click', function(e) {
	        e.preventDefault();
	        if(!$(this).hasClass('disabled'))
	        	validationFormPersonalDataSenha();
    	});
		
		$('.formPersonalData .form-button').on('click', function(e) {
	        e.preventDefault();
	        if(!$(this).hasClass('disabled'))
	        	validationFormPersonalData();
    	});

		$('.NewAddress .form-button').on('click', function(e) {
	        e.preventDefault();
	        validationFormAvailabilityOfCare();
    	});

		$('.EditAddress .form-button').on('click', function(e) {
	        e.preventDefault();
	        validationFormAvailabilityOfCare();
    	});    	

		$('.attachment').on('click', function(e){
			e.preventDefault();
			$('.includeCertificate').click();
		});  

		
		$('#cepProfissional').on('click', function(){
			$('.cep-content-juristic-register').addClass('active');
		});
		$('#cepProfissional').on('focus', function() {
			$('.cep-content-juristic-register').addClass('active');
		});	

		$(".ddlEstado").on("change", function () {
		    var inputEstado = $(this); 
		    var parent = $(inputEstado.parents(".estadoCidade"));
		    var city = parent.find(".ddlCidade");
		    if ($(this).val() !== '') {
		        EstadoSelecionado(city, inputEstado.val());
		        }
		        else {
		            $(city).html('<option value="" class="hidden"></option>');
		        }
	    });	 
	    $(".txtCep").on("blur", function () {
			cepChange($(this)); 
	    });	 
	 	     
	    $('.boxAddress').on('click',function(){
	    	$(this).find('.box').toggleClass('active');
	    	$(this).find('i').toggleClass('icon-location-active');
	    	$(this).find('i').toggleClass('icon-location');
	    	$(this).find('.icon').toggleClass('icon-unmark');
	    	$(this).find('.icon').toggleClass('icon-mark');
	    	$(this).next('.serviceAddress').slideToggle('slow');
	    }); 	


	    $('[data-page="cadastro-profissional-disponibilidade-atendimento"]').on('click','.edit', function(e){
	    	e.preventDefault();
	    	$(this).parent('.action-icons').children('.EditAddress').slideDown('slow');
	    	$(this).parent('.action-icons').next('.boxAddress').slideUp('slow');
				$(this).addClass('hidden');
	    	$(this).next('.exclude').addClass('hidden');
	    	$(this).parent('.action-icons').find('.cancel').removeClass('hidden');
	    });
        $('[data-page="profissional-meus-dados-pessoais"]').on('click','.edit', function(e){
            e.preventDefault();
            $('.formPersonalData').find('input, select').attr('disabled', false);
            $('.formPersonalData .form-button').removeClass('disabled');
            $('.btn-crop').removeClass('disabled');
            $('.form-button').attr('disabled', false);
            $('[name="CPF"]').attr('disabled', 'disabled');
        });        
	    $('.cancel').on('click',function(e){
	    	e.preventDefault();
	    	$(this).parent('.action-icons').children('.EditAddress').slideUp('slow');
	    	$(this).parent('.action-icons').next('.boxAddress').slideDown('slow');
			$(this).addClass('hidden');
	    	$(this).parent('.action-icons').find('.exclude').removeClass('hidden');
	    	$(this).parent('.action-icons').find('.edit').removeClass('hidden');
	    });	    
	}
	function EstadoSelecionado(inputCidades, estadoID, callBackFunction, loading) {
	  if (!loading)
			loading = new Loading();
		if (estadoID != 0) {
			$.ajax({
			type: "GET",
			url: $("#urlListarCidades").val(),
			data: { id: estadoID },
				success: function (data) {
					inputCidades.html('<option value="" class="hidden"></option>');
					$.each(data, function (index, value) {
					inputCidades.append('<option value="' + value.ID + '" data-nome="' + value.Nome + '">' + value.Nome + '</option>');
					});

					if (callBackFunction)
					callBackFunction(inputCidades);
					else
					loading.hide();
				}
			});
		} else {
		  loading.hide();
		}
  }
  	function cepChange(inputCep)
    {
    	var loading = new Loading();
    	var form = $(inputCep.parents('form'));
        $.ajax({
            type: "GET",
            url: "https://webmaniabr.com/api/1/cep/" + inputCep.val().replace("-", "") + "/?app_key=" + 'PYlhy37jk1bhwY3WnKq0GZray0xA5MGb' + "&app_secret=" + 'VWjiPK0jzOYROBNZLmfqtmT3PKPcZ7cGrLGRMQG6vA5wMJX1',
            data: { cep: inputCep.val().replace("-", ""), formato: "json" },
            success: function (data) {
                
                    form.find(".txtBairro").val(data.bairro);
                    form.find(".txtLogradouro").val(data.endereco);               
                    var inputEstado = form.find(".ddlEstado"); 
                    inputEstado.val(inputEstado.find("[data-uf='" + data.uf + "']").val());
                    var inputCidade = form.find(".ddlCidade"); 
                    EstadoSelecionado(inputCidade, inputEstado.val(), function (inputCidade) { inputCidade.val(inputCidade.find("[data-nome='" + data.cidade + "']").val()); loading.hide(); }, loading);
                }
        });
    }


    function MascaraTelefone(inputs, length)
    {
		inputs.each(function(){
			var input = $(this);
			var mask = "(00) 00000-0000";
			if(input.val().length < length ){
				mask = "(00) 0000-0000Z";
						}
			if((input.attr("mascara") == undefined) || (input.attr("mascara") != mask)){
				input.attr("mascara", mask);
				input.unmask().mask(mask, {
					translation: {
						'Z': {
							pattern: /[0-9]/, optional: true
						}
					}
				});
				setTimeout(function(){ input[0].selectionStart = input[0].selectionEnd = 10000; }, 0);
			}    		
    	});
    };
	function ProfissionalMasks()
	{
	    //$.jMaskGlobals = { watchInputs: false };
	    $('[name="Agencia"]').mask('0000');
	    $('[name="NumeroConta"]').mask('0000000000000');

		$('[name="DadosAcademicosDataInicio"]').mask('00/00/0000');
		$('[name="CPF"]').mask('000.000.000-00', {reverse: true});
		$('[name="DataNascimento"]').mask("00/00/0000");
		$('[name="AnoInicio"], [name="AnoFim"]').mask("0000");
		$('[name="CEP"]').mask('00000-000');
		MascaraTelefone($('[name="Telefone1"], [name="Telefone2"]'), 11);
		$('[name="Telefone1"], [name="Telefone2"]').keyup(function(){   		
    		MascaraTelefone($(this), 15);
    	});    	
	}
	
    function validationFormExperiencias(form)
    {
		var error = false;
        var empresa = form.find('[name="Empresa"]');
        var cargo = form.find('[name="Cargo"]');
        var anoInicio = form.find('[name="AnoInicio"]');
        var anoIntIn = parseInt(form.find('[name="AnoInicio"]').val());
        
    if (validation.isNull(anoInicio.val())) {
        error = true;
        anoInicio.addClass('error');
    } else {
        anoInicio.removeClass('error');
    }

    if (validation.isNull(cargo.val())) {
        error = true;
        cargo.addClass('error');
    } else {
        cargo.removeClass('error');
    }

    if (validation.isNull(empresa.val())) {
        error = true;
        empresa.addClass('error');
    } else {
        empresa.removeClass('error');
    }

		if (!error) {
    		form.submit();
    	}     	
    }

	function validationFormPersonalData() {
    	var error = false;
    	var form = $('.formPersonalData');
    	var imagem = form.find('[name="image"]');
    	var name = form.find('[name="Nome"]');
    	var email = form.find('[name="Email_"]');
    	var sexo = form.find('[name="Sexo"]');
    	var numeorRg = form.find('[name="RG"]');
    	var estadoC = form.find('[name="EstadoCivil"]');
    	var dataNascimento = form.find('[name="DataNascimento"]');
    	var personalCpf = form.find('[name="CPF"]');
    	var phoneOne = form.find('[name="Telefone1"]');
    	var banco = form.find('[name="BancoID"]');
    	var agencia = form.find('[name="Agencia"]');
    	var AccountType = form.find('[name="TipoConta"]');
    	var accountNumber = form.find('[name="NumeroConta"]');
    	var accountDigit = form.find('[name="DigitoConta"]');

        var LogradouroCadastro = form.find('[name="Logradouro"]');
        var NumeroCadastro = form.find('[name="Numero"]');
        var estadoCadastro = form.find('[name="Estado"]');
        var cidadeCadastro = form.find('[name="Cidade"]');
        var BairroCadastro = form.find('[name="Bairro"]');

        var cep = form.find('[name="CEP"]');

    	if (validation.isNull(personalCpf.val()) || !validation.verifyCPF(personalCpf.val())) {
    		error = true;
    		personalCpf.addClass('error');
    	} else {
    		personalCpf.removeClass('error');
    	}

    	if (validation.isNull(agencia.val())) {
    		error = true;
    		agencia.addClass('error');
    	} else {
    		agencia.removeClass('error');
    	}

    	if (validation.isNull(numeorRg.val())) {
    	    error = true;
    	    numeorRg.addClass('error');
    	} else {
    	    numeorRg.removeClass('error');
    	}

    	if (validation.isNull(estadoC.val())) {
    	    error = true;
    	    estadoC.addClass('error');
    	} else {
    	    estadoC.removeClass('error');
    	}

		if (validation.isNull(phoneOne.val()) || phoneOne.val().length < 14) {
    		error = true;
    		phoneOne.addClass('error');
    	} else {
    		phoneOne.removeClass('error');
    	}
    	if (banco.val == "0") {
    		error = true;
    		banco.addClass('error');
    	} else {
    		banco.removeClass('error');
    	} 

    	if (validation.isNull(dataNascimento.val())) {
    		error = true;
    		dataNascimento.addClass('error');
    	} else {
    		dataNascimento.removeClass('error');
    	}      	

    	if (validation.isNull(sexo.val())) {
    		error = true;
    		sexo.addClass('error');
    	} else {
    		sexo.removeClass('error');
    	}    	

    	if (validation.isNull(imagem.val())) {
    		error = true;
    		$('.user-image').addClass('error');
    	} else {
    		$('.user-image').removeClass('error');
    	}

    	if (validation.isNull(name.val())) {
    		error = true;
    		name.addClass('error');
    	} else {
    		name.removeClass('error');
    	}    	
    	
    	if (!validation.IsEmail(email.val()) || validation.isNull(email.val())) {
    		error = true;
    		email.addClass('error');
    	} else {
    		email.removeClass('error');
    	}
  	    	
    	if (validation.isNull(AccountType.val())) {
    		error = true;
    		AccountType.addClass('error');
    	} else {
    		AccountType.removeClass('error');
    	}
    	if (validation.isNull(accountNumber.val())) {
    		error = true;
    		accountNumber.addClass('error');
    	} else {
    		accountNumber.removeClass('error');
    	}

    	if (validation.isNull(accountDigit.val())) {
    		error = true;
    		accountDigit.addClass('error');
    	} else {
    		accountDigit.removeClass('error');
    	}    	

        if (validation.isNull(LogradouroCadastro.val())) {
            error = true;
            LogradouroCadastro.addClass('error');
        } else {
            LogradouroCadastro.removeClass('error');
        }
        if (validation.isNull(NumeroCadastro.val())) {
            error = true;
            NumeroCadastro.addClass('error');
        } else {
            NumeroCadastro.removeClass('error');
        }
        if (validation.isNull(estadoCadastro.val())) {
            error = true;
            estadoCadastro.addClass('error');
        } else {
            estadoCadastro.removeClass('error');
        }
    
        if (validation.isNull(cidadeCadastro.val())) {
            error = true;
            cidadeCadastro.addClass('error');
        } else {
            cidadeCadastro.removeClass('error');
        }
        if (validation.isNull(BairroCadastro.val())) {
            error = true;
            BairroCadastro.addClass('error');
        } else {
            BairroCadastro.removeClass('error');
        }

        if (validation.isNull(cep.val())) {
            error = true;
            cep.addClass('error');
        } else {
            cep.removeClass('error');
        }

    	if (!error) {
    		form.submit();
    	}
    	else {

    	    var formData = new FormData(form[0]);
    	    formData.append("ImagemFile", $("#imageFile")[0].files[0]);
    	    $.ajax({
    	        url: form.attr("action"),
    	        type: form.attr("method"),
    	        data: formData,
    	        dataType: 'json',
    	        contentType: false,
    	        processData: false,
    	        success: function (result) {},
    	        error: function (XMLHttpRequest, textStatus, errorThrown) {
    	            console.log("Erro")
    	        }
            });
    	    //form.submit();
    	    var elemTop = $('form').find('.error').offset().top;
    	    TweenLite.to('html,body', 1, { scrollTop: (elemTop - 40), ease: Power1.easeOut });
    	}
    }
	
	function validationFormPersonalDataSenha() {
    	var error = false;
    	var form = $('.formPersonalDataSenha');
    	var senha = form.find('[name="novaSenha"]');
        var confirmaSenha = form.find('[name="senha-confirme"]');

        if (senha.val().length < 4 || senha.val().length > 18 || validation.isNull(senha.val()) || senha.val() != confirmaSenha.val()) {
            error = true;
            senha.addClass('error');
            confirmaSenha.addClass('error');
        } else {
            senha.removeClass('error');
            confirmaSenha.removeClass('error');
        }
		
		if (!error) {
    		form.submit();
    	}
    }
	
	
	
	function validationFormAvailabilityOfCare() {

    	var error = false;
		var form = $('form');
		
    	var LogradouroCadastro = form.find('[name="LogradouroCadastro"]');
    	var NumeroCadastro = form.find('[name="NumeroCadastro"]');
    	var estadoCadastro = form.find('[name="estadoCadastro"]');
    	var cidadeCadastro = form.find('[name="cidadeCadastro"]');
    	var BairroCadastro = form.find('[name="BairroCadastro"]');

    	if (validation.isNull(LogradouroCadastro.val())) {
    		error = true;
    		LogradouroCadastro.addClass('error');
    	} else {
    		LogradouroCadastro.removeClass('error');
    	}
    	if (validation.isNull(NumeroCadastro.val())) {
    		error = true;
    		NumeroCadastro.addClass('error');
    	} else {
    		NumeroCadastro.removeClass('error');
    	}
    	if (validation.isNull(estadoCadastro.val())) {
    		error = true;
    		estadoCadastro.addClass('error');
    	} else {
    		estadoCadastro.removeClass('error');
    	}
  	
    	if (validation.isNull(cidadeCadastro.val())) {
    		error = true;
    		cidadeCadastro.addClass('error');
    	} else {
    		cidadeCadastro.removeClass('error');
    	}
    	if (validation.isNull(BairroCadastro.val())) {
    		error = true;
    		BairroCadastro.addClass('error');
    	} else {
    		BairroCadastro.removeClass('error');
    	}
    	
    	if (!error) {
    		form.submit();
    	}
    	else {
    	    var elemTop = $('form').find('.error').offset().top;
    	    TweenLite.to('html,body', 1, { scrollTop: (elemTop - 40), ease: Power1.easeOut });
    	}
    }

	function SpecialityActivate()
	{
        

    $('.button-specialty').each(function(key,value){

        if($(this).hasClass('active'))
        {
            var dataId = $(this).data('id');
            $('#'+dataId).css('display','block');
        } 
    })
};
return init();

};

function testeDevolucao(devolucao) {
    if (devolucao != null) {
        Alert("Atenção", devolucao);
        ModalShow();
    }
}

// box button-availability-monday -little turn-morning active
