var Availability = function() {

	function init() {

		$('[data-component="disponibilidade-atendimento"]').on('click', '.availability .button-availability', function(e) {
			e.preventDefault();

			$(this).toggleClass('active');
			$(this).find('.icon').toggleClass('icon-mark');
			$(this).find('.icon').toggleClass('icon-unmark');
			// $('.button-availability').find('.icon-specialty-active').toggleClass('icon-specialty').removeClass('icon-location-active');
			// $('.button-availability').find('.icon-specialty-active').toggleClass('icon-location-active');
			// $(this).find('.icon-specialty').addClass('icon-specialty-active').removeClass('icon-specialty');

			// $('.button-availability').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			// $('.button-availability').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			// $(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');

		});

		$('#availability-tuesday').on('click', '.button-availability-tuesday', function(e) {
			e.preventDefault();

			$('.button-availability-tuesday').removeClass('active');
			$(this).addClass('active');

			$('.button-availability-tuesday').find('.icon-specialty-active').addClass('icon-specialty').removeClass('icon-location-active');
			$(this).find('.icon-specialty').addClass('icon-specialty-active').removeClass('icon-specialty');

			$('.button-availability-tuesday').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');

		});	
		$('#availability-wednesday').on('click', '.button-availability-wednesday', function(e) {
			e.preventDefault();

			$('.button-availability-wednesday').removeClass('active');
			$(this).addClass('active');

			$('.button-availability-wednesday').find('.icon-specialty-active').addClass('icon-specialty').removeClass('icon-location-active');
			$(this).find('.icon-specialty').addClass('icon-specialty-active').removeClass('icon-specialty');

			$('.button-availability-wednesday').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');

		});		
		$('#availability-thursday').on('click', '.button-availability-thursday', function(e) {
			e.preventDefault();

			$('.button-availability-thursday').removeClass('active');
			$(this).addClass('active');

			$('.button-availability-thursday').find('.icon-specialty-active').addClass('icon-specialty').removeClass('icon-location-active');
			$(this).find('.icon-specialty').addClass('icon-specialty-active').removeClass('icon-specialty');

			$('.button-availability-thursday').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');

		});		
		$('#availability-friday').on('click', '.button-availability-friday', function(e) {
			e.preventDefault();

			$('.button-availability-friday').removeClass('active');
			$(this).addClass('active');

			$('.button-availability-friday').find('.icon-specialty-active').addClass('icon-specialty').removeClass('icon-location-active');
			$(this).find('.icon-specialty').addClass('icon-specialty-active').removeClass('icon-specialty');

			$('.button-availability-friday').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');

		});			
		$('#availability-saturday').on('click', '.button-availability-saturday', function(e) {
			e.preventDefault();

			$('.button-availability-saturday').removeClass('active');
			$(this).addClass('active');

			$('.button-availability-saturday').find('.icon-specialty-active').addClass('icon-specialty').removeClass('icon-location-active');
			$(this).find('.icon-specialty').addClass('icon-specialty-active').removeClass('icon-specialty');

			$('.button-availability-saturday').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');

		});	
		$('#availability-friday').on('click', '.button-availability-friday', function(e) {
			e.preventDefault();

			$('.button-availability-friday').removeClass('active');
			$(this).addClass('active');

			$('.button-availability-friday').find('.icon-specialty-active').addClass('icon-specialty').removeClass('icon-location-active');
			$(this).find('.icon-specialty').addClass('icon-specialty-active').removeClass('icon-specialty');

			$('.button-availability-friday').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');

		});	
		$('#availability-sunday').on('click', '.button-availability-sunday', function(e) {
			e.preventDefault();

			$('.button-availability-sunday').removeClass('active');
			$(this).addClass('active');

			$('.button-availability-sunday').find('.icon-specialty-active').addClass('icon-specialty').removeClass('icon-location-active');
			$(this).find('.icon-specialty').addClass('icon-specialty-active').removeClass('icon-specialty');

			$('.button-availability-sunday').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');

		});		
	}

	return init();
}