var Loading = function() {
	var self = this;
	self.loadingSrc = $("#img-load").attr('src');
	self.htmlElement = '<div class="loading" style="height: 100vh; position:fixed;"><img src="' + self.loadingSrc + '"></div>';

	function init() {
		$('body').append(self.htmlElement);
	}

	this.show = function() {
		$('body, html').css('overflow-y','hidden');
		$('.loading').fadeIn('600');
	}

	this.hide = function() {
		$('body, html').css('overflow-y','auto');
		$('.loading').fadeOut('600');
	}

	return init();
};