var LoginProfissional = function() {

	var validation = new Validation();
	
	function init() {
		addListeners();
	}

	function addListeners()
	{
		$('#cpf').mask('000.000.000-00', {reverse: true});

		$('.to-access a').on("click",function (e) {
			e.preventDefault();
			validationForm();		
			
		});

		$('.esqueci-senha').on("click",function (e) {
			e.preventDefault();
			
			var cpf = $('#cpf');

			if (validation.isNull(cpf.val()) || !validation.verifyCPF(cpf.val())) {
				cpf.addClass('error');
    		} else {
    			cpf.removeClass('error');
    			var loading = new Loading();
    			$.ajax({
					url: $(this).attr('href') + "?cpf=" + cpf.val(),
					type: "GET",
					success: function (result) {
						Alert("Atenção!", result.Mensagem);
						ModalShow();
						loading.hide();
					},
					error: function (XMLHttpRequest, textStatus, errorThrown) {
				    	console.log("Erro")
					}
				});
    		}	
    	});

		$('form').on("submit",function (e) {
			
			e.preventDefault();
            var form = $(this);
            var loading = new Loading();
            $.ajax({
				url: form.attr("action"),
				type: form.attr("method"),
				data: form.serialize(),
				success: function (result) {
				    if (result != null) { 
				        if(result.Sucesso)
				        {
                            location.href = result.Mensagem;
				        }
				        else
				        {
				        	Alert("Atenção", result.Mensagem)
				            ModalShow();
				        }
				    }

				    loading.hide();
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
				    console.log("Erro")
				}
			});
		});	
	}

	
	function validationForm() {

    	var error = false;
		var form = $('form');

    	var cpf = form.find('#cpf');
    	var senha = form.find('[name="senha"]');

    	if (validation.isNull(cpf.val()) || !validation.verifyCPF(cpf.val())) {
    		error = true;
    		cpf.addClass('error');
    	} else {
    		cpf.removeClass('error');
    	}
    		
    	if (validation.isNull(senha.val())) {
    		error = true;
    		senha.addClass('error');
    	} else {
    		senha.removeClass('error');
    	}
    	if (!error) {
    		form.submit();
    	} 
    }

    return init();

};