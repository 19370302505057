var IndicateProfessional = function(){

	var validation = new Validation();
	
	function init() {
		insertMask();
		addListeners();
	}

	function addListeners() {
		$('.form-submit').on('click', function(e) {
			e.preventDefault();
			validationForm();
		});

		$('form').on('submit',function(e){
			e.preventDefault();
			var form = $('form');
			var loading = new Loading();
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: form.serialize(),
                success: function (result) {
                	if(result.Sucesso)
                	{
                        ModalShow();
                        Alert('Sucesso!','Email enviado com sucesso');
                		form[0].reset();
                	}
                	else
                	{
                        ModalShow();
                        Alert('Erro!',result.Mensagem);
                    }

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro");
                    loading.hide();
                }
            });
		})

	}

	function insertMask() {
        $('[name="Telefone"]').keyup(function(){           
            MascaraTelefone($(this), 15);
        });
	}

    function MascaraTelefone(inputs, length)
    {
        inputs.each(function(){
            var input = $(this);
            var mask = "(00) 00000-0000";
            if(input.val().length < length ){
                mask = "(00) 0000-0000Z";
                        }
            if((input.attr("mascara") == undefined) || (input.attr("mascara") != mask)){
                input.attr("mascara", mask);
                input.unmask().mask(mask, {
                    translation: {
                        'Z': {
                            pattern: /[0-9]/, optional: true
                        }
                    }
                });
                setTimeout(function(){ input[0].selectionStart = input[0].selectionEnd = 10000; }, 0);
            }           
        });
    };

    function validationForm() {
    	var error = false;
    	var form = $('.form-1');

    	var nome = form.find('[name="Nome"]');
    	var telefone = form.find('[name="Telefone"]');
		var email = form.find('[name="Email_"]');
        var especialidade = form.find('[name="Servico"]');

    	if (validation.isNull(nome.val())) {
    		error = true;
    		nome.addClass('error');
    	} else {
			nome.removeClass('error');
    	}
        if (validation.isNull(especialidade.val())) {
            error = true;
            especialidade.addClass('error');
        } else {
            especialidade.removeClass('error');
        }
    	if (!validation.IsEmail(email.val()) || validation.isNull(email.val())) {
    		error = true;
    		email.addClass('error');
    	} else {
			email.removeClass('error');
    	}

		if (validation.isNull(telefone.val()) || telefone.val().length < 14) {
    		error = true;
    		telefone.addClass('error');
    	} else {
    		telefone.removeClass('error');
    	}

    	if (!error) {
    		form.submit();
    	}
    }

	return init();

};
