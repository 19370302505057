var PreRegister = function() {
	var validation = new Validation();
	function init() {
		addListeners();
		insertMask();
	}

	function addListeners() {

		$( ".popUpPass" )
		.mouseover(function (e) {
		    e.preventDefault();
		    if ($(window).width() < 992) {
		        $("body").append("<div class='reveal-modal-bg'></div>");
		        $('.reveal-modal-bg').addClass('hidden');
		        $('#tip-password').addClass('hidden');
		        $('.popPass').css({ 'left': '5%' });
		    }
		    $('.popPass').addClass('show');

		})
		.mouseout(function() {
			$('.popPass').removeClass('show');
		});


		$( ".popUpCpf" )
		.mouseover(function (e) {
		    e.preventDefault();
		    if ($(window).width() < 992) {
		        $("body").append("<div class='reveal-modal-bg'></div>");
		        $('.reveal-modal-bg').addClass('hidden');
		        $('#tip-password').addClass('hidden');
		        $('.popCpf').css({ 'left': '0%', 'z-index': '10' });
		    }
			$('.popCpf').addClass('show');
		})
		.mouseout(function() {
			$('.popCpf').removeClass('show');
		});


		$('form').on("submit",function(e) {
            e.preventDefault();
            var form = $('form');

            var loading = new Loading();
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: form.serialize(),
                success: function (result) {
                	if(result.Sucesso)
                	{
                		location.href = result.Mensagem;	
                	}
                	else
                	{
                		Alert("Atenção",result.Mensagem);
                		ModalShow();
                		loading.hide();
                    }

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro");
                    loading.hide();
                }
            });

        });

		$('.to-access').on("click",function(e)
		{
			e.preventDefault();
			validationForm();
		});

		function validationForm() {
    		var error = false;
    		var form = $('form');

    		var nome = form.find('[name="Nome"]');
    		var email = form.find('[name="Email_"]');
    		var cpf = form.find('[name="CPF"]');
    		var senha = form.find('[name="Senha"]');

    		var senhaMin = 4;
    		var senhaMax = 18;

    		if (validation.isNull(nome.val())) {
    			error = true;
    			nome.addClass('error');
    		} else {
    			nome.removeClass('error');
    		}

    		if (validation.isNull(cpf.val()) || !validation.verifyCPF(cpf.val())) {
    			error = true;
    			cpf.addClass('error');
    		} else {
    			cpf.removeClass('error');
    		}

    		if (senha.val().length < senhaMin || senha.val().length > senhaMax || validation.isNull(senha.val())) {
    			error = true;
    			senha.addClass('error');
    		} else {
    			senha.removeClass('error');
    		}

    		if (!validation.IsEmail(email.val()) || validation.isNull(email.val())) {
    			error = true;
    			email.addClass('error');
    		} else {
    			email.removeClass('error');
    		}

    		if (!error) {
    			form.submit();
    		}	 
    	}
    }

    
    function insertMask() { 
    	$('[name="CPF"]').mask('000.000.000-00', {reverse: true});
    }

	return init();

};
