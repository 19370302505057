var HeaderProfissional = function () {



  function init() {

	addListeners();
  }

  function addListeners() {

	var mobileWidth = $(document).width();



	if (mobileWidth < 770) {

	  $(".txtCep").focusin(function () {
		if ($(".txtCep").is(":focus")) {
		  $('.tip-phone').fadeIn();
		}
	  });

	  $(".txtCep").focusout(function () {
		$('.tip-phone').fadeOut();
	  });

	  $(".bankfocus").focusin(function () {
		if ($(".onlyNumber").is(":focus")) {
		  $('.tip-agency').fadeIn();
		}
	  });

	  $(".bankfocus").focusout(function () {
		$('.tip-agency').fadeOut();
	  });

	}
	var mobileWidth = $(document).width();

	if (mobileWidth < 1025) {

	  $('.hover-notifications').on('click', function () {

		$(this).next('.notifications-body').toggleClass('show-notifications');

	  })

	} else {

	  $('.hover-notifications')
		.mouseover(function () {
		  $(this).next('.notifications-body').addClass('show-notifications');
		})
		.mouseout(function () {
		  $(this).next('.notifications-body').removeClass('show-notifications');
		});

	  $( '.notifications-body' )
	  .mouseover(function() {
			$(this).addClass('show-notifications');
	  })
	  .mouseout(function() {
			$(this).removeClass('show-notifications');
	  });
	}




	setInterval(function () {
	  $.ajax({
		url: $('#urlNotificacoes').val(),
		type: "GET",
		success: function (result) {
		  if (result.Sucesso) {
			if (result.Retorno.length > 0) {
			  $(".notifications-parent .number").removeClass('hidden');
			  $(".notifications-parent .number").text(result.Retorno.length);
			  $('.notifications-list').html('');
			  $.each(result.Retorno, function (index, value) {
				$('.notifications-list').append('<li class="clearfix">' +
				  '<i class="icon-alert "></i>' +
				  '<div class="notification-inner-content">' +
				  '<p>' + value.TextoNotificacao + '</p>' +
				  '<a href="' + value.Link + '" class="btn btn-notification">Confira</a>' +
				  '</div>' +
				  '<div class="line-bottom"></div>' +
				  '</li>');
			  });
			}
		  }
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) {
		  console.log("Erro");
		}
	  });
	}, 60000);

  }

  return init();

};
