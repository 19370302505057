var Register = function() {

	var validation = new Validation();

    var templateCompile;
    var templateSource = $(".template-addres").html();
    var templateDestiny = $("#locais-paciente");
    
	function init() {

		new Validation();
		addListeners();
		new RegisterAttendance(CallBackSubmit,validation,CallBackVoltar);
		insertMask();
		submits();
        compileAddresTemplate();

		$('#cep-charges').mask('00000-000');        

	}

	function CallBackSubmit(result){
		$("#register-pass-3").removeClass("active");
		$(".register-nav-list").find("[data-pass='3']").removeClass("active");
		$("#register-pass-4").addClass("active");
		$(".register-nav-list").find("[data-pass='4']").addClass("active");
		$("#atendimento-id").val(result.Retorno);
		$(".pass-4-form-1 #atendimento-id").val(result.Retorno);
	}

	function CallBackVoltar(result){
		$("#register-pass-2").addClass("active");
		$(".register-nav-list").find("[data-pass='2']").addClass("active");
		$("#register-pass-3").removeClass("active");
		$(".register-nav-list").find("[data-pass='3']").removeClass("active");
	}
    
    function compileAddresTemplate() {
		templateCompile = dust.compile(templateSource, 'addresList');

		dust.loadSource(templateCompile);
	};
   
	function addListeners() {

		$(".perfil-content .checkbox").on('click',function(e){
			var inputChek = $(this).find('[type="checkbox"]');
            var iconCheck = $(this).find('.fa-check');
			if(inputChek.is(":checked"))
			{
				inputChek.removeProp("checked");	
                iconCheck.css('display','none');
			}
			else
			{
				inputChek.prop("checked","checked");
                iconCheck.css('display','block');
			}
		});

	    $('.form-cancel-submit-jur').on('click',function(e){
	        e.preventDefault();
	        $(this).parents('.cepCobrancaJuridica').removeClass('active');
	        $('#perfil-juristic .new-cep-charges').removeClass('hidden');
	        $('#cep-charges2').val('');
	        $('#addresscharges').val('');
	        $('#addressNumberCharges').val('');
	        $('#cobrancaCharges').val('');
	        $('#state-juristic-charges').val('');
	        $('#state-juristic-charges').change();
	        $('#bairro-juristic').val('');        
	    });

	    $('.form-cancel-submit').on('click',function(e){
	        e.preventDefault();

	        $('.cepCobrancaFisica').removeClass('active');
	        $('#perfil-physical .new-cep-charges').removeClass('hidden');

	        $('#cep-charges').val('');
	        $('#address-charges').val('');
	        $('#address-number-charges').val('');
	        $('#cobranca-charges').val('');
	        $('#state-physical-charges').val('');
	        $('#state-physical-charges').change();
	        $('#complement-charges').val('');
	    }); 

		$('#card-number').keydown(function (e) {
	        // Allow: backspace, delete, tab, escape, enter and .
	        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
	             // Allow: Ctrl/cmd+A
	            (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
	             // Allow: Ctrl/cmd+C
	            (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
	             // Allow: Ctrl/cmd+X
	            (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
	             // Allow: home, end, left, right
	            (e.keyCode >= 35 && e.keyCode <= 39)) {
	                 // let it happen, don't do anything
	                 return;
	        }
	        // Ensure that it is a number and stop the keypress
	        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
	            e.preventDefault();
	        }
       	});
        
        $('.register-nav-list a').on('click', function(e) {
			e.preventDefault();

			var pass = $(this).data('pass');
			pass = 'register-pass-' + pass;

			$('.register-content').removeClass('active');
			$('#' + pass).addClass('active');
		});

		$('.button-perfil').on('click', function(e) {
			e.preventDefault();

			var row = $(this).closest('.row');
			var content = $(this).closest('.register-content');

			row.find('.button-perfil').removeClass('active');
			$(this).addClass('active');

			row.find('.button-perfil').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');

			if ($(this).hasClass('perfil-physical')) {

				$(this).find('.icon-person1').addClass('icon-person2').removeClass('icon-person1');
				$('.perfil-juristic').find('.icon-person4').addClass('icon-person3').removeClass('icon-person4');
			
			} else if ($(this).hasClass('perfil-juristic')) {

				$(this).find('.icon-person3').addClass('icon-person4').removeClass('icon-person3');
				$('.perfil-physical').find('.icon-person2').addClass('icon-person1').removeClass('icon-person2');

			} else if ($(this).hasClass('perfil-patient')) {

				$(this).find('.icon-person1').addClass('icon-person2').removeClass('icon-person1');
				$('.perfil-register-patient').find('.icon-person5').addClass('icon-person6').removeClass('icon-person5');

			} else if ($(this).hasClass('perfil-register-patient')) {

				$(this).find('.icon-person6').addClass('icon-person5').removeClass('icon-person6');
				$('.perfil-patient').find('.icon-person2').addClass('icon-person1').removeClass('icon-person2');

			} 

			var perfil = $(this).data('perfil');
			perfil = "perfil-" + perfil;

			content.find('.perfil-content').removeClass('active');
			$('#' + perfil).addClass('active');

			$('.btn-back-out-form').addClass('hidden');

		});

		$('#perfil-register-patient').on('click', '.button-location', function(e) {
			e.preventDefault();

			$('.button-location').removeClass('active');
			$(this).addClass('active');

			$('.button-location').find('.icon-location-active').addClass('icon-location').removeClass('icon-location-active');
			$(this).find('.icon-location').addClass('icon-location-active').removeClass('icon-location');

			$('.button-location').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');
            
            if($(this).find(".endereco-paciente-id").val() == "0")
                $("#endereco-cliente").val("True");
            else
                $("#endereco-cliente").val("False");
            
            $("#endereco-paciente-check").val($(this).find(".endereco-paciente-id").val());

            $('.cep-content-patient').removeClass('active');
            $('.cepPaciente .new-cep-charges').removeClass('hidden');

		});

		$('.button-pay').on('click', function() {

			var content = $(this).data('pay');

			$('.pay-content').removeClass('active');
			$('#pay-' + content).addClass('active');

			$('.button-pay').removeClass('active');
			$(this).addClass('active');

			$('.button-pay').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon').addClass('icon-mark').removeClass('icon-unmark');
            
            $("#forma-pagamento").val($(this).attr("data-formaPagamento"));
            
		});
        
				
			function  perfilPhysicalCepRegister() {
				$('#perfil-physical .cep-content-physical-register').addClass('active');
			}

			$('#perfil-physical .cep-register').on('focus', function() {
				perfilPhysicalCepRegister()
			});

			function perfilPhysicalNewCepCharges(e) {
				e.preventDefault();
				$('#perfil-physical .cep-content-physical-charges').addClass('active');
				$('#perfil-physical .new-cep-charges').addClass('hidden');
				$("#perfil-physical .form-cancel-submit").removeClass('hidden');
			}

			$('#perfil-physical .new-cep-charges').on('click', function(e) {
				perfilPhysicalNewCepCharges(e);
			});

		

			
		

		
			function perfilJuristicCepRegister() {
				$('#perfil-juristic .cep-content-juristic-register').addClass('active');
			}
			
			$('#perfil-juristic .cep-register').on('focus', function() {
				perfilJuristicCepRegister();			
			});



			function perfilJuristicNewCepCharges(e) {
				e.preventDefault();
				$('#perfil-juristic .cep-content-juristic-charges').addClass('active');
				$('#perfil-juristic .new-cep-charges').addClass('hidden');
			}

			$('#perfil-juristic .new-cep-charges').on('click', function(e) {
				perfilJuristicNewCepCharges(e);
			});
		

		function perfilRegisterPatientNewCepCharges(e) {
			e.preventDefault();
			$('#perfil-register-patient .cep-content-patient').addClass('active');
			$('#perfil-register-patient .new-cep-charges').addClass('hidden');
			$("#endereco-paciente-check").val(0);
			$('.button-location').removeClass('active');
			$('.button-location').find('.icon-location-active').addClass('icon-location').removeClass('icon-location-active');
			$('.button-location').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
				
		}

		$('#perfil-register-patient .new-cep-charges').on('click', function(e) {
			perfilRegisterPatientNewCepCharges(e)
			
		});

		$("#carregar-endereco-paciente").on('click', function (e) {
		    e.preventDefault();
            var form = $('.pass-1-form-2');
            var form2 = $('.pass-2-form-2');
            var form3 = $('.pass-2-form-2 :input');

            perfilPhysicalCepRegister();
            form2.find('[name="CEPCadastro"]').val(form.find('[name="CEP"]').val());
            form2.find('[name="LogradouroCadastro"]').val(form.find('[name="Logradouro"]').val());
            form2.find('[name="NumeroCadastro"]').val(form.find('[name="Numero"]').val());
            form2.find('[name="ComplementoCadastro"]').val(form.find('[name="Complemento"]').val());
            form2.find('[name="BairroCadastro"]').val(form.find('[name="Bairro"]').val());
            form2.find('[name="estadoCadastro"]').val(form.find('[name="Estado"]').val());
		 
            var inputCidade = form2.find('[name="cidadeCadastro"]');
            var cidade = form.find('[name="Cidade"] option:selected').text();

			var loading = new Loading();
            EstadoSelecionado(inputCidade, form2.find('[name="estadoCadastro"]').val(), function (inputCidade) { inputCidade.val(inputCidade.find("[data-nome='" + cidade + "']").val()); loading.hide(); }, loading);

        });

		$('#relationship').on('change', function() {
			var value = $(this).val();

			var form = $('.pass-1-form-2');
			var form2 = $('.pass-2-form-2');
			var form3 = $('.pass-2-form-1');

			$(".form-back-geral").addClass("hidden");

	    	clearError(form);
	    	clearError(form2);
	    	clearError(form3);

	    	$(".relacionamentoCliente").val(value);
            $("#carregar-endereco-paciente").removeClass("hidden");

            form2.find('[name="nome"]').removeAttr('disabled');
            form2.find('[name="dataNascimento"]').removeAttr('disabled');
            form2.find('[name="sexo"]').removeAttr('disabled');
            form2.find('[name="telefone1"]').removeAttr('disabled');

	    	if(value == "J")
	    	{
	    		$('#perfil-physical').removeClass('active');
				$('#perfil-juristic').addClass('active');
	    	}
	    	else
            {
                
	    		$('#perfil-juristic').removeClass('active');
				$('#perfil-physical').addClass('active');

				if(value == "R")
                {
					form2.find('[name="nome"]').val(form.find('[name="nome"]').val());
			    	form2.find('[name="dataNascimento"]').val(form.find('[name="dataNascimento"]').val());
					form2.find('[name="sexo"]').val(form.find('[name="sexo"]').val());
                    form2.find('[name="telefone1"]').val(form.find('[name="telefone1"]').val());
                    form2.find('[name="nome"]').attr('disabled','disabled');
			    	form2.find('[name="dataNascimento"]').attr('disabled','disabled');
					form2.find('[name="sexo"]').attr('disabled','disabled');
                    form2.find('[name="telefone1"]').attr('disabled','disabled');
                    $("#carregar-endereco-paciente").click();
				}
	    	}
		});
	    $('#card-number').mask("9999.9999.9999.9999");

	}

    function clearError(form) {
    	form.find('.error').removeClass('error');
    }

    function insertMask() { 
    	$('[name="dataNascimento"], [name="data_nascimento"]').mask('00/00/0000', {placeholder: "__/__/____"});
    	MascaraTelefone($('[name="telefone1"], [name="telefone2"]'), 11);

        $('[name="telefone1"], [name="telefone2"]').keyup(function(){           
            MascaraTelefone($(this), 15);
        }); 


		$('[name="cpf"]').mask('000.000.000-00', {reverse: true});
		$('[name="CEPCadastro"], [name="CEPCobranca"], [name="CEP"],[name="CepCobranca"]').mask('00000-000');
		$('[name="cnpj"]').mask('00.000.000/0000-00', {reverse: true});
		$('[name="mesValidade"], [name="anoValidade"]').mask('00');
		$('[name="codigoSeguranca"]').mask('000');

    }

    function submits() {

		$(".pass-1-form-1").on('submit', function(e) {
			e.preventDefault();
            var form = $(this);
            var loading = new Loading();
            $.ajax({
				url: form.attr("action"),
				type: form.attr("method"),
				data: form.serialize(),
				success: function (result) {
				    if (result != null) { 
				        if(result.Sucesso)
				        {
                            $(".input-cadastro-login").attr("disabled","disabled");
                            $("#dados-basicos").removeClass("hidden");
				            $(".clienteID").val(result.Retorno);
				            $(form).find(".form-button").addClass("hidden");
				        }
				        else
				        {
	                        ModalShow();
	                        Alert('Erro!',result.Mensagem);
				        }
				    }
				    loading.hide();
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
				    console.log("Erro");
				    loading.hide();
				}
			});
               
        });
        $('.pass-1-form-1 .form-submit').on('click', function(e) {
			e.preventDefault();
			validationForm1_1();
        });

        $(".pass-1-form-2").on('submit', function(e) {
            e.preventDefault();
            SubmitFormPaciente($(this));
            
        });
        $('.pass-1-form-2 .form-submit').on('click', function(e) {
			e.preventDefault();
			validationForm1_2();

        });

        $(".pass-2-form-1").on('submit', function(e) {
            e.preventDefault();
            SubmitFormCliente($(this));
        });
        $('.pass-2-form-1 .form-submit').on('click', function(e) {
			e.preventDefault();
			validationForm2_1();
        });

        function SubmitFormCliente(form)
        {
        	var loading = new Loading();
        	$.ajax({
        		url: form.attr("action"),
        		type: form.attr("method"),
        		data: form.serialize(),
        		success: function (result) {
        			if (result != null) { 
        				if(result.Sucesso)
        				{
                            $("#register-pass-2").removeClass("active");
                            $(".register-nav-list").find("[data-pass='2']").removeClass("active");
                            $("#register-pass-3").addClass("active");
                            $(".register-nav-list").find("[data-pass='3']").addClass("active");
                            TweenLite.to('html,body', 1, {scrollTop:0, ease:Power1.easeOut});
                        }
				        else
				        {
	                        ModalShow();
	                        Alert('Erro!',result.Mensagem);
				        }
				    }
				    loading.hide();
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
				    console.log("Erro");
				    loading.hide();
				}
			});		
        }



        $(".pass-2-form-2").on('submit', function(e) {
            e.preventDefault();
            SubmitFormCliente($(this));
        });

        $('.pass-2-form-2 .form-submit').on('click', function(e) {
			e.preventDefault();
			validationForm2_2();
			
        });
        $('.pass-3-form-1 .form-submit').on('click', function(e) {
			e.preventDefault();
			
        });
        
		$('[data-partial="register-pass-2"] .form-back').on('click', function(e) {
			e.preventDefault();
			$("#register-pass-1").addClass("active");
            $(".register-nav-list").find("[data-pass='1']").addClass("active");
            $("#register-pass-2").removeClass("active");
            $(".register-nav-list").find("[data-pass='2']").removeClass("active");
        });

        function SubmitFormPaciente(form)
        {
        	var loading = new Loading();

        	var endereco = form.find('[name="Logradouro"]').val() + ',';
        	endereco += form.find('[name="Numero"]').val() + ',';
        	endereco += form.find('[name="Cidade"] option:selected').data("nome") + ',';
					endereco += form.find('[name="Estado"] option:selected').data("uf") + ',';
					endereco += form.find('[name="CEP"]').val() + ',';
					endereco += form.find('[name="Bairro"]').val() + ',';
        	geolocalizacao(endereco, form.find('[name="Latitude"]'), form.find('[name="Longitude"]'),function(){
        		$.ajax({
        			url: form.attr("action"),
        			type: form.attr("method"),
        			data: form.serialize(),
        			success: function (result) {
        				if (result != null) { 
        					if(result.Sucesso)
        					{
                        		$("#register-pass-1").removeClass("active");
                        		$(".register-nav-list").find("[data-pass='1']").removeClass("active");
                        		$("#register-pass-2").addClass("active");
                        		$(".register-nav-list").find("[data-pass='2']").addClass("active");
                        		$("#ag-nome-paciente").html(result.Retorno.NomePaciente);
                        		$("#ag-endereco-paciente").html(result.Retorno.Rua + ' ' + result.Retorno.Complemento + '<br>Bairro: ' + result.Retorno.Bairro + '<br>' + result.Retorno.Cidade);
                        		$("#local-atendimento").val(result.Retorno.LocalAtendimentoID);

                    		
                    		//$("#cep-patient").val("");
                    		//$("#address-patient").val("");
                    		//$("#address-number-patient").val("");
                    		//$("#complement-patient").val("");
                    		//$("#state-patient").val("");
                    		//$("#state-patient").change();
                    		//$("#neighborhood-patient").val("");
                    		//$('#perfil-register-patient .cep-content-patient').removeClass('active');
                    		//$('#perfil-register-patient .new-cep-charges').removeClass('hidden');
                    		//$('.endereco-paciente-id[value="'+ result.Retorno.LocalAtendimentoID +'"]').click()
                    		TweenLite.to('html,body', 1, {scrollTop:0, ease:Power1.easeOut});
                    	}
                    	else
                    		{
                    			ModalShow();
                    			Alert('Erro!',result.Mensagem);
                    		}
                    	}
                    	loading.hide();
				    	},
						error: function (XMLHttpRequest, textStatus, errorThrown) {
				    		console.log("Erro");
				    		loading.hide();
						}
					});
				});
			
		}

        $(".pass-4-form-1").on('submit', function(e) {
              e.preventDefault();
              var form = $(this);
              var loading = new Loading();
              $.ajax({
				url: form.attr("action"),
				type: form.attr("method"),
				data: form.serialize(),
				success: function (result) {
				    if (result != null) { 
				        if(result.Sucesso)
				        {
                            location.href = result.Retorno;
                        }
				        else
				        {
	                        ModalShow();
	                        Alert('Erro!',result.Mensagem);
				        }
				    }
				    loading.hide();
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
				    console.log("Erro");
				    loading.hide();
				}
			});
        });
        $('.pass-4-form-1 .form-submit').on('click', function(e) {
			e.preventDefault();
			validationForm4_1();
        });

        $(".pass-4-form-1 .form-back").on('click',function(e)
        {
        	e.preventDefault();
        	$("#register-pass-3").addClass("active");
			$(".register-nav-list").find("[data-pass='3']").addClass("active");
			$("#register-pass-4").removeClass("active");
            $(".register-nav-list").find("[data-pass='4']").removeClass("active");
        });

    }

    function validationForm1_1() {
    	var error = false;
    	var form = $('.pass-1-form-1');

    	var email = form.find('[name="email_"]');
    	var senha = form.find('[name="senha"]');

    	var senhaMin = 4;
    	var senhaMax = 18;

    	if (!validation.IsEmail(email.val()) || validation.isNull(email.val())) {
    		error = true;
    		email.addClass('error');
    	} else {
			email.removeClass('error');
    	}

    	if (senha.val().length < senhaMin || senha.val().length > senhaMax || validation.isNull(senha.val())) {
    		error = true;
    		senha.addClass('error');
    	} else {
    		senha.removeClass('error');
    	}

    	if (!error) {
    		form.submit();
    	} 
    	else
    	{
    		var elemTop = $('.pass-1-form-1').find('.error').offset().top;
    	 	TweenLite.to('html,body', 1, {scrollTop: (elemTop - 40), ease:Power1.easeOut});	
    	}
    	
    }

    /*
	    function validationForm1_2() {
	    	var error = false;
	    	var form = $('.pass-1-form-2');

	    	var nome = form.find('[name="nome"]');
	    	var cpf = form.find('[name="cpf"]');
	    	var sexo = form.find('[name="sexo"]');
	    	var dataNascimento = form.find('[name="dataNascimento"]');
	    	var telefone1 = form.find('[name="telefone1"]');
	    	var cep = form.find('[name="CEPCadastro"]');
	    	var logradouro = form.find('[name="LogradouroCadastro"]');
	    	var numero = form.find('[name="NumeroCadastro"]');
	    	var estado = form.find('[name="estadoCadastro"]');
	    	var cidade = form.find('[name="cidadeCadastro"]');
	    	var bairro = form.find('[name="BairroCadastro"]');

	    	if (validation.isNull(nome.val())) {
	    		error = true;
	    		nome.addClass('error');
	    	} else {
				nome.removeClass('error');
	    	}

	    	if (validation.isNull(cpf.val()) || !validation.verifyCPF(cpf.val())) {
	    		error = true;
	    		cpf.addClass('error');
	    		if(!validation.isNull(cpf.val())){
	    			$('.cpf-alert').css('display','block');
	    		}else{
	    			$('.cpf-alert').css('display','none');
	    		}
	    	} else {
	    		cpf.removeClass('error');
	    		$('.cpf-alert').css('display','none');
	    	}

			if (validation.isNull(sexo.val())) {
	    		error = true;
	    		sexo.addClass('error');
	    	} else {
	    		sexo.removeClass('error');
	    	}

			if (!validation.isNull(dataNascimento.val()) && !validation.verifyDate(dataNascimento.val())) {
	    		error = true;
	    		dataNascimento.addClass('error');
	    	} else {
	    		dataNascimento.removeClass('error');
	    	}

			if (validation.isNull(telefone1.val()) || telefone1.val().length < 14) {
	    		error = true;
	    		telefone1.addClass('error');
	    	} else {
	    		telefone1.removeClass('error');
	    	}

			if (validation.isNull(cep.val())) {
	    		error = true;
	    		cep.addClass('error');
	    	} else {
	    		cep.removeClass('error');
	    	}

			if (validation.isNull(logradouro.val())) {
	    		error = true;
	    		logradouro.addClass('error');
	    	} else {
	    		logradouro.removeClass('error');
	    	}

			if (validation.isNull(numero.val())) {
	    		error = true;
	    		numero.addClass('error');
	    	} else {
	    		numero.removeClass('error');
	    	}

			if (validation.isNull(estado.val())) {
	    		error = true;
	    		estado.addClass('error');
	    	} else {
	    		estado.removeClass('error');
	    	}

			if (validation.isNull(cidade.val())) {
	    		error = true;
	    		cidade.addClass('error');
	    	} else {
	    		cidade.removeClass('error');
	    	}

			if (validation.isNull(bairro.val())) {
	    		error = true;
	    		bairro.addClass('error');
	    	} else {
	    		bairro.removeClass('error');
	    	}

	    	if ($('.cep-content-physical-charges').hasClass('active')) {
		    	var cepCobranca = form.find('[name="CepCobranca"]');
		    	var logradouroCobranca = form.find('[name="LogradouroCobranca"]');
		    	var numeroCobranca = form.find('[name="NumeroCobranca"]');
		    	var estadoCobranca = form.find('[name="EstadoCobranca"]');
		    	var cidadeCobranca = form.find('[name="CidadeCobranca"]');
		    	var bairroCobranca = form.find('[name="BairroCobranca"]');

		    	if (validation.isNull(cepCobranca.val())) {
		    		error = true;
		    		cepCobranca.addClass('error');
		    	} else {
		    		cepCobranca.removeClass('error');
		    	}

				if (validation.isNull(logradouroCobranca.val())) {
		    		error = true;
		    		logradouroCobranca.addClass('error');
		    	} else {
		    		logradouroCobranca.removeClass('error');
		    	}

				if (validation.isNull(numeroCobranca.val())) {
		    		error = true;
		    		numeroCobranca.addClass('error');
		    	} else {
		    		numeroCobranca.removeClass('error');
		    	}

				if (validation.isNull(estadoCobranca.val())) {
		    		error = true;
		    		estadoCobranca.addClass('error');
		    	} else {
		    		estadoCobranca.removeClass('error');
		    	}

				if (validation.isNull(cidadeCobranca.val())) {
		    		error = true;
		    		cidadeCobranca.addClass('error');
		    	} else {
		    		cidadeCobranca.removeClass('error');
		    	}

				if (validation.isNull(bairroCobranca.val())) {
		    		error = true;
		    		bairroCobranca.addClass('error');
		    	} else {
		    		bairroCobranca.removeClass('error');
		    	}
	    	}

	    	if (!error) {
	    		form.submit();
	    	}
	    	else
	    	{
	    		var elemTop = $('.pass-1-form-2').find('.error').offset().top;
	    	 	TweenLite.to('html,body', 1, {scrollTop: (elemTop - 40), ease:Power1.easeOut});	
	    	} 
	    }
	*/
    function validationForm1_2() {
    	var error = false;
    	var form = $('.pass-1-form-2');

    	var nome = form.find('[name="nome"]');
    	var data_nascimento = form.find('[name="dataNascimento"]');
		var sexo = form.find('[name="sexo"]');
    	var telefone = form.find('[name="telefone1"]');

    	var cep = form.find('[name="CEP"]');
    	var logradouro = form.find('[name="Logradouro"]');
    	var numeroCasa = form.find('[name="Numero"]');
    	var estado = form.find('[name="Estado"]');
    	var cidade = form.find('[name="Cidade"]');
    	var bairro = form.find('[name="Bairro"]');

    	if (validation.isNull(nome.val())) {
    		error = true;
    		nome.addClass('error');
    	} else {
			nome.removeClass('error');
    	}

		if (validation.isNull(data_nascimento.val()) && !validation.verifyDate(data_nascimento.val())) {
    		error = true;
    		data_nascimento.addClass('error');
    	} else {
    		data_nascimento.removeClass('error');
    	}

		if (validation.isNull(sexo.val())) {
    		error = true;
    		sexo.addClass('error');
    	} else {
    		sexo.removeClass('error');
    	}

		if (validation.isNull(telefone.val()) || telefone.val().length < 14) {
    		error = true;
    		telefone.addClass('error');
    	} else {
    		telefone.removeClass('error');
    	}


    	if (validation.isNull(cep.val())) {
    		error = true;
    		cep.addClass('error');
    	} else {
    		cep.removeClass('error');
    	}

		if (validation.isNull(logradouro.val())) {
    		error = true;
    		logradouro.addClass('error');
    	} else {
    		logradouro.removeClass('error');
    	}

		if (validation.isNull(numeroCasa.val())) {
    		error = true;
    		numeroCasa.addClass('error');
    	} else {
    		numeroCasa.removeClass('error');
    	}

		if (validation.isNull(estado.val())) {
    		error = true;
    		estado.addClass('error');
    	} else {
    		estado.removeClass('error');
    	}

		if (validation.isNull(cidade.val())) {
    		error = true;
    		cidade.addClass('error');
    	} else {
    		cidade.removeClass('error');
    	}

		if (validation.isNull(bairro.val())) {
    		error = true;
    		bairro.addClass('error');
    	} else {
    		bairro.removeClass('error');
    	}


    	if (!error) {
    		form.submit();
    	}
    	else
    	{
    		var elemTop = $('.pass-1-form-2').find('.error').offset().top;
    	 	TweenLite.to('html,body', 1, {scrollTop: (elemTop - 40), ease:Power1.easeOut});	
    	}
    	
    }

    function validationForm2_1() {
    	var error = false;
    	var form = $('.pass-2-form-1');

    	var nome = form.find('[name="nome"]');
    	var cnpj = form.find('[name="cnpj"]');
    	var nomeContato = form.find('[name="nomeContato"]');
    	var telefone1 = form.find('[name="telefone1"]');
    	var cep = form.find('[name="CEPCadastro"]');
    	var logradouro = form.find('[name="LogradouroCadastro"]');
    	var numero = form.find('[name="NumeroCadastro"]');
    	var estado = form.find('[name="estadoCadastro"]');
    	var cidade = form.find('[name="cidadeCadastro"]');
    	var bairro = form.find('[name="BairroCadastro"]');
		var inscricaoEstadualIsento = form.find('[name="InscricaoEstadualIsento"]');
    	
    	if (validation.isNull(nome.val())) {
    		error = true;
    		nome.addClass('error');
    	} else {
			nome.removeClass('error');
    	}

    	if (validation.isNull(cnpj.val()) || !validation.verifyCNPJ(cnpj.val())) {
    		error = true;
    		cnpj.addClass('error');
    		if(!validation.isNull(cnpj.val())){
    			$('.cnpj-alert').css('display','block');
    		}else{
    			$('.cnpj-alert').css('display','none');
    		}
    		
    	} else {
			cnpj.removeClass('error');
			$('.cnpj-alert').css('display','none');
    	}
    	

    	if (validation.isNull(nomeContato.val())) {
    		error = true;
    		nomeContato.addClass('error');
    	} else {
			nomeContato.removeClass('error');
    	}

    	if (validation.isNull(telefone1.val()) || telefone1.val().length < 14) {
    		error = true;
    		telefone1.addClass('error');
    	} else {
			telefone1.removeClass('error');
    	}

		if (validation.isNull(cep.val())) {
    		error = true;
    		cep.addClass('error');
    	} else {
    		cep.removeClass('error');
    	}

    	if (validation.isNull(logradouro.val())) {
    		error = true;
    		logradouro.addClass('error');
    	} else {
    		logradouro.removeClass('error');
    	}

		if (validation.isNull(numero.val())) {
    		error = true;
    		numero.addClass('error');
    	} else {
    		numero.removeClass('error');
    	}

		if (validation.isNull(estado.val())) {
    		error = true;
    		estado.addClass('error');
    	} else {
    		estado.removeClass('error');
    	}

		if (validation.isNull(cidade.val())) {
    		error = true;
    		cidade.addClass('error');
    	} else {
    		cidade.removeClass('error');
    	}

		if (validation.isNull(bairro.val())) {
    		error = true;
    		bairro.addClass('error');
    	} else {
    		bairro.removeClass('error');
    	}

    	if ($('.cep-content-juristic-charges').hasClass('active')) {
	    	var cepCobranca = form.find('[name="CepCobranca"]');
	    	var logradouroCobranca = form.find('[name="logradouroCobranca"]');
	    	var numeroCobranca = form.find('[name="numeroCobranca"]');
	    	var estadoCobranca = form.find('[name="EstadoCobranca"]');
	    	var cidadeCobranca = form.find('[name="CidadeCobranca"]');
	    	var bairroCobranca = form.find('[name="BairroCobranca"]');

	    	if (validation.isNull(cepCobranca.val())) {
	    		error = true;
	    		cepCobranca.addClass('error');
	    	} else {
	    		cepCobranca.removeClass('error');
	    	}

			if (validation.isNull(logradouroCobranca.val())) {
	    		error = true;
	    		logradouroCobranca.addClass('error');
	    	} else {
	    		logradouroCobranca.removeClass('error');
	    	}

			if (validation.isNull(numeroCobranca.val())) {
	    		error = true;
	    		numeroCobranca.addClass('error');
	    	} else {
	    		numeroCobranca.removeClass('error');
	    	}

			if (validation.isNull(estadoCobranca.val())) {
	    		error = true;
	    		estadoCobranca.addClass('error');
	    	} else {
	    		estadoCobranca.removeClass('error');
	    	}

			if (validation.isNull(cidadeCobranca.val())) {
	    		error = true;
	    		cidadeCobranca.addClass('error');
	    	} else {
	    		cidadeCobranca.removeClass('error');
	    	}

			if (validation.isNull(bairroCobranca.val())) {
	    		error = true;
	    		bairroCobranca.addClass('error');
	    	} else {
	    		bairroCobranca.removeClass('error');
	    	}
    	}

    	if (!error) {
    		form.submit();
    	} 
    	else
    	{
    		var elemTop = $('.pass-2-form-1').find('.error').offset().top;
    	 	TweenLite.to('html,body', 1, {scrollTop: (elemTop - 40), ease:Power1.easeOut});	
    	}
    }

	

    function validationForm2_2() {
    	var error = false;
    	var form = $('.pass-2-form-2');

    	var nome = form.find('[name="nome"]');
    	var cpf = form.find('[name="cpf"]');
		var sexo = form.find('[name="sexo"]');
    	var telefone = form.find('[name="telefone1"]');


    	if (validation.isNull(nome.val())) {
    		error = true;
    		nome.addClass('error');
    	} else {
			nome.removeClass('error');
    	}

    	if (validation.isNull(cpf.val()) || !validation.verifyCPF(cpf.val())) {
    		error = true;
    		cpf.addClass('error');
    		if(!validation.isNull(cpf.val())){
    			$('.cpf-alert').css('display','block');
    		}else{
    			$('.cpf-alert').css('display','none');
    		}
    	} else {
    		cpf.removeClass('error');
    		$('.cpf-alert').css('display','none');
    	}

		if (validation.isNull(sexo.val())) {
    		error = true;
    		sexo.addClass('error');
    	} else {
    		sexo.removeClass('error');
    	}

		if (validation.isNull(telefone.val()) || telefone.val().length < 14) {
    		error = true;
    		telefone.addClass('error');
    	} else {
    		telefone.removeClass('error');
    	}

	
    	var cep = form.find('[name="CEPCadastro"]');
    	var logradouro = form.find('[name="LogradouroCadastro"]');
    	var numeroCasa = form.find('[name="NumeroCadastro"]');
    	var estado = form.find('[name="estadoCadastro"]');
    	var cidade = form.find('[name="cidadeCadastro"]');
    	var bairro = form.find('[name="BairroCadastro"]');

    	if (validation.isNull(cep.val())) {
    		error = true;
    		cep.addClass('error');
    	} else {
    		cep.removeClass('error');
    	}

		if (validation.isNull(logradouro.val())) {
    		error = true;
    		logradouro.addClass('error');
    	} else {
    		logradouro.removeClass('error');
    	}

		if (validation.isNull(numeroCasa.val())) {
    		error = true;
    		numeroCasa.addClass('error');
    	} else {
    		numeroCasa.removeClass('error');
    	}

		if (validation.isNull(estado.val())) {
    		error = true;
    		estado.addClass('error');
    	} else {
    		estado.removeClass('error');
    	}

		if (validation.isNull(cidade.val())) {
    		error = true;
    		cidade.addClass('error');
    	} else {
    		cidade.removeClass('error');
    	}

		if (validation.isNull(bairro.val())) {
    		error = true;
    		bairro.addClass('error');
    	} else {
    		bairro.removeClass('error');
    	}


    	if ($('.cep-content-physical-charges').hasClass('active')) {
	    	var cepCobranca = form.find('[name="CepCobranca"]');
	    	var logradouroCobranca = form.find('[name="LogradouroCobranca"]');
	    	var numeroCobranca = form.find('[name="NumeroCobranca"]');
	    	var estadoCobranca = form.find('[name="EstadoCobranca"]');
	    	var cidadeCobranca = form.find('[name="CidadeCobranca"]');
	    	var bairroCobranca = form.find('[name="BairroCobranca"]');

	    	if (validation.isNull(cepCobranca.val())) {
	    		error = true;
	    		cepCobranca.addClass('error');
	    	} else {
	    		cepCobranca.removeClass('error');
	    	}

			if (validation.isNull(logradouroCobranca.val())) {
	    		error = true;
	    		logradouroCobranca.addClass('error');
	    	} else {
	    		logradouroCobranca.removeClass('error');
	    	}

			if (validation.isNull(numeroCobranca.val())) {
	    		error = true;
	    		numeroCobranca.addClass('error');
	    	} else {
	    		numeroCobranca.removeClass('error');
	    	}

			if (validation.isNull(estadoCobranca.val())) {
	    		error = true;
	    		estadoCobranca.addClass('error');
	    	} else {
	    		estadoCobranca.removeClass('error');
	    	}

			if (validation.isNull(cidadeCobranca.val())) {
	    		error = true;
	    		cidadeCobranca.addClass('error');
	    	} else {
	    		cidadeCobranca.removeClass('error');
	    	}

			if (validation.isNull(bairroCobranca.val())) {
	    		error = true;
	    		bairroCobranca.addClass('error');
	    	} else {
	    		bairroCobranca.removeClass('error');
	    	}
    	}

    	if (!error) {
    		form.submit();
    	}
    	else
    	{
    		var elemTop = $('.pass-2-form-2').find('.error').offset().top;
    	 	TweenLite.to('html,body', 1, {scrollTop: (elemTop - 40), ease:Power1.easeOut});	
    	}
    	
    }

    function validationForm4_1() {
    	var error = false;
    	var form = $('.pass-4-form-1');

    	if ($('#pay-card').hasClass('active')) {
    	
	    	var numero_cartao = form.find('[name="numeroCartao"]');
	    	var cartao_validade_mes = form.find('[name="mesValidade"]');
	    	var cartao_validade_ano = form.find('[name="anoValidade"]');
	    	var numero_cartao = form.find('[name="NomeTitular"]');
	    	var cartao_codigo_seguranca = form. find('[name="codigoSeguranca"]');

			if (validation.isNull(numero_cartao.val())) {
	    		error = true;
	    		numero_cartao.addClass('error');
	    	} else {
	    		numero_cartao.removeClass('error');
	    	}

			if (validation.isNull(cartao_validade_mes.val()) || cartao_validade_mes.val() > 12) {
	    		error = true;
	    		cartao_validade_mes.addClass('error');
	    	} else {
	    		cartao_validade_mes.removeClass('error');
	    	}

			if (validation.isNull(cartao_validade_ano.val())) {
	    		error = true;
	    		cartao_validade_ano.addClass('error');
	    	} else {
	    		cartao_validade_ano.removeClass('error');
	    	}

			if (validation.isNull(numero_cartao.val())) {
	    		error = true;
	    		numero_cartao.addClass('error');
	    	} else {
	    		numero_cartao.removeClass('error');
	    	}

			if (validation.isNull(numero_cartao.val())) {
	    		error = true;
	    		numero_cartao.addClass('error');
	    	} else {
	    		numero_cartao.removeClass('error');
	    	}

			if (validation.isNull(cartao_codigo_seguranca.val())) {
	    		error = true;
	    		cartao_codigo_seguranca.addClass('error');
	    	} else {
	    		cartao_codigo_seguranca.removeClass('error');
	    	}

	    }

	    if (!error) {
    		form.submit();
    	} 
    }
    
    function EstadoSelecionado(inputCidades, estadoID, callBackFunction, loading, cidade) {
    	if(!loading)
    	    loading = new Loading();
    	if (estadoID) {
    	    $.ajax({
    	        type: "GET",
    	        url: $("#urlListarCidades").val(),
    	        data: { id: estadoID },
    	        success: function (data) {
    	            inputCidades.html('<option value="" class="hidden"></option>');
    	            $.each(data, function (index, value) {
    	                inputCidades.append('<option value="' + value.ID + '" data-nome="' + value.Nome + '">' + value.Nome + '</option>');
    	            });
    	            if (cidade) {
    	                inputCidades.val(cidade);
    	            }
    	            if (callBackFunction)
    	                callBackFunction(inputCidades);
    	            else
    	                loading.hide();
    	        }
    	    });
    	} else {
		  loading.hide();
        }

    }

$(".ddlEstado").on("change", function () {
    var inputEstado = $(this); 
    var parent = $(inputEstado.parents(".estadoCidade"));
    var city = parent.find(".ddlCidade");
    console.log(city);
    if ($(this).val() !== '') {
        EstadoSelecionado(city, inputEstado.val());
        }
        else {
            $(city).html('<option value="" class="hidden"></option>');
        }
    });
  function cepChange(cep,inputCep)
    {
    	var loading = new Loading();
        $.ajax({
            type: "GET",
            url: "https://webmaniabr.com/api/1/cep/" + cep + "/?app_key=" + 'PYlhy37jk1bhwY3WnKq0GZray0xA5MGb' + "&app_secret=" + 'VWjiPK0jzOYROBNZLmfqtmT3PKPcZ7cGrLGRMQG6vA5wMJX1',
            data: { cep: cep, formato: "json" },
            success: function (data) {
                
                    $(inputCep).find(".txtBairro").val(data.bairro);
                    $(inputCep).find(".txtLogradouro").val(data.endereco);
                    var inputEstado = $(inputCep).find(".ddlEstado"); 
                    inputEstado.val(inputEstado.find("[data-uf='" + data.uf + "']").val());
                    var inputCidade = $(inputCep).find(".ddlCidade"); 
                    EstadoSelecionado(inputCidade, inputEstado.val(), function (inputCidade) { inputCidade.val(inputCidade.find("[data-nome='" + data.cidade + "']").val()); loading.hide(); }, loading);
                
            }
        });
    }
    

    


    $(".txtCep").on("blur", function () {
        var input = $(this);
        var classCep = "." + input.attr('data-cep');
        cepChange(input.val().replace("-", ""), classCep);
    });

    function MascaraTelefone(inputs, length)
    {
        inputs.each(function(){
            var input = $(this);
            var mask = "(00) 00000-0000";
            if(input.val().length < length ){
                mask = "(00) 0000-0000Z";
                        }
            if((input.attr("mascara") == undefined) || (input.attr("mascara") != mask)){
                input.attr("mascara", mask);
                input.unmask().mask(mask, {
                    translation: {
                        'Z': {
                            pattern: /[0-9]/, optional: true
                        }
                    }
                });
                setTimeout(function(){ input[0].selectionStart = input[0].selectionEnd = 10000; }, 0);
            }           
        });
    };
  function geolocalizacao(endereco, inputLatitude, inputLongitude, callBackFunction, c = 0) {
	
        $.ajax({
		  url: "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDrUUCk1Yz5alT7PyYHzaur3-9fp_oRPlg&address=" + endereco + "&sensor=false",
            type: "POST",
            success: function (res) {
                console.log(res);
                var lat = ""; var long="";
                if (res.results.length != 0) {
                    lat=res.results[0].geometry.location.lat;
                    long = res.results[0].geometry.location.lng;
                }
                // if ($("[name=Latitude]").val() == "") {
								if ((!lat || !long) && c < 6) {
										setTimeout(() => { geolocalizacao(endereco, inputLatitude, inputLongitude, callBackFunction, c++) }, 10000);
								} else if (c == 6) {
								  notifica('Nao foi poss�vel validar o endere�o no momento.', 'erro');
								} else {
                    inputLatitude.val(lat);
                    inputLongitude.val(long);
                    if(callBackFunction)
                        callBackFunction();
                }
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
				    console.log("Erro");
					if(callBackFunction)
            			callBackFunction();	    
				}
        });
    }
    
    return init();

};
