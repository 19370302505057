var BlockNotices = function() {

	this.init = function() {

		new MasonryPlugin();
		
		$grid = $('.grid').masonry({
			itemSelector: '.grid-item'
		});
		
		/*$('.opacity').height( $('[data-component="block-notices"] .box-infos').last().height() + 115 );*/

	};

	return this.init();
};
