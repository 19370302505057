var RegisterAttendance = function(callBackSubmit,validation,callBackVoltar) {
    
    this.init = function() {

		addListeners();
    };
    
    
    
function addListeners() {  
 
    $('[name="horarioInicio"], [name="horarioFim"]').mask('00:00');

    $('#datepicker').datepicker({
        dateFormat: 'dd/mm/yyyy',
        onSelect: function(dateText, inst) {
            var date = $(this).datepicker('getDate'),
            day  = date.getDate(),
            month = date.getMonth() + 1,
            year =  date.getFullYear();
            $("#data-agendamento").val(day + '/' + (month < 10 ? "0" + month : month) + '/' + year);
        }
    });

    $('[name="horarioInicio"]').on("blur", function()
    {
    	$("#inicio-agendamento").val($('[name="horarioInicio"]').val());
            
    });

    $('[name="horarioFim"]').on("blur", function()
    {
    	$("#fim-agendamento").val($('[name="horarioFim"]').val());
    });



    if($("#data-agendamento").val() == '')
    {
        var date = new Date();
        var day  = date.getDate();
        var month = date.getMonth() + 1;
        var year =  date.getFullYear();
        $("#data-agendamento").val(day + '/' + (month < 10 ? "0" + month : month) + '/' + year);
    }
    else
    {
    	var data = $("#data-agendamento").val().split("/");
    	var day = parseInt(data[0]);
    	var month = parseInt(data[1]) - 1;
    	var year = parseInt(data[2]);
    	$("#datepicker").datepicker("setDate", new Date(year,month,day));

    	if($("#inicio-agendamento").val() == "06:00" && $("#fim-agendamento").val() == "12:00")
    		$(".turn-morning").click();
    	else if($("#inicio-agendamento").val() == "12:00" && $("#fim-agendamento").val() == "18:00")	
    		$(".turn-afternoon").click();
    	else if($("#inicio-agendamento").val() == "18:00" && $("#fim-agendamento").val() == "00:00")
    		$(".turn-night").click();
    	else if($("#inicio-agendamento").val() == "00:00" && $("#fim-agendamento").val() == "06:00")	
    		$(".turn-dawn").click();
    	else
    	{
    		$('[name="horarioInicio"]').val($("#inicio-agendamento").val());
    		$('[name="horarioFim"]').val($("#fim-agendamento").val());
    		$(".turn-specific").click();
    	}
    }	
    
    $('.button-turn').on('click', function() {
        $('.button-turn.active').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
        $('.button-turn').removeClass('active');
        $(this).addClass('active');
        $(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');
        if ($(this).find('.icon-clock').length > 0) {
            $(this).find('.icon-clock').addClass('icon-white-clock').removeClass('icon-clock');
        } else if ($(this).find('.icon-white-clock').length > 0) {
				/*Não fazer nada*/
        } else if ($('.button-turn').find('.icon-white-clock').length > 0) {
            $('.button-turn').find('.icon-white-clock').addClass('icon-clock').removeClass('icon-white-clock');
        }
        if($(this).hasClass("turn-morning"))
        {
            $("#inicio-agendamento").val("06:00");
            $("#fim-agendamento").val("12:00");
        }
        else if($(this).hasClass("turn-afternoon"))
        {
            $("#inicio-agendamento").val("12:00");
            $("#fim-agendamento").val("18:00");
        }
        else if($(this).hasClass("turn-night"))
        {
            $("#inicio-agendamento").val("18:00");
            $("#fim-agendamento").val("00:00");
        }
        else if($(this).hasClass("turn-dawn"))
        {
            $("#inicio-agendamento").val("00:00");
            $("#fim-agendamento").val("06:00");
        }
        else
        {
            $("#inicio-agendamento").val($('[name="horarioInicio"]').val());
            $("#fim-agendamento").val($('[name="horarioFim"]').val());
        }
    });
    
    $(".pass-3-form-1").on('submit', function(e) {
        e.preventDefault();
        var form = $(this);
        $.ajax({
            url: form.attr("action"),
            type: form.attr("method"),
            data: form.serialize(),
            success: function (result) {
                if (result != null) {
                    if(result.Sucesso)
                    {
                        if(callBackSubmit)
                        	callBackSubmit(result);
                            //TweenLite.to('html,body', 1, {scrollTop:0, ease:Power1.easeOut});
                }
                    else
                    {

                        ModalShow();
                        Alert('Erro!',result.Mensagem);
                    }
                }
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                console.log("Erro");
            }
        });
    });
    
    $('.pass-3-form-1 .form-submit').on('click', function(e) {
        e.preventDefault();
        validationForm3_1();
    });

    $('.pass-3-form-1 .form-voltar').on('click', function(e) {
        e.preventDefault();
        callBackVoltar();
    });
    
    $(".ddlServico").on("change",function()
    {
    	$("#pathology-type-hidden").val('0');
    	$("#pathology-type").val("");
    	ConsultarPreco();
    });


    $('.register').on('click','.pathology-list', function() {
        var id = $(this).data('id');
        //console.log(id);
        $('#pathology-type').val($(this).attr('data-pathology'));
        $('#pathology-type-hidden').val($(this).attr('data-id'));
        $('#pathology-type-hidden').attr('data-id', id);
		
		ConsultarPreco();
        
        $('.list-result-pathology').slideUp('fast');
    });

    $('#pathology-type').keyup(function(e) {
    	if($("#pathology-type-hidden").val() != 0)
    	{
    		$("#pathology-type-hidden").val("0");
    		ConsultarPreco();	
    	}

    	if ($(this).val().length >= 3) {

            $.ajax({
                url: $(this).attr("data-href") + "?servicoID=" + $(".ddlServico").val() + "&patologia=" + $(this).val(),
                type: "GET",
                success: function (result) {
                    if (result != null) { 
                        if(result.Sucesso)
                        {
                        	$('.list-result-pathology').empty();
                            $.each(result.Retorno, function(i,e){
                                $('.list-result-pathology').append('<li class="pathology-list" data-id="' + e.ID +'" data-pathology="' + e.Nome + '">' + e.Nome + '</li>');
                            });

                            $('.list-result-pathology').slideDown('fast');
                            
                        }
                        else 
                        {   
                            ModalShow();
                            Alert('Erro!',result.Mensagem);
                            // alert(result.Mensagem);
                        }
                    }
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro")
                }
            }); 

        } else {
            $('.list-result-pathology').slideUp('fast');
            $('.list-result-pathology').empty();
        }
        
    });

    $('#pathology-type').on('blur', function() {
        setTimeout(function(){
            $('.list-result-pathology').slideUp('fast');
        },500);
    });


    $('.unknow-pathology .icon').on('click', function() {

        if (!$(this).hasClass('icon-mark')) {

            $(this).addClass('icon-mark').removeClass('icon-unmark');

            $('.list-result-pathology').slideUp('fast');
            $('.list-result-pathology').empty();

            $('#pathology-type').val("");
            $('#pathology-type-hidden').val("0");

            $('#pathology-type').attr('disabled', 'disabled');

            ConsultarPreco();

        } else {

            $(this).addClass('icon-unmark').removeClass('icon-mark');

            $('#pathology-type').removeAttr('disabled');

        }

    });
}
function validationForm3_1() {
    var error = false;
    var form = $('.pass-3-form-1');
    var especialidade = form.find('[name="servico"]');
    var patologia = form.find('[name="ServicoPatologia"]');
    var horarioInicio = form.find('[name="horarioInicio"]');
    var horarioFim = form.find('[name="horarioFim"]');
    var localAtendimento = form.find('#local-atendimento');

    error = validateHhMm(horarioInicio, horarioFim);

    if (validation.isNull(especialidade.val())) {
        error = true;
        especialidade.addClass('error');
    } else {
        especialidade.removeClass('error');
    }


    if (validation.isNull($('.pathology-type').attr('disabled'))) {
        if (validation.isNull(patologia.val()) || patologia.val() == "0") {
            error = true;
            $('.pathology-type').addClass('error');
        } else {
            $('.pathology-type').removeClass('error');
        }
    } else {
        $('.pathology-type').removeClass('error');
    }


    if(localAtendimento.val() == "")
    {
        error = true;
        $('.button-location').addClass('error');
    } else {
        $('.button-location').removeClass('error');   
    }

    if ($('.button-turn.active').length == 0) {
        error = true;
        $('.button-turn').addClass('error');
        $('.icon-clock').addClass('icon-white-clock').removeClass('icon-clock');
    } else {
        $('.button-turn').removeClass('error');
        if (!$('.turn-specific').hasClass('active')) {
            $('.icon-white-clock').addClass('icon-clock').removeClass('icon-white-clock');
        }
    }
    if (!error) {
        form.submit();
    }
    else
    {
        var elemTop = $('body').find('.error').offset().top;
            TweenLite.to('html,body', 1, {scrollTop: (elemTop - 40), ease:Power1.easeOut}); 
    }
}

function ConsultarPreco(){
    var dados = { servicoID: $(".ddlServico").val() ? $(".ddlServico").val() : 0,
                  patologiaID: $("#pathology-type-hidden").val() ? $("#pathology-type-hidden").val() : 0,
                  LocalAtendimentoPacienteID: $("#local-atendimento").val() ? $("#local-atendimento").val() : 0 
                };
    $.ajax({
            url: $("#urlCarregarPreco").val(),
            type: "GET",
            data: dados,
            success: function (result) {
                if (result != null) {
                    if(result.Sucesso)
                    {
                        $("#valorAtendimento").html("R$ " + result.Retorno);
                    }
                    else
                    {
                        ModalShow();
                        Alert('Erro!',result.Mensagem);
                    }
                }
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                console.log("Erro");
            }
        });
    
}
  
      
function validateHhMm(inputField1, inputField2) 
    {
        var error = false;

        var isValid1 = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField1.val());
        var isValid2 = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField2.val());

        if($('.button-turn:not(.turn-specific)').hasClass('active')){
            $(inputField1).removeClass('error');
            $(inputField2).removeClass('error');
        }else if (isValid1 && isValid2 && inputField2.val() > inputField1.val() && $('.turn-specific').hasClass('active')) 
        {
            $(inputField1).removeClass('error');
            $(inputField2).removeClass('error');
        } else {
            $(inputField1).addClass('error');
            $(inputField2).addClass('error');
            error = true;
        }



        return error;
    }

	return this.init();





};
