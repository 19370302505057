var ProfissionalFinanceiro = function(){

	var templateCompile;
    var templateSource = $(".template-professional-financial-row").html();
    var templateDestiny = $("#ordens");

    //mobile
    var templateCompileMobile;
    var templateSourceMobile = $(".table-row-mobile-financial-template-professional").html();
    var templateDestinyMobile = $("#ordensMobile");
    //
    
	var limparFiltro = false;

	function init() {
		insertMask();
		compileAddresTemplate();
		addListeners();

        $('.money-order-header').on('click',function(){
            var body = $(this).next('.money-order-body');
        
            var icon = $(this).find('.icon');
            icon.toggleClass('icon-arrow-green');
            body.slideToggle();
        })
	}

	function compileAddresTemplate() {
		templateCompile = dust.compile(templateSource, 'attendanceList');
		dust.loadSource(templateCompile);
	    //mobile
		templateCompileMobile = dust.compile(templateSourceMobile, 'ordensListMobile');
		dust.loadSource(templateCompileMobile);
	    //
	};

	function insertMask() {
		$('[name="DataInicio"], [name="DataFim"]').mask('00/00/0000', { placeholder: "__/__/____" });
	}

	function addListeners() {
	    if ($(window).width() < 992) {
	        $(".infolabel").css({ "left": "10px", "margin-left": "15px" });
	        $(".pacienteNome").css({ "left": "-15px", "width": "10px" });
	        $(".valorTotal").css({ "left": "180px" });
	    }

		$('.carousel-rows').slick({
			dots: true,
			arrows: false
		});

		$('.button-status').on('click', function() {
            $('.button-status').removeClass('active');
            $(this).addClass('active');
            $("#status-filtro").val($(this).attr("data-value"));
            $(".form-button.-filter").click();
        });

        $('.form-filter').on("submit",function(e) {
            e.preventDefault();
            var form = $(this);

            if(limparFiltro)
            {
                form[0].reset();
                $(".button-status.-all").click();

            }
            var loading = new Loading();
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: form.serialize(),
                success: function (result) {
                    if (result != null) { 
                        if(result.Sucesso)
                        {
                        	//$("#cont-pagas").html(result.Retorno.Pagas);
                            //$("#cont-pendentes").html(result.Retorno.Pendentes);
                            $("#cont-itens").html(result.Retorno.NumeroItens);
                            if(limparFiltro)
                                $("#limpar-filtro").addClass("hidden");
                            else
                                $("#limpar-filtro").removeClass("hidden");
                                
                            templateDestiny.html('');
                            dust.render('attendanceList', result.Retorno, function(err, out) {
                                templateDestiny.html(out);
                            });


                            templateDestinyMobile.html('');
                            dust.render('ordensListMobile', result.Retorno, function (err, out) {
                                templateDestinyMobile.html(out);
                            });
                            $('.link-style-2').addClass('hidden');
                            $('.carousel-rows').addClass('hidden');
                            $('.table-row-mobile').addClass('hidden');
                            $('#ordensMobile').removeClass('hidden');

                        }
                        else
                        {
                            ModalShow();
                            Alert('Atenção!',result.Mensagem);
                            
                        }
                    }

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro");
                    loading.hide();
                }
            });

        });

 		$("#limpar-filtro").on("click",function(e) {
 		    e.preventDefault();
 		    if (!$('#ordensMobile').hasClass('hidden')) {
 		        location.reload();
 		    } else {
 		        limparFiltro = true;
 		        $('.form-filter').submit();
 		    }
        });

		$(".form-button.-filter").on("click",function(e) {
            e.preventDefault();
            limparFiltro = false;
            $('.form-filter').submit(); 
        });

		$(".table-list").on("click", ".-accept", function (e) {
			e.preventDefault();
			var ordemPagamentoID = $(this).attr("data-id");
			var url = $("#urlAceitarSolicitacao").val() + "?ordemPagamentoID=" + ordemPagamentoID + "&aceita=True";
			
			e.preventDefault();
			
			var loading = new Loading();
			$.ajax({
				url: url,
				success: function (result) {
					if (result.Sucesso) {
							ModalShow();
                            Alert('Atenção!','Ordem de pagamento aceita com sucesso');
                            $(".form-button.-filter").click();
                        }
						else {
							ModalShow();
                            Alert('Atenção!',result.Mensagem);
						}
					loading.hide();
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					console.log("Erro")
				}
			});
		});

		$(".table-list").on("click", ".-cancel", function (e) {
			e.preventDefault();
			var ordemPagamentoID = $(this).attr("data-id");
			var url = $("#urlAceitarSolicitacao").val() + "?ordemPagamentoID=" + ordemPagamentoID + "&aceita=False";
			
			e.preventDefault();
			
			var loading = new Loading();
			$.ajax({
				url: url,
				success: function (result) {
					if (result.Sucesso) {
							ModalShow();
                            Alert('Atenção!','Ordem de pagamento recusada com sucesso');
                            $(".form-button.-filter").click();
                        }
						else {
							ModalShow();
                            Alert('Atenção!',result.Mensagem);
						}
					loading.hide();
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					console.log("Erro")
				}
			});
		});
	}

	$('.table-result').on('click', '.link-style-2', function (e) {
	    e.preventDefault();
	    $(this).next().removeClass('hidden');
	    $('.link-style-2').addClass('hidden');
	    $('.carousel-rows').addClass('hidden');
	    $('.btn-back-view').removeClass('hidden');
	});

	$('.table-result').on('click', '.btn-back-view', function (e) {
	    e.preventDefault();
	    $('.link-style-2').removeClass('hidden');
	    $('.carousel-rows').removeClass('hidden');
	    $('.table-item-mobile').addClass('hidden');
	    $(this).addClass('hidden');
	});


	return init();

};
