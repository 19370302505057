
	function ModalShow(){
		TweenMax.to('.reveal-modal.ModalAlert', .6, {opacity:1 , display: 'block', ease:Power1.easeInOut});
		TweenMax.to('.bg-modal', .6, {opacity:1 , display: 'block', ease:Power1.easeInOut});

		document.querySelector(".ModalAlert").addEventListener("click", function(){
		    TweenMax.to('.reveal-modal.ModalAlert', .6, {opacity:0 , display: 'none', ease:Power1.easeInOut});
			TweenMax.to('.bg-modal', .6, {opacity:0 , display: 'none', ease:Power1.easeInOut});
		});

	};

	function Alert(title, message) {

		var modalTitulo = $('#ModalAlertTitle');
		var modalMessage = $('#ModalAlertMessage');

		$(modalTitulo).text(title);
		$(modalMessage).text(message);

	};	

	function HideModal(){
        TweenMax.to('.reveal-modal.ModalAlert', .3, { opacity: 0, display: 'none', ease: Power1.easeInOut });
        TweenMax.to('.bg-modal', .3, { opacity: 0, display: 'none', ease: Power1.easeInOut });

        if ($(".payment-slip").data('id') == 'card') {
            $('.card-content').slideDown('slow');
        }
        if ($(".payment-slip").data('id') == 'boleto') {
            $('.boleto-content').slideDown('slow');
        }
	};



	$(".payment-slip").on("click", function () {
        var dataId = $(this).data('id');
        var modalID = "#" + $(this).parents('.ModalPayment').attr('id');
       // $(modalID + " .payment-slip").removeClass('active');
        $(modalID + " .payment-slip").find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
        //$(this).addClass('active'); 
        $(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');
        $(modalID + ' .confirmando').removeAttr('disabled');
        $(modalID + ' .confirmando').css('pointer-events', 'auto');
        if (dataId == 'card') {
            $(modalID + ' .fraseCard').removeAttr('hidden');
            $(modalID + ' .boleto-content').slideUp();
            $(modalID + ' .card-content').slideDown('slow');
            $(modalID + ' .metodo-pagamento').val('C');
        }
        if (dataId == 'boleto') {
            $(modalID + ' .fraseBoleto').removeAttr('hidden');
            $(modalID + ' .card-content').slideUp();
            $(modalID + ' .boleto-content').slideDown('slow');
            $(modalID + ' .metodo-pagamento').val('B');
        }  
     
    });

    $('.ModalPayment').on('click', '.close-payment', function(){
    	$('.bg-modal-payment').css('display','none');
    	$('.bg-modal-payment').css('ModalPayment','none');
    });

    $('.ModalPayment').on('click', '.form-button', function(){
    	$('.bg-modal-payment').css('display','none');
    	$('.bg-modal-payment').css('ModalPayment','none');
    });    

    $('.cancelar-modal').on('click', function () {
        var modalID = "#" + $(this).parents('.ModalPayment').attr('id');
        var metodoPagamento = $(modalID + " .metodo-pagamento").val();
        var div = " .boleto-content";
        if (metodoPagamento == "C") div = " .card-content";

        $(modalID + div).slideUp();
        $(modalID + div).slideDown();

        $(".close-reveal-modal").click();
    });  

    $(".form-button.form-change").on("click", function (e) {
        e.preventDefault();
        $(this).parents('.form-change').submit();
    });

    $('.form-change').on("submit", function (e) {
        e.preventDefault();
        var form = $(this);

        var modalID = "#" + $(this).parents('.ModalPayment').attr('id');
        var metodoPagamento = $(modalID + " .metodo-pagamento").val();
        var div = " .boleto-content";

        if (metodoPagamento == "C") div = " .card-content";
        $(modalID + div).slideUp();

        $(".close-reveal-modal").click();
        var loading = new Loading();
        $.ajax({
            url: form.attr("action"),
            type: form.attr("method"),
            data: form.serialize(),
            success: function (result) {
                if (result != null) {
                        ModalShow();
                        Alert('Atenção!', result.Mensagem);
                        $('.form-filter').submit();
                }

                loading.hide();
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                console.log("Erro");
                loading.hide();
            }
        });
        $(modalID + div).slideDown();
    });

    function ModalPayment(){
    	$('.bg-modal-payment').css('display','block');
    }  




