var Header = function() {

	var header = $('header').find('.status-' + $('header').attr('class'));

	function init() {

		header.find('.menu-mobile .current').text( header.find('.menu-mobile .active').text() );

		addListeners();
		
	}

	function addListeners() {
		
	  $('.menu-mobile').on('click', function () {
		

		if ($(this).find('.list').hasClass('active')) {

		  $(this).find('.list').slideUp('500', function () {
				$(this).removeClass('active');
			});
			  $(this).find('.current-content i').switchClass( "fa-angle-up", "fa-angle-down", 200, "easeInOutQuad" );

			} else {
		
			  $(this).find('.list').slideDown('500', function() {
					$(this).addClass('active');
				});
			  $(this).find('.current-content i').switchClass( "fa-angle-down", "fa-angle-up", 200, "easeInOutQuad" );

			}

		});

		header.find('.menu-mobile .item').on('click', function() {

			$('.menu-mobile .item').removeClass('active');
			$(this).addClass('active');
			$('.menu-mobile .current').text( $(this).text() );

		});


		$('.btn-area:not(.form-button)').on('click', function(e){
			
			if ($('[data-page="home"]').length > 0) {
				e.preventDefault();
				var hash = $(this).prop("hash");
				$("html, body").animate({ scrollTop: $(hash).offset().top}, 1000);
			} else{
			
				href = $(this).attr('href');

				window.location = href.substring(0,9);
			}
		});	

		$('.notifications').on('click', function(){
			$('.notifications-component').slideToggle();
		});

		$('.form-back').on('click', function(){
			TweenLite.to('html,body', 1, {scrollTop:0, ease:Power1.easeOut});
		});
		$('.form-voltar').on('click', function(){
			TweenLite.to('html,body', 1, {scrollTop:0, ease:Power1.easeOut});
		});		

		setInterval(function()
		{
			$.ajax({
				url: $('#urlNotificacoes').val(),
				type: "GET",
				success: function (result) {
					if(result.Sucesso)
                        {
                        	if(result.Retorno.length > 0)
                        	{
                        		$(".notifications-parent .number").removeClass('hidden');
                        		$(".notifications-parent .number").text(result.Retorno.length);
                        		$('.notifications-list').html('');
                        		$.each(result.Retorno, function( index, value ) {
  									$('.notifications-list').append('<li class="clearfix">' +
  																		'<i class="icon-alert "></i>' +
  																		'<div class="notification-inner-content">' +
  																			'<p>' + value.TextoNotificacao + '</p>' +
  																			'<a href="' + value.Link + '" class="btn btn-notification">Confira</a>' +
  																		'</div>' +
  																		'<div class="line-bottom"></div>' + 
  																	'</li>');
  								});
                        	}
                        }
                    },
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					console.log("Erro");
				}
			});
		}, 60000);


		var prev = 0;
		var $window = $(window);
		var nav = $('header');

		$window.on('scroll', function(){
		  var scrollTop = $window.scrollTop();
		  nav.toggleClass('menu-hide', scrollTop > prev);
		  prev = scrollTop;
		});

		$('[data-page="home-flow"]').prev('.ShowMenu').css('display','none');

	}

	return init();

};
