var ProfissionalMobile = function(){
    
	function init() {
		addListeners();
	}
    
	function addListeners() {

        $('.professional-mobile-menu').on('click', function(){
            $(this).next('nav').slideToggle('slow');
        });         
	}

	return init();

};
