var Agenda = function () {

	var validation = new Validation();

	function init() {

		var calendario = $('.calendar').offset().top;
		var hora;
		var enderecos;
		var detalheAgenda = {};
		var novaHora = '';
		var limparFiltro = false;

		var wWidth = $(window).width();

	if (wWidth < 480) {
	    $('.calendar').fullCalendar({

	        disableResizing: true,
	        ignoreTimezone: false,
	        slotLabelFormat: 'h A',
	        defaultView: "basicDay",
	        header: {
	            left: 'tarefa',
	            center: 'prev title next',
	            right: 'agendaDay,agendaWeek,month'
	        },
	        views: {
	            basicDay: {
	                titleFormat: 'MMMM YYYY',
	                columnFormat: 'ddd D',
	            }
	        },
	        disableDragging: false,
	        height: 700,
	        editable: false,
	        timeFormat: 'H(:mm)',
	        minTime: '06:00:00',
	        maxTime: '30:00:00',
	        slotDuration: '01:00:00',
	        firstHour: 6,
	        customButtons: {
	            tarefa: {
	                text: 'Tarefa',
	                click: function (date) {
	                    $('#novaAgenda').parents('.bg-agenda').css('display', 'block');
	                    $('#novaAgenda').css('visibility', 'visible');
	                    $('#novaAgenda').css('top', (calendario - 70));
	                    TweenLite.to('html,body', 1, { scrollTop: (calendario), ease: Power1.easeOut });

	                }
	            }
	        },
	        buttonText: {
	            today: 'Hoje',
	            month: 'Mês',
	            week: 'Semana',
	            day: 'Dia',
	            list: 'Lista'
	        },
	        navLinks: true,
	        allDaySlot: false,
	        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Quar', 'Qui', 'Sex', 'Sáb'],
	        monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
	        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
	        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
	        events: {
	            url: $("#urlAgenda").val(),
	            error: function () {
	                console.log('erro');
	            },
	            success: function (data, event, element) {

	                for (var i = 0; i < data.Retorno.length; i++) {

	                    $('.calendar').fullCalendar('renderEvent', data.Retorno[i]);
	                }
	            },
	        },
	        displayEventTime: true,

	        eventRender: function (event, element, data) {
	            var paciente = event.paciente;

	            if (event.status == 'agendado') {
	                $(element).css({
	                    'border-radius': '0',
	                    'border-color': '#ccc'
	                });
	                $(element).find('.fc-content').addClass('agendado');

	            } else if (event.status == 'realizado') {
	                $(element).css({
	                    'border-radius': '0',
	                    'border-color': '#ccc'
	                });
	                $(element).find('.fc-content').addClass('realizado');

	            } else if (event.status == 'em andamento') {
	                $(element).css({
	                    'border-radius': '0',
	                    'border-color': '#ccc'
	                });
	                $(element).find('.fc-content').addClass('andamento');

	            } else if (event.status == 'cancelado') {
	                $(element).css({
	                    'border-radius': '0',
	                    'border-color': '#ccc'
	                });
	                $(element).find('.fc-content').addClass('cancelado');
	            }

	            var view = $('.calendar').fullCalendar('getView');

	        },
	        eventClick: function (calEvent, jsEvent, view) {

	            Id = calEvent.id;
	            title = calEvent.title;
	            data = calEvent.data;
	            profissional = calEvent.profissional;
	            especialidade = calEvent.especialidade;
	            dataInicio = calEvent.dataInicio;
	            hora = calEvent.hora;
	            local = calEvent.local;
	            tarefaRecorrente = calEvent.tarefaRecorrente;
	            recorrencia = calEvent.recorrencia;
	            paciente = calEvent.paciente;
	            status = calEvent.status;
	            latitude = calEvent.latitude;
	            longitude = calEvent.longitude;

	            detalheAgenda = {
	                Id: Id,
	                title: title,
	                data: data,
	                profissional: profissional,
	                especialidade: especialidade,
	                dataInicio: dataInicio,
	                hora: hora,
	                local: local,
	                tarefaRecorrente: tarefaRecorrente,
	                recorrencia: recorrencia,
	                paciente: paciente,
	                status: status,
	                latitude: latitude,
	                longitude: longitude
	            }

	            //  $('#anteriorAgenda').css('display', 'block');

	            $('#anteriorAgenda').parents('.bg-agenda').css('display', 'block');
	            $('#anteriorAgenda').css('visibility', 'visible');
	            $('#anteriorAgenda').css('top', (calendario + 50));
	            TweenLite.to('html,body', 1, { scrollTop: (calendario), ease: Power1.easeOut });


	            $('#status').text(calEvent.status);
	            $('#nome').text(calEvent.paciente);
	            $('#localAtual').text(calEvent.local);
	            $('#dataHora').text(calEvent.dataInicio + ', ' + data + ' ás ' + hora);
	            $('#latitude').text(calEvent.latitude);
	            $('#longitude').text(calEvent.longitude);
	            $("#agenda-nova-data-id").val(calEvent.id);

	            if (calEvent.status == "agendado")
	                $(".btn-edit-agenda").removeClass("hidden");
	            else
	                $(".btn-edit-agenda").addClass("hidden");

	            $('iframe').attr("src", "https://www.google.com/maps?q=" + latitude + "," + longitude + "&output=embed").css({ "width": "100%" });



	            $('#status').text(calEvent.status);
	            $('#nome').text(calEvent.paciente);
	            $('#localAtual').text(calEvent.local);
	            $('#dataHora').text(calEvent.dataInicio + ', ' + data + ' ás ' + hora);
	        }
	    });


	} else {
	    	$('.calendar').fullCalendar({
	 
		disableResizing: true,
			    disableDragging: false, 	
		    	ignoreTimezone: false,
		slotEventOverlap: false,
		    	slotLabelFormat: 'h A',
				defaultView: "agendaWeek",    	
			    header: {
		    		left: 'tarefa',
		    		center: 'prev title next',
		    		right: 'agendaDay,agendaWeek,month'
		    	},	   	
				editable: false,
	            timeFormat: 'H(:mm)',
		views: {
		        	agendaWeek: {
		        		titleFormat: 'MMMM YYYY',
		        		columnFormat: 'ddd D',
		        	},
		        	month: {
		        		columnFormat: 'dddd',
		        	}   		
	        	},
	        	
	        	minTime: '06:00:00',
	        	maxTime: '30:00:00',
		    	slotDuration: '01:00:00',
		    	disableDragging: false,
			    customButtons: {
			        tarefa: {
			            text: 'Tarefa',
			click: function (date) {
			            	
			  $('#novaAgenda').parents('.bg-agenda').css('display', 'block');
			  $('#novaAgenda').css('visibility', 'visible');
			  $('#novaAgenda').css('top', (calendario + 50));
			  TweenLite.to('html,body', 1, { scrollTop: (calendario), ease: Power1.easeOut });
		            									
			            }
			        }
			    },	
			    buttonText: {
		    		today: 'Hoje',
		    		month: 'Mês',
		    		week: 'Semana',
		    		day: 'Dia',
		    		list: 'Lista'
		    	},    
		    	navLinks: true,
				allDaySlot: false,
		monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
		monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
	 			dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
	 			dayNamesShort: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
			    events: {
			    	url: $("#urlAgenda").val(),
		  error: function () {
			    		console.log('erro');
			    	},
		  success: function (data, event, element) {
			        	
			for (var i = 0; i < data.Retorno.length; i++) {

			        		$('.calendar').fullCalendar('renderEvent', data.Retorno[i]);	        		
			        	}			
				    },
			    },
				displayEventTime: true,

		eventRender: function (event, element, data) {
					var paciente = event.paciente;
		  if (event.status == 'agendado') {
						$(element).css({
			  'border-radius': '0',
							'border-color': '#ccc'
						});
						$(element).find('.fc-content').addClass('agendado');
						

					} else if (event.status == 'realizado') {
						$(element).css({
			  'border-radius': '0',
							'border-color': '#ccc'
						});
						$(element).find('.fc-content').addClass('realizado');

					} else if (event.status == 'em andamento') {
					  $(element).css({
						'border-radius': '0',
						'border-color': '#ccc'
					  });
					  $(element).find('.fc-content').addClass('andamento');	
						
		  } else if (event.status == 'cancelado') {
						$(element).css({
			  'border-radius': '0',
							'border-color': '#ccc'
						});
						$(element).find('.fc-content').addClass('cancelado');	
						
					}	

					var view = $('.calendar').fullCalendar('getView');

			    },
		eventClick: function (calEvent, jsEvent, view) {

			    	Id = calEvent.id;
			        title = calEvent.title;
			        data = calEvent.data;
			        profissional = calEvent.profissional;
			        especialidade = calEvent.especialidade;
			        dataInicio = calEvent.dataInicio;
			        hora = calEvent.hora;
			        local = calEvent.local;
			        tarefaRecorrente = calEvent.tarefaRecorrente;
			        recorrencia = calEvent.recorrencia;
			        paciente = calEvent.paciente;
			        status = calEvent.status;
					latitude = calEvent.latitude;
					longitude = calEvent.longitude;

			        detalheAgenda = {
			        	Id: Id,
			        	title: title,
			        	data: data,
			        	profissional: profissional,
			        	especialidade: especialidade,
			        	dataInicio: dataInicio,
			        	hora: hora,
			        	local: local,
			        	tarefaRecorrente: tarefaRecorrente,
			        	recorrencia: recorrencia,
			        	paciente: paciente,
			        	status: status,
			        	latitude: latitude,
			        	longitude: longitude
			        }
			       
		  $('#anteriorAgenda').parents('.bg-agenda').css('display', 'block');
		  $('#anteriorAgenda').css('visibility', 'visible');
		  $('#anteriorAgenda').css('top', (calendario + 50));
		  TweenLite.to('html,body', 1, { scrollTop: (calendario), ease: Power1.easeOut });
			       
			        $('#status').text(calEvent.status);
			        $('#nome').text(calEvent.paciente);
			        $('#localAtual').text(calEvent.local);
		  $('#dataHora').text(calEvent.dataInicio + ', ' + data + ' ás ' + hora);
					$('#latitude').text(calEvent.latitude);
					$('#longitude').text(calEvent.longitude);
					$("#agenda-nova-data-id").val(calEvent.id);

		  if (calEvent.status == "agendado")
						$(".btn-edit-agenda").removeClass("hidden");
					else        
						$(".btn-edit-agenda").addClass("hidden");

		  $('iframe').attr("src", "https://www.google.com/maps?q=" + latitude + "," + longitude + "&output=embed");
					
			    }		    		        			
			})			
			
		}
	$("#novaAgenda .btn-save-task").on("click", function (e) {
			e.preventDefault();
            ValidationFormAgenda($(this).parents('form'));
		});

	$('#novaAgendaForm').on("submit", function (e) {
            e.preventDefault();
            var form = $(this);
            var loading = new Loading();
            $.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: form.serialize(),
                success: function (result) {
		  if (result.Sucesso) {
                			$("#ddlEspecialidade").html('<option value="" class="hidden">Selecione</option>');
                			$('[name="Data"]').val("");
                			$('[name="Hora"]').val("");
                			$("#ddlPaciente").val("")
                			$("#local").html('<option value="" class="hidden">Selecione</option>');
                			$(".calendar").fullCalendar('refetchEvents');
                			$(".close-reveal-modal").click();
                        }
		  else {
                        	ModalShow();
			Alert('Atenção!', result.Mensagem);
                        }
                    

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro");
                    loading.hide();
                }
            });

        });

		$('.fc-tarefa-button').prepend('<i class="fa fa-plus-circle" aria-hidden="true"></i> ');

		$('#novaAgenda').on('click', '.button-specialty', function (e) {
			e.preventDefault();

			$('.button-specialty').removeClass('active');
			$(this).addClass('active');

			$('.button-specialty').find('.icon-specialty-active').addClass('icon-specialty').removeClass('icon-location-active');
			$(this).find('.icon-specialty').addClass('icon-specialty-active').removeClass('icon-specialty');

			$('.button-specialty').find('.icon-mark').addClass('icon-unmark').removeClass('icon-mark');
			$(this).find('.icon-unmark').addClass('icon-mark').removeClass('icon-unmark');


			$("#ddlEspecialidade").html($(this).find('select').html());


		});

	$("#ddlPaciente").on('change', function (e) {
			var id = $("#ddlPaciente").val();
			var select = $("#select-paciente-" + id);
			$("#local").html(select.html());
		});

	$('.day-choose').on('click', function () {
			var inputName = $(this).find('input').attr('name');
	  var cont = $('.recorrencia-content').find('#' + inputName).slideToggle(function () {
		$(this).find('#' + inputName).toggleClass('active');
			});
	  cont.children('#' + inputName);
		});

	$('.close-reveal-modal').on('click', function () {
	  $('#novaAgenda').css('visibility', 'hidden');
	  $('.bg-agenda').css('display', 'none');
		});
	$('.close-alter-data').on('click', function () {
	  $('.changeDate').css('display', 'none');
		});		
	$('.edit-task').on('click', function () {
	    if ($(window).width() < 480) {
	        $('.changeDate').css({ "display": "block", "margin": "auto", "width": "100%", "left": "0" });
	        $('#buttonSalvar').css({"display": "inline" });
	    } else {
	        $('.changeDate').css('display', 'block');
	    }
	});			
	
	$('.close-reveal-modal').on('click', function () {
	  $('#anteriorAgenda').css('visibility', 'hidden');
	  $('.bg-agenda').css('display', 'none');
		});
	$('#newHour').on('change', function () {
			novaHora = $(this).val();
		})
	$(".form-button.-filter").on("click", function (e) {
            e.preventDefault();
            limparFiltro = false;
            $('form').submit(); 
        });

	$("#limpar-filtro").on("click", function (e) {
            e.preventDefault();
            limparFiltro = true;
            $('form').submit();
        });

	$('.btn-alter-task').on('click', function (e) {
			e.preventDefault();
			var error = false;
			var novaData = $("#novaData");
			var novaHora = $("#novaHora");
			
	  if (!validateHora(novaHora)) {
				error = true;
				novaHora.addClass('error');
			}
	  if (validation.isNull(novaData.val()) || (!validation.verifyDate(novaData.val()))) {
				error = true;
				novaData.addClass('error');
			}
	  if (!error) {
				var loading = new Loading();
				var form = $("#form-edit-atendimento");
				$.ajax({
                url: form.attr("action"),
                type: form.attr("method"),
                data: form.serialize(),
                success: function (result) {
                    if (result) {
			  if (result.Sucesso) {
 							novaData.val("");
 							novaHora.val("");
 							$("#agenda-nova-data-id").val("");
 							$(".calendar").fullCalendar('refetchEvents');
                			$(".close-reveal-modal").click();
                        }
			  else {
                        	ModalShow();
				Alert('Atenção!', result.Mensagem);
                        }
                    }

                    loading.hide();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log("Erro");
                    loading.hide();
                }
            });
			}
		});		

	$('#hour').on('change', function () {
			hora = $(this).val();
		});
	$('#local').on('change', function () {
			local = $(this).val();
		});
	$('#local').on('change', function () {
			local = $(this).val();
		});		
		
	$('.close-reveal-modal').on('click', function () {
			$('.button-specialty').removeClass('error');
			$('.button-specialty').removeClass('active');
			$('.icon-mark').addClass('icon-unmark');
			$('.icon-unmark').addClass('icon-mark');
			$('[name="dataInicio"]').removeClass('error');
			$('[name="hora"]').removeClass('error');
			$('[name="hora"]').val('');
			$('[name="local"]').removeClass('error');
		});
	$('.btn-filter-mobile').on('click', function () {
			$(this).toggleClass('filter-opened');
			var icon = $(this).find('i');
	  if ($(icon).hasClass('icon-filter')) {
				$(icon).removeClass('icon-filter');
				$(icon).addClass('icon-filter-closed');
	  } else {
				$(icon).removeClass('icon-filter-closed');
				$(icon).addClass('icon-filter');
			}
			$('.filter-mobile').slideToggle();
			$('.filter-patient').slideToggle();
		})

		insertMask();
    }
	function insertMask() {
	$('[name="Data"], [name="DataFim"], #novaData').mask('00/00/0000', { placeholder: "____ / ____ / ____" });
		$('#novaHora,[name="Hora"]').mask('00:00');

	}

  function validateHora(input) {
		var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(input.val());
		return isValid;
	}

  function ValidationFormAgenda(form) {
		var error = false;
		var dataInicio = form.find('[name="Data"]');
		var hora = form.find('[name="Hora"]');
		var local = form.find('[name="LocalID"]');
		var especialidade = form.find('[name="Especialidade"]');
		var paciente = form.find("#ddlPaciente");
		
	if ($('.button-specialty').hasClass('active')) {
			$('.button-specialty').removeClass('error');
	} else {
			error = true;
			$('.button-specialty').addClass('error');
		}		

	if (validation.isNull(dataInicio.val()) || (!validation.verifyDate(dataInicio.val()))) {
            error = true;
            dataInicio.addClass('error');
        } else {
            dataInicio.removeClass('error');
        }

        if (validation.isNull(hora.val()) || !validateHora(hora)) {
            error = true;
            hora.addClass('error');
        } else {
            hora.removeClass('error');
        }
        if (validation.isNull(local.val())) {
            error = true;
            local.addClass('error');
        } else {
            local.removeClass('error');
        }
        if (validation.isNull(especialidade.val())) {
            error = true;
            especialidade.addClass('error');
        } else {
            especialidade.removeClass('error');
        }
        if (validation.isNull(paciente.val())) {
            error = true;
            paciente.addClass('error');
        } else {
            paciente.removeClass('error');
        }
		
		if (!error) {
    		form.submit();
    	}		
	};

	return init();

};
