var AttendanceProfessional = function() {
    
    function init() {
        addListeners();
	}

    function addListeners() {

        $('.carousel-rows').slick({
            dots: true,
            arrows: false
        });


        $('.table-result').on('click', '.link-style-2',function(e){
            e.preventDefault();
            $(this).next().removeClass('hidden');
            $('.link-style-2').addClass('hidden');
            $('.carousel-rows').addClass('hidden');
            $('.btn-back-view').removeClass('hidden');
        });

        $('.table-result').on('click', '.btn-back-view',function(e){
            e.preventDefault();
            $('.link-style-2').removeClass('hidden');
            $('.carousel-rows').removeClass('hidden');
            $('.table-item-mobile').addClass('hidden');            
            $(this).addClass('hidden');
        });        
        
    }
    
	return init();

};