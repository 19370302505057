var Login = function() {

	var validation = new Validation();
	
	function init() {
		addListeners();
		$(document).keypress(DetectEnter);
	}

	function addListeners()
	{

		$('.login.home .form-submit').on("click",function (e) {
			validationForm();		
		});

		$('.login.home form').on("submit",LoginForm);	
	}

	function LoginForm(e) {
		e.preventDefault();
        var form = $(this);
        var loading = new Loading();
        $.ajax({
			url: form.attr("action"),
			type: form.attr("method"),
			data: form.serialize(),
			success: function (result) {
			    if (result != null) { 
			        if(result.Sucesso)
			        {
                        location.href = result.Mensagem;
			        }
			        else
			        {
                        ModalShow();
                        Alert('Erro!',result.Mensagem);
			        }
			    }

			    loading.hide();
			},
			error: function (XMLHttpRequest, textStatus, errorThrown) {
			    console.log("Erro")
			}
		});
	}

	$('.esqueci-senha').on("click",function (e) {
			e.preventDefault();
			
			var email = $('[name="email_"]');

			if (validation.isNull(email.val()) || !validation.IsEmail(email.val())) {
				email.addClass('error');
    		} else {
    			email.removeClass('error');
    			var loading = new Loading();
    			$.ajax({
					url: $(this).attr('href') + "?email=" + email.val(),
					type: "GET",
					success: function (result) {
						Alert("Atenção!", result.Mensagem);
						ModalShow();
						loading.hide();
					},
					error: function (XMLHttpRequest, textStatus, errorThrown) {
				    	console.log("Erro")
					}
				});
    		}	
    	});

	function DetectEnter(e){

		if(e.which == 13) {
	        validationForm();
        }else{
        	console.log('ok');
        }		
	};
	function validationForm() {
    	var error = false;
    	var form = $('.login.home form');

    	var email = form.find('[name="email_"]');
    	var senha = form.find('[name="senha"]');


    	if (!validation.IsEmail(email.val()) || validation.isNull(email.val())) {
    		error = true;
    		email.addClass('error');
    	} else {
			email.removeClass('error');
    	}

    	if (validation.isNull(senha.val())) {
    		error = true;
    		senha.addClass('error');
    	} else {
    		senha.removeClass('error');
    	}

    	if (!error) {
    		form.submit();
    	} 
    }

    return init();

};